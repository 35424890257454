import * as React from "react";
import { ISchoolDetailsVoteCloudAuthStageState } from "./ISchoolDetailsVoteCloudAuthStageState";
import { ISchoolDetailsVoteCloudAuthStageProps } from "./ISchoolDetailsVoteCloudAuthStageProps";
import "./SchoolDetailsVoteCloudAuthStage.scss";
import IconPending from "../../shared/IconPending/IconPending";
import Button from "@material-ui/core/Button";
import SchoolDetailsVoteIcon from "../SchoolDetailsVoteIcon/SchoolDetailsVoteIcon";
import ReactFacebookLogin, {
  ReactFacebookFailureResponse,
  ReactFacebookLoginInfo,
} from "react-facebook-login";
import env from "../../../assets/data/.env.json";

class SchoolDetailsVoteCloudAuthStage extends React.Component<
  ISchoolDetailsVoteCloudAuthStageProps,
  ISchoolDetailsVoteCloudAuthStageState
> {
  constructor(props: ISchoolDetailsVoteCloudAuthStageProps) {
    super(props);
    this.state = {
      isFbPending: false,
      displayAllMethods: false,
    };
  }

  public handleFacebookLoginCallback = (
    userInfo: ReactFacebookLoginInfo | ReactFacebookFailureResponse
  ) => {
    const { onVoteViaFacebookMethod } = this.props;
    this.setState({
      isFbPending: false,
    });
    if (
      (userInfo as ReactFacebookFailureResponse).status &&
      (userInfo as ReactFacebookFailureResponse).status === "unknown"
    ) {
      return;
    }
    const loginInfo: ReactFacebookLoginInfo = userInfo as ReactFacebookLoginInfo;
    const { accessToken, id } = loginInfo;
    onVoteViaFacebookMethod &&
      onVoteViaFacebookMethod({ accessToken, userID: id });
  };

  public handleEmailButtonClick = () => {
    const { onVoteViaEmailMethod } = this.props;
    onVoteViaEmailMethod && onVoteViaEmailMethod();
  };

  public handleFacebookLoginClick = () => {
    this.setState({
      isFbPending: true,
    });
  };

  public handleCancelButtonClick = () => {
    const { onCancel } = this.props;
    onCancel && onCancel();
  };

  public handleOtherMethodClick = () => {
    this.setState({
      displayAllMethods: true,
    });
  };

  public render(): JSX.Element {
    const {
      selectedVoteType,
      createCurrentUserFacebookAccountBody,
      putCurrentUserFacebookAccountBody,
      addVoteAuth,
    } = this.props;
    const { isFbPending, displayAllMethods } = this.state;

    const isApiPending: boolean =
      createCurrentUserFacebookAccountBody.status === "pending" ||
      putCurrentUserFacebookAccountBody.status === "pending";
    const isPending: boolean = isFbPending || isApiPending;
    return (
      <div className={`schoolDetailsVoteCloudAuthStage`}>
        <div className="body">
          <div className="vote-icon">
            <SchoolDetailsVoteIcon selectedVoteType={selectedVoteType} />
          </div>
          <div className="form">
            <div>
              <p className="info">Wybierz sposób w jaki chcesz odddać głos:</p>
            </div>
            <div className="input-area">
              <div className="row facebook-area">
                <ReactFacebookLogin
                  appId={env.FB_APP_ID}
                  autoLoad={false}
                  fields="first_name,last_name,email,picture"
                  scope="public_profile,email"
                  onClick={this.handleFacebookLoginClick}
                  callback={this.handleFacebookLoginCallback}
                  cssClass="auth-button facebook-button"
                  icon={
                    isPending ? <IconPending isPending={true} /> : "fa-facebook"
                  }
                  textButton={
                    isFbPending
                      ? "Zezwól aplikacji na dostęp..."
                      : isApiPending
                      ? "Trwa weryfikacja konta..."
                      : "Zagłosuj przez Facebook"
                  }
                  isDisabled={isPending}
                />
              </div>
              {!displayAllMethods && (
                <div
                  className="other-method"
                  onClick={this.handleOtherMethodClick}
                >
                  <span>Nie masz konta? Wybierz inną metodę...</span>
                </div>
              )}
              {displayAllMethods && (
                <div>
                  <div className="space-wrapper">
                    <div className="line-wrapper">
                      <hr className="line"></hr>
                    </div>

                    <span className="or">lub</span>
                  </div>
                  <div className="row email-area">
                    <span>
                      <button
                        className="auth-button email-button"
                        onClick={this.handleEmailButtonClick}
                        disabled={isPending}
                      >
                        <i className="icon-mail"></i>Zagłosuj przez E-mail
                      </button>
                    </span>
                  </div>
                </div>
              )}
              <div className="error">{addVoteAuth.otherError}</div>
            </div>
          </div>
        </div>
        <div className="footer">
          <Button
            className="cancel"
            onClick={this.handleCancelButtonClick}
            disabled={isPending}
          >
            Anuluj
          </Button>
        </div>
      </div>
    );
  }
}

export default SchoolDetailsVoteCloudAuthStage;
