import * as React from "react";
import { IOopsState } from "./IOopsState";
import { IOopsProps } from "./IOopsProps";
import "./Oops.scss";
import MonitorIcon from "../../entities/MonitorIcon/MonitorIcon";

class Oops extends React.Component<IOopsProps, IOopsState> {
  constructor(props: IOopsProps) {
    super(props);
    this.state = {};
  }

  public render(): JSX.Element {
    const { children, className } = this.props;
    return (
      <div className={`Oops ${className}`}>
        <MonitorIcon
          text={":/"}
          size={80}
          borderColor={"#474747"}
          borderWidth={4}
        />

        <p className="message">
          {children || "Niestety, nie znaleziono tu niczego..."}
        </p>
      </div>
    );
  }
}

export default Oops;
