import * as React from "react";
import { ISchoolDetailsGoogleReviewState } from "./ISchoolDetailsGoogleReviewState";
import { ISchoolDetailsGoogleReviewProps } from "./ISchoolDetailsGoogleReviewProps";
import "./SchoolDetailsGoogleReview.scss";
import Moment from "react-moment";
import "moment/locale/pl";

class SchoolDetailsGoogleReview extends React.Component<
  ISchoolDetailsGoogleReviewProps,
  ISchoolDetailsGoogleReviewState
> {
  constructor(props: ISchoolDetailsGoogleReviewProps) {
    super(props);
    this.state = {};
  }

  public getVoteIcon = (rate: 0 | 1 | 2 | 3 | 4 | 5): string => {
    return rate >= 3 ? "icon-thumbs-up-alt" : "icon-thumbs-down-alt";
  };

  public render(): JSX.Element {
    const { googleReview } = this.props;
    return (
      <div className={`SchoolDetailsGoogleReview`}>
        <div className="comment-content">
          <div className="name-and-date">
            <span className="name">{googleReview.author}</span>
            <span className="date">
              <Moment locale="pl" fromNow={true}>
                {googleReview.date}
              </Moment>
            </span>
          </div>

          <div className="comment">
            <span>{googleReview.text}</span>
          </div>
          <span className="google-label">
            Ten komentarz jest pobrany z google
          </span>
        </div>
        <div
          className={`comment-icon ${googleReview.rate >= 3 ? "up" : "down"}`}
        >
          <i className={`icon ${this.getVoteIcon(googleReview.rate)}`}></i>
        </div>
      </div>
    );
  }
}

export default SchoolDetailsGoogleReview;
