import * as React from "react";
import { IBackgroundState } from "./IBackgroundState";
import { IBackgroundProps } from "./IBackgroundProps";
import "./Background.scss";

class Background extends React.Component<IBackgroundProps, IBackgroundState> {
  constructor(props: IBackgroundProps) {
    super(props);
    this.state = {};
  }

  public render(): JSX.Element {
    return (
      <div className={`Background`}>
        <div className="background-wrap">
          <div className="bubble x1"></div>
          <div className="bubble x2"></div>
          <div className="bubble x3"></div>
          <div className="bubble x4"></div>
          <div className="bubble x5"></div>
          <div className="bubble x6"></div>
          <div className="bubble x7"></div>
          <div className="bubble x8"></div>
          <div className="bubble x9"></div>
          <div className="bubble x10"></div>
        </div>
      </div>
    );
  }
}

export default Background;
