import * as React from "react";
import { ITopState } from "./ITopState";
import { ITopProps } from "./ITopProps";
import "./Top.scss";
import Logo from "../Logo/Logo";

class Top extends React.Component<ITopProps, ITopState> {
  constructor(props: ITopProps) {
    super(props);
    this.state = {};
  }

  public render(): JSX.Element {
    return (
      <div className={`Top`}>
        <div className="logo">
          <Logo />
        </div>
      </div>
    );
  }
}

export default Top;
