import * as React from "react";
import { ISchoolDetailsExpertsOpinionsState } from "./ISchoolDetailsExpertsOpinionsState";
import { ISchoolDetailsExpertsOpinionsProps } from "./ISchoolDetailsExpertsOpinionsProps";
import "./SchoolDetailsExpertsOpinions.scss";
import SchoolDetailsExpertOpinion from "../SchoolDetailsExpertOpinion/SchoolDetailsExpertOpinion";
import Oops from "../../shared/Oops/Oops";
import staticOpinions from "./static-opinions.json";

class SchoolDetailsExpertsOpinions extends React.Component<
  ISchoolDetailsExpertsOpinionsProps,
  ISchoolDetailsExpertsOpinionsState
> {
  constructor(props: ISchoolDetailsExpertsOpinionsProps) {
    super(props);
    this.state = {};
  }

  public render(): JSX.Element {
    const {
      grades,
      vocationalEducationInformationGrade,
      generalEducationInformationGrade,
      generalEducationInformation,
      vocationalEducationInformation,
      intershipInformation,
      intershipInformationGrade,
      getSchoolDetailsByFullNameBody,
    } = this.props;

    const isPending = getSchoolDetailsByFullNameBody.status === "pending";
    return (
      <div className="SchoolDetailsExpertsOpinions">
        {!isPending && generalEducationInformationGrade !== undefined && (
          <SchoolDetailsExpertOpinion
            grade={{
              _id: "",
              type: generalEducationInformationGrade,
              addedByExpertId: "1",
              addedToSchoolId: "",
              isIncluded: true,
              isVisible: true,
              created_at: new Date(),
              updated_at: new Date(),
              opinion:
                staticOpinions["generalEducationInformationGrade"][
                  generalEducationInformationGrade
                ],
            }}
            extensionText={generalEducationInformation}
          />
        )}
        {!isPending && vocationalEducationInformationGrade !== undefined && (
          <SchoolDetailsExpertOpinion
            grade={{
              _id: "",
              type: vocationalEducationInformationGrade,
              addedByExpertId: "2",
              addedToSchoolId: "",
              isIncluded: true,
              isVisible: true,
              created_at: new Date(),
              updated_at: new Date(),
              opinion:
                staticOpinions["vocationalEducationInformationGrade"][
                  vocationalEducationInformationGrade
                ],
            }}
            extensionText={vocationalEducationInformation}
          />
        )}
        {!isPending && intershipInformationGrade !== undefined && (
          <SchoolDetailsExpertOpinion
            grade={{
              _id: "",
              type: intershipInformationGrade,
              addedByExpertId: "3",
              addedToSchoolId: "",
              isIncluded: true,
              isVisible: true,
              created_at: new Date(),
              updated_at: new Date(),
              opinion:
                staticOpinions["intershipInformationGrade"][
                  intershipInformationGrade
                ],
            }}
            extensionText={intershipInformation}
          />
        )}
        {!isPending && grades
          ? grades.map((grade, i: number) => (
              <SchoolDetailsExpertOpinion
                key={i}
                grade={grade}
                extensionText={undefined}
              />
            ))
          : [1, 2, 3, 4, 5, 6, 7, 8].map((opinion, i: number) => (
              <SchoolDetailsExpertOpinion
                key={i}
                grade={undefined}
                extensionText={undefined}
              />
            ))}
        {!grades?.length &&
          generalEducationInformationGrade === undefined &&
          vocationalEducationInformationGrade === undefined &&
          intershipInformationGrade === undefined && (
            <Oops>
              <h3 className="oops-header">Przykro nam!</h3>
              <span>Nie ma tu jeszcze żadnej opinii...</span>
            </Oops>
          )}
      </div>
    );
  }
}

export default SchoolDetailsExpertsOpinions;
