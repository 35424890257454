import { stringify } from "querystring";
import { fetchFromApi } from "../../utils/utils";

const getSiteBriefByName = async (
  requestParams: Site.Req.GetSiteBriefByNameParams,
  requestBody: Site.Req.GetSiteBriefByNameBody,
  requestQuery: Site.Req.GetSiteBriefByNameQuery
): Promise<Site.Res.GetSiteBriefByNameBody> => {
  return await fetchFromApi<
    Site.Req.GetSiteBriefByNameBody,
    Site.Res.GetSiteBriefByNameBody
  >(`/sites/brief/name`, "POST", requestBody);
};

export default {
  getSiteBriefByName,
};
