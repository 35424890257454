import * as React from "react";
import { IPagesState } from "./IPagesState";
import {
  IPagesDispatchProps,
  IPagesProps,
  IPagesStateProps,
} from "./IPagesProps";
import "./Pages.scss";
import { Redirect, Route, Switch, withRouter } from "react-router";
import Ranking from "../Ranking/Ranking";
import AddSchool from "../AddSchool/AddSchool";
import { AppDispatch, AppState } from "../../../store/store";
import { connect } from "react-redux";
import {
  setScrollHeightAction,
  setScrollTopAction,
  setWindowSizeAction,
} from "../../../store/@ui/ui.actions";
import Contact from "../Contact/Contact";
import Rules from "../Rules/Rules";
import ScrollTop from "../../utils/ScrollTop/ScrollTop";
import CookieAlert from "../../entities/CookieAlert/CookieAlert";
import FacebookDeletionStatus from "../FacebookDeletionStatus/FacebookDeletionStatus";
import ShareWindow from "../../entities/ShareWindow/ShareWindow";
import IFramePreviewWindow from "../../entities/IFramePreviewWindow/IFramePreviewWindow";

const mapStateToProps = (state: AppState): IPagesStateProps => ({});

const mapDispatchToProps = (dispatch: AppDispatch): IPagesDispatchProps => ({
  onScrollHeightChange: (scrollHeight: number) => {
    dispatch(setScrollHeightAction({ scrollHeight }));
  },
  onScrollTopChange: (scrollTop: number) => {
    dispatch(setScrollTopAction({ scrollTop }));
  },
  onWindowSizeChange: (windowWidth: number, windowHeight: number) => {
    dispatch(setWindowSizeAction({ windowWidth, windowHeight }));
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);

class Pages extends React.Component<IPagesProps, IPagesState> {
  scrollTimer: NodeJS.Timeout | undefined;
  pagesRef: React.RefObject<HTMLDivElement> = React.createRef();
  constructor(props: IPagesProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.onscroll = (event: any) => {
      this.handleScrollPage(
        document.documentElement.scrollTop || document.body.scrollTop
      );
    };
  }

  componentWillUnmount() {
    window.onscroll = () => {};
  }

  public handleResizePage = () => {};

  public handleScrollPage = (scrollTop: number) => {
    const { onScrollTopChange } = this.props;

    onScrollTopChange && onScrollTopChange(scrollTop);
  };

  public render(): JSX.Element {
    return (
      <div className={`pages`} ref={this.pagesRef}>
        <div className="windows">
          <IFramePreviewWindow />
          <ShareWindow />
        </div>
        <ScrollTop pagesRef={this.pagesRef}>
          <div className="content">
            <Switch>
              <Route exact path="/">
                <Redirect to="/ranking" />
              </Route>
              <Route path="/ranking/:schoolFullName?">
                <Ranking />
              </Route>
              <Route path="/add-school">
                <AddSchool />
              </Route>

              <Route path="/contact">
                <Contact />
              </Route>
              <Route path="/rules">
                <Rules />
              </Route>
              <Route path="/facebook/deletion-status">
                <FacebookDeletionStatus />
              </Route>
            </Switch>
          </div>
        </ScrollTop>
        <CookieAlert />
      </div>
    );
  }
}

export default withRouter(connector(Pages));
