import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";
import usersReducers from "./users.reducers";

export type UserState = {
  currentUser: User.CurrentUser | undefined;
  getCurrentUserBody: User.Res.GetCurrentUserBody;
  patchCurrentUserBody: User.Res.PatchCurrentUserBody;
  createCurrentUserEmailBody: User.Res.CreateCurrentUserEmailBody;
  createCurrentUserFacebookAccountBody: User.Res.CreateCurrentUserFacebookAccountBody;
  putCurrentUserFacebookAccountBody: User.Res.PutCurrentUserFacebookAccountBody;
  generateCodeBody: Code.Res.GenerateCodeBody;
  verifyCodeBody: Code.Res.VerifyCodeBody;
  verifyCurrentUserBody: User.Res.VerifyCurrentUserBody;
};

const initialState: UserState = {
  currentUser: undefined,
  getCurrentUserBody: { status: "empty" },
  patchCurrentUserBody: { status: "empty" },
  createCurrentUserEmailBody: { status: "empty" },
  createCurrentUserFacebookAccountBody: { status: "empty" },
  putCurrentUserFacebookAccountBody: { status: "empty" },
  generateCodeBody: { status: "empty" },
  verifyCodeBody: { status: "empty" },
  verifyCurrentUserBody: { status: "empty" },
};

export const userSlice: Slice<UserState> = createSlice({
  name: "user",
  initialState,
  reducers: {
    ...usersReducers,
  },
});

export const {
  unsetCurrentUser,
  setUsers,
  unsetUsers,
  updateUser,
} = userSlice.actions;

export default userSlice.reducer;
