import * as React from "react";
import { IWelcomeState } from "./IWelcomeState";
import { IWelcomeProps } from "./IWelcomeProps";
import "./Welcome.scss";
import Bubble from "../../shared/Bubble/Bubble";
import content from "../../../assets/data/contents.data.json";
import AnimationText from "../../shared/AnimationText/AnimationText";

class Welcome extends React.Component<IWelcomeProps, IWelcomeState> {
  constructor(props: IWelcomeProps) {
    super(props);
    this.state = {
      isHeaderAnimationComplete: false,
    };
  }

  public handleHeaderAnimationComplete = () => {
    this.setState({
      isHeaderAnimationComplete: true,
    });
  };

  public render(): JSX.Element {
    const {} = this.props;
    const { isHeaderAnimationComplete } = this.state;
    return (
      <Bubble className={`Welcome`} ready={true}>
        <div className={`gradient-background-wrapper`}>
          <div className="gradient-background-box">
            <div className="gradient-background"></div>
          </div>
        </div>
        <h1>
          <AnimationText
            visible={true}
            onAnimationComplete={this.handleHeaderAnimationComplete}
            delayBetweenWords={0.2}
          >
            {content["ranking-welcome"].header}
          </AnimationText>
        </h1>
        <div className="more">
          <p>
            <AnimationText
              separator={"\u2063"}
              visible={isHeaderAnimationComplete}
              delayBetweenWords={0.2}
            >
              {content["ranking-welcome"].description}
            </AnimationText>
          </p>

          <div
            className="icon"
            style={{
              backgroundImage: `url(${content["ranking-welcome"].image_url})`,
            }}
          ></div>
        </div>
      </Bubble>
    );
  }
}

export default Welcome;
