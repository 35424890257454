import * as React from "react";
import { ILoaderState } from "./ILoaderState";
import { ILoaderProps } from "./ILoaderProps";
import "./Loader.scss";

class Loader extends React.Component<ILoaderProps, ILoaderState> {
  constructor(props: ILoaderProps) {
    super(props);
    this.state = {};
  }

  public render(): JSX.Element {
    return (
      <div className={`Loader`}>
        <div className="loader"></div>
        <div className="shadow"></div>
      </div>
    );
  }
}

export default Loader;
