import * as React from "react";
import { ISchoolPreviewState } from "./ISchoolPreviewState";
import { ISchoolPreviewProps } from "./ISchoolPreviewProps";
import "./SchoolPreview.scss";
import StarGrade from "../../shared/StarGrade/StarGrade";
import Skeleton from "react-loading-skeleton";

class SchoolPreview extends React.Component<
  ISchoolPreviewProps,
  ISchoolPreviewState
> {
  schoolPreviewRef: React.RefObject<HTMLDivElement> = React.createRef();
  constructor(props: ISchoolPreviewProps) {
    super(props);
    this.state = {};
  }

  private handleSchoolPreviewClick = (
    event: React.MouseEvent<HTMLDivElement>
  ): void => {
    const { schoolPreviewRef } = this;
    const {
      onSelectSchool,
      schoolPreview,
      rankingRef,
      index,
      getSchoolDetailsBody,
    } = this.props;
    if (!schoolPreview) return;
    if (getSchoolDetailsBody.status === "pending") return;
    onSelectSchool && onSelectSchool(schoolPreview, index);

    this.scrollToElement({ schoolPreviewRef, rankingRef });
  };

  private scrollToElement = (payload: {
    rankingRef: React.RefObject<HTMLDivElement>;
    schoolPreviewRef: React.RefObject<HTMLDivElement>;
  }) => {
    const { rankingRef, schoolPreviewRef } = payload;
    const rankingElement: HTMLDivElement | null = rankingRef.current;
    const schoolPreviewElement: HTMLDivElement | null =
      schoolPreviewRef.current;
    if (!rankingElement) return;
    if (!schoolPreviewElement) return;

    const rankingTop = rankingElement.getBoundingClientRect().top;
    const schoolPreviewTop = schoolPreviewElement.getBoundingClientRect().top;
    const diff = schoolPreviewTop - window.innerHeight / 2 - rankingTop;
    diff > 0
      ? schoolPreviewElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        })
      : rankingElement.scrollIntoView({ behavior: "smooth" });
  };

  private getScoreBackgroundColor = (
    score: number,
    minScore: number,
    maxScore: number
  ): string => {
    const sMin: number = 15;
    const sMax: number = 45;
    const lMin: number = 27;
    const lMax: number = 45;

    const s: number = (score / (maxScore - minScore)) * (sMax - sMin) + sMin;
    const l: number = (score / (maxScore - minScore)) * (lMax - lMin) + lMin;
    return `hsl(237deg, ${s}%, ${l}%)`;
  };

  public getAdditionalCity = (
    schoolPreview: School.SchoolPreview | undefined,
    schoolNameFilter: string | undefined,
    provinceTypeFilter: School.ProvinceType | undefined
  ): string | undefined => {
    if (!schoolPreview) return undefined;
    if (!schoolNameFilter && !provinceTypeFilter) return undefined;
    if (schoolPreview.provinceType === provinceTypeFilter) return undefined;
    const { additionalLocations } = schoolPreview;
    if (!additionalLocations) return undefined;

    const additionalLocation =
      additionalLocations.find(
        (location) => location.provinceType === provinceTypeFilter
      ) ||
      additionalLocations.find((location) =>
        schoolNameFilter?.includes(location.city)
      );
    return additionalLocation?.city;
  };

  public render(): JSX.Element {
    const {
      schoolPreview,
      isSelected,
      index,
      schoolNameFilter,
      provinceTypeFilter,
    } = this.props;
    return (
      <div
        className="schoolPreview-wrapper"
        title={
          schoolPreview && `${schoolPreview.name} w: ${schoolPreview.city}`
        }
      >
        <div
          className={`schoolPreview ${isSelected && "selected"}`}
          ref={this.schoolPreviewRef}
          onClick={this.handleSchoolPreviewClick}
        >
          <div className={`place ${index + 1 <= 3 ? "podium" : ""}`}>
            <span>{index + 1}</span>
          </div>
          <div className="score" style={{}}>
            <span>{schoolPreview?.score}</span>
          </div>
          <div className="name">
            <span>{schoolPreview?.name || <Skeleton />}</span>
          </div>
          <div className="city">
            <span>
              {this.getAdditionalCity(
                schoolPreview,
                schoolNameFilter,
                provinceTypeFilter
              ) ||
                schoolPreview?.city || <Skeleton />}
            </span>
          </div>
          <div className="stars">
            <StarGrade
              gradeValue={schoolPreview ? schoolPreview.gradesAvg : 0}
            />
          </div>
          <div className="votes-up">
            <span>{schoolPreview ? schoolPreview.thumbsUp : <Skeleton />}</span>
          </div>
          <div className="votes-down">
            <span>
              {schoolPreview ? schoolPreview.thumbsDown : <Skeleton />}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default SchoolPreview;
