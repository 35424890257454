import * as React from "react";
import { IRedirectState } from "./IRedirectState";
import { IRedirectProps, RedirectSearch } from "./IRedirectProps";
import { withRouter } from "react-router";
import qs from "query-string";
import Joi from "joi";
import redirectsService from "../../../store/redirects/redirects.service";

export const JoiRedirectSearch = {
  to: Joi.string()
    .uri({ scheme: ["http", "https"] })
    .required(),
};

class Redirect extends React.Component<IRedirectProps, IRedirectState> {
  constructor(props: IRedirectProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { history, location } = this.props;
    const searchObj: RedirectSearch = qs.parse(location.search);
    if (!searchObj["to"]) return this.redirectToHome();
    else if (!this.isToValid(searchObj["to"])) return this.redirectToHome();
    else return this.redirectToExternalPage(searchObj["to"]);
  }

  isToValid = (to: any) => {
    return !JoiRedirectSearch.to.validate(decodeURIComponent(to)).error;
  };

  redirectToHome = () => {
    window.location.href = "https://szkolytechnologiczne.pl";
  };

  redirectToExternalPage = async (externalUrl: string) => {
    window.location.href = decodeURIComponent(externalUrl);
    await redirectsService.createRedirect({}, { to: externalUrl }, {});
  };

  public render(): JSX.Element {
    return <></>;
  }
}

export default withRouter(Redirect);
