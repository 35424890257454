import { fetchFromApi } from "../../utils/utils";
import { stringify } from "query-string";

const fetchSchoolPreviews = async (
  requestParams: School.Req.GetSchoolPreviewsParams,
  requestBody: School.Req.GetSchoolPreviewsBody,
  requestQuery: School.Req.GetSchoolPreviewsQuery
): Promise<School.Res.GetSchoolPreviewsBody> => {
  const responseBody = await fetchFromApi<
    School.Req.GetSchoolPreviewsBody,
    School.Res.GetSchoolPreviewsBody
  >(`/schools/previews/?${stringify(requestQuery)}`, "GET");
  return responseBody;
};

const fetchBestSchoolDetails = async (): Promise<School.Res.GetBestSchoolDetailsBody> => {
  const responseBody = await fetchFromApi<
    School.Req.GetBestSchoolDetailsBody,
    School.Res.GetBestSchoolDetailsBody
  >(`/schools/details/best`, "GET");
  return responseBody;
};

const fetchSchoolDetails = async (
  requestParams: School.Req.GetSchoolDetailsByFullNameParams,
  requestBody: School.Req.GetSchoolDetailsByFullNameBody,
  requestQuery: School.Req.GetSchoolDetailsByFullNameQuery
): Promise<School.Res.GetSchoolDetailsByFullNameBody> => {
  const responseBody = await fetchFromApi<
    School.Req.GetSchoolDetailsByFullNameBody,
    School.Res.GetSchoolDetailsByFullNameBody
  >(
    `/schools/details/fullname/${requestParams.fullName}/?${stringify(
      requestQuery
    )}`,
    "GET"
  );
  return responseBody;
};

const fetchSchoolDetailsById = async (
  requestParams: School.Req.GetSchoolDetailsByIdParams,
  requestBody: School.Req.GetSchoolDetailsByIdBody,
  requestQuery: School.Req.GetSchoolDetailsByIdQuery
): Promise<School.Res.GetSchoolDetailsByIdBody> => {
  const responseBody = await fetchFromApi<
    School.Req.GetSchoolDetailsByIdBody,
    School.Res.GetSchoolDetailsByIdBody
  >(
    `/schools/details/id/${requestParams.schoolId}/?${stringify(requestQuery)}`,
    "GET"
  );
  return responseBody;
};

const createMySchool = async (
  requestBody: School.Req.CreateMySchoolBody
): Promise<School.Res.CreateMySchoolBody> => {
  const responseBody = await fetchFromApi<
    School.Req.CreateMySchoolBody,
    School.Res.CreateMySchoolBody
  >("/schools/my", "POST", requestBody);
  return responseBody;
};

export default {
  fetchSchoolPreviews,
  fetchBestSchoolDetails,
  fetchSchoolDetailsById,
  fetchSchoolDetails,
  createMySchool,
};
