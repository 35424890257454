import * as React from "react";
import { IStarGradeState } from "./IStarGradeState";
import { IStarGradeProps } from "./IStarGradeProps";
import "./StarGrade.scss";

class StarGrade extends React.Component<IStarGradeProps, IStarGradeState> {
  constructor(props: IStarGradeProps) {
    super(props);
    this.state = {};
  }

  private getStars = (gradeValue: number): App.Stars => {
    const stars: App.Stars = [
      "icon-star-empty",
      "icon-star-empty",
      "icon-star-empty",
      "icon-star-empty",
      "icon-star-empty",
    ].map((star, i: number) => {
      const starNumber: number = i + 1;
      if (starNumber <= gradeValue) return "icon-star";
      if (starNumber === Math.ceil(gradeValue)) return "icon-star-half-alt";
      return star;
    }) as App.Stars;
    return stars;
  };

  public render(): JSX.Element {
    const { gradeValue, className } = this.props;
    return (
      <div className={`starGrade ${className}`}>
        {this.getStars(gradeValue).map((starIcon: App.StarIcon, i: number) => (
          <i key={i} className={starIcon}></i>
        ))}
      </div>
    );
  }
}

export default StarGrade;
