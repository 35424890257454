import * as React from "react";
import { IVoteButtonState } from "./IVoteButtonState";
import { IVoteButtonProps } from "./IVoteButtonProps";
import "./VoteButton.scss";
import Button from "../../shared/Button/Button";

import voteButtonsData from "./vote-buttons.data.json";

export type VoteButtonMode =
  | "disabled"
  | "inactive"
  | "active"
  | "not_verified"
  | "voted";

class VoteButton extends React.Component<IVoteButtonProps, IVoteButtonState> {
  constructor(props: IVoteButtonProps) {
    super(props);
    this.state = {};
  }

  private handleButtonClick = (
    mode: VoteButtonMode,
    voteType: System.VoteType,
    selectedVoteType: System.VoteType | undefined
  ): void => {
    const { onVoteButtonSelected, onVoteButtonUnselected } = this.props;
    if (mode === "disabled") return;
    if (voteType === selectedVoteType)
      return onVoteButtonUnselected && onVoteButtonUnselected(voteType);
    if (voteType !== selectedVoteType)
      return onVoteButtonSelected && onVoteButtonSelected(voteType);
  };

  private getMode = (
    voteType: System.VoteType,
    userVoteType: System.VoteType | undefined,
    userVoteStatus: App.VoteStatus,
    selectedVoteType: System.VoteType | undefined // ui
  ): VoteButtonMode => {
    if (userVoteStatus === "not_voted") {
      if (voteType === selectedVoteType) return "active";
      return "inactive";
    } else if (userVoteStatus === "in_verification") {
      if (voteType === userVoteType) return "not_verified";
      return "disabled";
    } else if (userVoteStatus === "voted") {
      if (voteType === userVoteType) return "voted";
      return "disabled";
    }
    return "disabled";
  };

  public render(): JSX.Element {
    const {
      className,
      voteType,
      userVoteType,
      userVoteStatus,
      selectedVoteType,
    } = this.props;
    const mode: VoteButtonMode = this.getMode(
      voteType,
      userVoteType,
      userVoteStatus,
      selectedVoteType
    );
    const voteButtonData = voteButtonsData[voteType][mode];
    return (
      <>
        {mode !== "disabled" && (
          <Button
            className={`vote-button ${className}`}
            border="top"
            colorTheme={voteButtonData.defaultColorTheme}
            hoverColorTheme={voteButtonData.hoverColorTheme}
            onClick={() =>
              this.handleButtonClick(mode, voteType, selectedVoteType)
            }
          >
            <i className={`icon ${voteButtonData.iconName}`}></i>
            <span>{voteButtonData.text}</span>
          </Button>
        )}
      </>
    );
  }
}

export default VoteButton;
