import { UserState } from "./index";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  AddCurrentUserEmailPayload,
  AddCurrentUserFacebookAccountPayload,
  AddCurrentUserVotePayload,
  ConfirmCurrentUserVotePayload,
  RemoveCurrentUserFacebookAccountPayload,
  RemoveCurrentUserVotePayload,
  SetCurrentUserLastCaptchaVerificationActionPayload,
  SetCurrentUserPayload,
  UpdateCurrentUserFacebookAccountPayload,
  UpdateCurrentUserFirstNamePayload,
  UpdateCurrentUserLastAddedSchoolAtPayload,
  VerifyCurrentUserEmailPayload,
} from "./users.actions";

const setCurrentUser = (
  state: UserState,
  { payload }: PayloadAction<SetCurrentUserPayload>
) => {
  state.currentUser = payload.currentUser;
};

const setCurrentUserLastCaptchaVerification = (
  state: UserState,
  { payload }: PayloadAction<SetCurrentUserLastCaptchaVerificationActionPayload>
) => {
  if (!state.currentUser) return;
  state.currentUser.lastCaptchaVerification = payload.lastCaptchaVerification;
};

const updateCurrentUserFirstName = (
  state: UserState,
  { payload }: PayloadAction<UpdateCurrentUserFirstNamePayload>
) => {
  if (!state.currentUser) return;
  state.currentUser.firstName = payload.firstName;
};

const updateCurrentUserLastAddedSchoolAt = (
  state: UserState,
  { payload }: PayloadAction<UpdateCurrentUserLastAddedSchoolAtPayload>
) => {
  if (!state.currentUser) return;
  state.currentUser.lastAddedSchool_at = payload.lastAddedSchool_at;
};

const unsetCurrentUser = (state: UserState, { payload }: PayloadAction<{}>) => {
  state.currentUser = undefined;
};

const addCurrentUserEmail = (
  state: UserState,
  { payload }: PayloadAction<AddCurrentUserEmailPayload>
) => {
  if (!state.currentUser) return;
  state.currentUser.emails.push(payload.email);
};

const addCurrentUserFacebookAccount = (
  state: UserState,
  { payload }: PayloadAction<AddCurrentUserFacebookAccountPayload>
) => {
  if (!state.currentUser) return;
  state.currentUser.facebookAccounts.push(payload.facebookAccount);
};

const addCurrentUserVote = (
  state: UserState,
  { payload }: PayloadAction<AddCurrentUserVotePayload>
) => {
  if (!state.currentUser) return;
  if (!state.currentUser.votes) return;
  state.currentUser.votes.push(payload.vote);
};

const confirmCurrentUserVote = (
  state: UserState,
  { payload }: PayloadAction<ConfirmCurrentUserVotePayload>
) => {
  if (!state.currentUser) return;
  if (!state.currentUser.votes) return;
  const voteIdx: number = state.currentUser.votes.findIndex(
    (vote) => vote._id === payload.voteId
  );
  state.currentUser.votes[voteIdx].isConfirmed = true;
};

const removeCurrentUserVote = (
  state: UserState,
  { payload }: PayloadAction<RemoveCurrentUserVotePayload>
) => {
  if (!state.currentUser) return;
  if (!state.currentUser.votes) return;
  state.currentUser.votes = state.currentUser.votes.filter(
    (vote) => vote._id !== payload.voteId
  );
};

const removeCurrentUserFacebookAccount = (
  state: UserState,
  { payload }: PayloadAction<RemoveCurrentUserFacebookAccountPayload>
) => {
  if (!state.currentUser) return;
  state.currentUser.facebookAccounts = state.currentUser.facebookAccounts.filter(
    (account) => account.userID !== payload.userID
  );
};

const updateCurrentUserFacebookAccount = (
  state: UserState,
  { payload }: PayloadAction<UpdateCurrentUserFacebookAccountPayload>
) => {
  if (!state.currentUser) return;
  const fbAccountIndex: number = state.currentUser.facebookAccounts.findIndex(
    (account) => account.userID === payload.userID
  );
  state.currentUser.facebookAccounts[fbAccountIndex] = {
    ...state.currentUser.facebookAccounts[fbAccountIndex],
    ...payload.newAccount,
  };
};

const verifyCurrentUserEmail = (
  state: UserState,
  { payload }: PayloadAction<VerifyCurrentUserEmailPayload>
) => {
  if (!state.currentUser) return;
  const emailIndex: number = state.currentUser.emails.findIndex(
    (email) => email.address === payload.emailAddress
  );
  if (emailIndex === -1) return;
  state.currentUser.emails[emailIndex].isConfirmed = true;
};

const setGetCurrentUserBody = (
  state: UserState,
  { payload }: PayloadAction<{ body: User.Res.GetCurrentUserBody }>
) => {
  state.getCurrentUserBody = payload.body;
};

const setPatchCurrentUserBody = (
  state: UserState,
  { payload }: PayloadAction<{ body: User.Res.PatchCurrentUserBody }>
) => {
  state.patchCurrentUserBody = payload.body;
};

const setCreateCurrentUserEmailBody = (
  state: UserState,
  { payload }: PayloadAction<{ body: User.Res.CreateCurrentUserEmailBody }>
) => {
  state.createCurrentUserEmailBody = payload.body;
};

const setCreateCurrentUserFacebookAccountBody = (
  state: UserState,
  {
    payload,
  }: PayloadAction<{ body: User.Res.CreateCurrentUserFacebookAccountBody }>
) => {
  state.createCurrentUserFacebookAccountBody = payload.body;
};

const setPutCurrentUserFacebookAccountBody = (
  state: UserState,
  {
    payload,
  }: PayloadAction<{ body: User.Res.PutCurrentUserFacebookAccountBody }>
) => {
  state.putCurrentUserFacebookAccountBody = payload.body;
};

const setGenerateCodeBody = (
  state: UserState,
  { payload }: PayloadAction<{ body: Code.Res.GenerateCodeBody }>
) => {
  state.generateCodeBody = payload.body;
};

const setVerifyCodeBody = (
  state: UserState,
  { payload }: PayloadAction<{ body: Code.Res.VerifyCodeBody }>
) => {
  state.verifyCodeBody = payload.body;
};

const setVerifyCurrentUserBody = (
  state: UserState,
  { payload }: PayloadAction<{ body: User.Res.VerifyCurrentUserBody }>
) => {
  state.verifyCurrentUserBody = payload.body;
};

export default {
  setCurrentUser,
  setCurrentUserLastCaptchaVerification,
  updateCurrentUserFirstName,
  updateCurrentUserLastAddedSchoolAt,
  unsetCurrentUser,
  addCurrentUserEmail,
  addCurrentUserFacebookAccount,
  addCurrentUserVote,
  confirmCurrentUserVote,
  removeCurrentUserVote,
  removeCurrentUserFacebookAccount,
  updateCurrentUserFacebookAccount,
  verifyCurrentUserEmail,
  setGetCurrentUserBody,
  setPatchCurrentUserBody,
  setCreateCurrentUserEmailBody,
  setCreateCurrentUserFacebookAccountBody,
  setPutCurrentUserFacebookAccountBody,
  setGenerateCodeBody,
  setVerifyCodeBody,
  setVerifyCurrentUserBody,
};
