import * as React from "react";
import { NavLink } from "react-router-dom";
import { IMenuOptionState } from "./IMenuOptionState";
import { IMenuOptionProps } from "./IMenuOptionProps";
import "./MenuOption.scss";

class MenuOptionComponent extends React.Component<
  IMenuOptionProps,
  IMenuOptionState
> {
  constructor(props: IMenuOptionProps) {
    super(props);
    this.state = {};
  }

  public render(): JSX.Element {
    const { option } = this.props;
    return (
      <div className={`menuOption`}>
        <NavLink
          className="option-button"
          to={option.path}
          activeClassName="selected"
        >
          <div className="icon">
            <i className={option.iconName}></i>
          </div>
          <div className="title">
            <span>{option.title}</span>
          </div>
        </NavLink>
      </div>
    );
  }
}

export default MenuOptionComponent;
