import { AppState } from "../store";

export const getCurrentUser = (
  appState: AppState
): User.CurrentUser | undefined => {
  const currentUser: User.CurrentUser | undefined = appState.user.currentUser;
  return currentUser;
};

export const getCurrentUserVotes = (
  appState: AppState
): Vote.Vote[] | undefined => {
  return appState.user.currentUser?.votes;
};

export const getIsCurrentUserVerified = (appState: AppState): boolean => {
  const currentUser: User.User | undefined = getCurrentUser(appState);
  if (!currentUser) return false;
  if (!currentUser.lastCaptchaVerification) return false;
  return true;
};

export const getGetCurrentUserBody = (
  appState: AppState
): User.Res.GetCurrentUserBody => {
  const getCurrentUserBody = appState.user.getCurrentUserBody;
  return getCurrentUserBody;
};

export const getPatchCurrentUserBody = (
  appState: AppState
): User.Res.PatchCurrentUserBody => {
  const patchCurrentUserBody = appState.user.patchCurrentUserBody;
  return patchCurrentUserBody;
};

export const getCreateCurrentUserEmailBody = (
  appState: AppState
): User.Res.CreateCurrentUserEmailBody => {
  const createCurrentUserEmailBody = appState.user.createCurrentUserEmailBody;
  return createCurrentUserEmailBody;
};

export const getCreateCurrentUserFacebookAccountBody = (
  appState: AppState
): User.Res.CreateCurrentUserFacebookAccountBody => {
  const createCurrentUserFacebookAccountBody =
    appState.user.createCurrentUserFacebookAccountBody;
  return createCurrentUserFacebookAccountBody;
};

export const getPutCurrentUserFacebookAccountBody = (
  appState: AppState
): User.Res.PutCurrentUserFacebookAccountBody => {
  const putCurrentUserFacebookAccountBody =
    appState.user.putCurrentUserFacebookAccountBody;
  return putCurrentUserFacebookAccountBody;
};

export const getGenerateCodeBody = (
  appState: AppState
): Code.Res.GenerateCodeBody => {
  const generateCodeBody = appState.user.generateCodeBody;
  return generateCodeBody;
};

export const getVerifyCodeBody = (
  appState: AppState
): Code.Res.VerifyCodeBody => {
  const verifyCodeBody = appState.user.verifyCodeBody;
  return verifyCodeBody;
};
