import { getCurrentUser } from "./../user/users.selectors";
import { AppState } from "../store";
import {
  isEmailIsConfirmedForUser,
  isFacebookAccountExistForUser,
} from "../user/users.helpers";

export const getSchoolPreviews = (
  appState: AppState
): School.SchoolPreview[] | undefined => {
  const { schoolPreviews } = appState.school;
  if (!schoolPreviews) return undefined;
  return [...schoolPreviews].sort((schoolA, schoolB) =>
    schoolA.score > schoolB.score ? -1 : 1
  );
};

export const getFilteredSchoolPreviews = (
  appState: AppState
): School.SchoolPreview[] | undefined => {
  return appState.school.filteredSchoolPreviews;
};

export const getSelectedSchoolDetails = (
  appState: AppState
): School.SchoolDetails | undefined => {
  return appState.school.selectedSchoolDetails;
};

export const getCurrentUserVoteInSelectedSchool = (
  appState: AppState
): Vote.Vote | undefined => {
  const currentUser: User.CurrentUser | undefined = getCurrentUser(appState);
  const selectedSchoolDetails:
    | School.SchoolDetails
    | undefined = getSelectedSchoolDetails(appState);

  const currentUserVoteInSelectedSchool: Vote.Vote | undefined =
    currentUser &&
    currentUser.votes &&
    selectedSchoolDetails &&
    currentUser.votes.find(
      (vote) => vote.addedToSchoolId === selectedSchoolDetails._id
    );

  return currentUserVoteInSelectedSchool;
};

export const getCurrentUserVoteStatusInSelectedSchool = (
  appState: AppState
): App.VoteStatus => {
  const currentUser: User.CurrentUser | undefined = getCurrentUser(appState);
  const currentUserVoteInSelectedSchool:
    | Vote.Vote
    | undefined = getCurrentUserVoteInSelectedSchool(appState);

  if (!currentUserVoteInSelectedSchool) return "not_voted";
  if (currentUserVoteInSelectedSchool.method.type === "facebook")
    return isFacebookAccountExistForUser(
      currentUser,
      currentUserVoteInSelectedSchool.method.value
    )
      ? "voted"
      : "not_voted";
  if (currentUserVoteInSelectedSchool.method.type === "email")
    return isEmailIsConfirmedForUser(
      currentUser,
      currentUserVoteInSelectedSchool.method.value
    )
      ? "voted"
      : "in_verification";

  return "not_voted";
};

export const getSchoolPreviewsCount = (appState: AppState): number => {
  const schoolPreviews = getSchoolPreviews(appState);
  return schoolPreviews ? schoolPreviews.length : 0;
};

export const getGetSchoolPreviewsBody = (
  appState: AppState
): School.Res.GetSchoolPreviewsBody => {
  return appState.school.getSchoolPreviewsBody;
};

export const getGetSchoolDetailsByFullNameBody = (
  appState: AppState
): School.Res.GetSchoolDetailsByFullNameBody => {
  return appState.school.getSchoolDetailsBody;
};

export const getCreateMySchoolBody = (
  appState: AppState
): School.Res.CreateMySchoolBody => {
  const createMySchoolBody = appState.school.createMySchoolBody;
  return createMySchoolBody;
};

export const getBestSchool = (
  appState: AppState
): School.SchoolPreview | undefined => {
  const schoolPreviews: School.SchoolPreview[] | undefined = getSchoolPreviews(
    appState
  );
  return schoolPreviews && schoolPreviews[0];
};

export const getWorstSchool = (
  appState: AppState
): School.SchoolPreview | undefined => {
  const schoolPreviews: School.SchoolPreview[] | undefined = getSchoolPreviews(
    appState
  );
  const schoolPreviewsCount: number = getSchoolPreviewsCount(appState);
  return schoolPreviews && schoolPreviews[schoolPreviewsCount - 1];
};

export const getSchoolPodium = (appState: AppState): App.SchoolPodium => {
  const schoolPreviews: School.SchoolPreview[] | undefined = getSchoolPreviews(
    appState
  );

  const schoolPodium: App.SchoolPodium = schoolPreviews
    ? {
        first: schoolPreviews[0],
        second: schoolPreviews[1],
        third: schoolPreviews[2],
      }
    : {
        first: undefined,
        second: undefined,
        third: undefined,
      };

  return schoolPodium;
};

export const getSelectedSchoolDetailsGoogleReviews = (
  appState: AppState
): School.GoogleReview[] => {
  const selectedSchoolDetails:
    | School.SchoolDetails
    | undefined = getSelectedSchoolDetails(appState);
  if (!selectedSchoolDetails) return [];
  return selectedSchoolDetails.googleReviews;
};
