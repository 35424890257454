import * as React from "react";
import { ISchoolDetailsVoteCloudSuccessStageState } from "./ISchoolDetailsVoteCloudSuccessStageState";
import { ISchoolDetailsVoteCloudSuccessStageProps } from "./ISchoolDetailsVoteCloudSuccessStageProps";
import "./SchoolDetailsVoteCloudSuccessStage.scss";
import SchoolDetailsVoteIcon from "../SchoolDetailsVoteIcon/SchoolDetailsVoteIcon";
import { Button } from "@material-ui/core";
import { withRouter } from "react-router";

class SchoolDetailsVoteCloudSuccessStage extends React.Component<
  ISchoolDetailsVoteCloudSuccessStageProps,
  ISchoolDetailsVoteCloudSuccessStageState
> {
  constructor(props: ISchoolDetailsVoteCloudSuccessStageProps) {
    super(props);
    this.state = {};
  }

  public handleCloseButtonClick = () => {
    const { onClose } = this.props;
    onClose && onClose();
  };

  public handleShareButtonClick = () => {
    const { onShare } = this.props;
    onShare && onShare();
  };

  public render(): JSX.Element {
    const { selectedVoteType } = this.props;
    return (
      <div className={`schoolDetailsVoteCloudSuccessStage`}>
        <div className="body">
          <div className="vote-icon">
            <SchoolDetailsVoteIcon selectedVoteType={selectedVoteType} />
          </div>
          <div className="form">
            <div>
              <p className="info">
                Dziękujemy za poświęcenie czasu, głos trafił już do naszej bazy!
              </p>
            </div>
          </div>
        </div>
        <div className="footer">
          <Button
            className="close-button"
            onClick={this.handleCloseButtonClick}
          >
            Zamknij
          </Button>
          <Button
            className="app-button share-button"
            onClick={this.handleShareButtonClick}
          >
            Udostępnij
          </Button>
        </div>
      </div>
    );
  }
}

export default withRouter(SchoolDetailsVoteCloudSuccessStage);
