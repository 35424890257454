import * as React from "react";
import { ISchoolDetailsContainerState } from "./ISchoolDetailsContainerState";
import {
  ISchoolDetailsContainerDispatchProps,
  ISchoolDetailsContainerProps,
  ISchoolDetailsContainerStateProps,
} from "./ISchoolDetailsContainerProps";
import SchoolDetails from "../SchoolDetails/SchoolDetails";
import {
  getAddVoteAuth,
  getAddVoteForm,
  getEmailVerificationForm,
  getIsMinimalizable,
  getIsOnSide,
  getIsSchoolDetailsMinimalize,
  getIsWindow,
  getLastEmailResendTimestamp,
  getScrollTop,
  getSelectedCurrentUserFacebookAccount,
  getSelectedVoteMethodType,
  getSelectedVoteMethodValue,
  getSelectedVoteType,
  getWindowHeight,
  getWindowWidth,
} from "../../../store/@ui/ui.selectors";
import {
  resetAddVoteFormAction,
  setAddVoteAuthAction,
  setAddVoteFormAction,
  setEmailVerificationFormAction,
  setIsSchoolDetailsMinimalizeAction,
  setIsShareWindowClosedAction,
  setSelectedVoteMethodTypeAction,
  setSelectedVoteTypeAction,
} from "../../../store/@ui/ui.actions";
import {
  getCreateCurrentUserEmailBody,
  getCreateCurrentUserFacebookAccountBody,
  getCurrentUser,
  getGenerateCodeBody,
  getIsCurrentUserVerified,
  getPatchCurrentUserBody,
  getPutCurrentUserFacebookAccountBody,
  getVerifyCodeBody,
} from "../../../store/user/users.selectors";

import {
  getCreateCurrentVoteBody,
  getCurrentUserVotes,
  getDeleteCurrentVoteBody,
  getIsCurrentUserLimitReached,
  getNegativeVotesInLastThreeDays,
  getPositiveVotesInLastOneDay,
} from "../../../store/vote/votes.selectors";
import {
  createVoteFromConfirmedEmailOperation,
  createVoteFromExistedEmailOperation,
  createVoteFromExistedFacebookAccountOperation,
  createVoteFromNewEmailOperation,
  deleteCurrentVoteOperation,
} from "../../../store/vote/votes.operations";
import {
  createCurrentUserFacebookAccountOperation,
  generateEmailVerificationCodeOperation,
  putCurrentUserFacebookAccountOperation,
  patchCurrentUserOperation,
  verifyEmailOperation,
} from "../../../store/user/users.operations";
import { withRouter } from "react-router";

import { AppDispatch, AppState } from "../../../store/store";
import { connect } from "react-redux";
import {
  getCurrentUserVoteInSelectedSchool,
  getCurrentUserVoteStatusInSelectedSchool,
  getGetSchoolDetailsByFullNameBody,
  getSelectedSchoolDetails,
} from "../../../store/school/school.selectors";
import schoolsOperations from "../../../store/school/schools.operations";
import queryString from "query-string";
import {
  encodeSchoolUrl,
  getSchoolFullName,
  getSelectedSectionType,
} from "../../../utils/utils";

const mapStateToProps = (
  appState: AppState
): ISchoolDetailsContainerStateProps => ({
  currentUser: getCurrentUser(appState),
  currentUserVoteInSelectedSchool: getCurrentUserVoteInSelectedSchool(appState),
  currentUserVoteStatusInSelectedSchool: getCurrentUserVoteStatusInSelectedSchool(
    appState
  ),
  isCurrentUserVerified: getIsCurrentUserVerified(appState),
  isCurrentUserLimitReached: getIsCurrentUserLimitReached(appState),
  negativeVotesInLastThreeDays: getNegativeVotesInLastThreeDays(appState),
  positiveVotesInLastOneDay: getPositiveVotesInLastOneDay(appState),
  currentUserVotes: getCurrentUserVotes(appState),
  selectedSchoolDetails: getSelectedSchoolDetails(appState),
  selectedVoteType: getSelectedVoteType(appState),
  selectedVoteMethodType: getSelectedVoteMethodType(appState),
  selectedVoteMethodValue: getSelectedVoteMethodValue(appState),
  selectedCurrentUserFacebookAccount: getSelectedCurrentUserFacebookAccount(
    appState
  ),
  getSchoolDetailsBody: getGetSchoolDetailsByFullNameBody(appState),
  patchCurrentUserBody: getPatchCurrentUserBody(appState),
  verifyCodeBody: getVerifyCodeBody(appState), // selector
  generateCodeBody: getGenerateCodeBody(appState),
  createCurrentVoteBody: getCreateCurrentVoteBody(appState), // selector
  deleteCurrentVoteBody: getDeleteCurrentVoteBody(appState),
  createCurrentUserEmailBody: getCreateCurrentUserEmailBody(appState),
  createCurrentUserFacebookAccountBody: getCreateCurrentUserFacebookAccountBody(
    appState
  ),
  putCurrentUserFacebookAccountBody: getPutCurrentUserFacebookAccountBody(
    appState
  ),
  scrollTop: getScrollTop(appState),
  isSchoolDetailsMinimalize: getIsSchoolDetailsMinimalize(appState),
  lastEmailResendTimestamp: getLastEmailResendTimestamp(appState),
  addVoteForm: getAddVoteForm(appState),
  addVoteAuth: getAddVoteAuth(appState),
  emailVerificationForm: getEmailVerificationForm(appState),
  windowWidth: getWindowWidth(appState),
  windowHeight: getWindowHeight(appState),
  isMinimalizable: getIsMinimalizable(appState),
  isOnSide: getIsOnSide(appState),
  isWindow: getIsWindow(appState),
});

const mapDispatchToProps = (
  dispatch: AppDispatch
): ISchoolDetailsContainerDispatchProps => ({
  onFetchSchoolByFullName: (selectedFullName, index) => {
    dispatch(
      schoolsOperations.fetchSchoolDetailsOperation({
        fullName: selectedFullName,
        index,
      })
    );
  },
  onFetchBestSchool: () => {
    dispatch(schoolsOperations.fetchBestSchoolDetailsOperation({}));
  },
  onMaximalize: () => {
    dispatch(
      setIsSchoolDetailsMinimalizeAction({ isSchoolDetailsMinimalize: false })
    );
  },
  onMinimalize: () => {
    dispatch(
      setIsSchoolDetailsMinimalizeAction({ isSchoolDetailsMinimalize: true })
    );
  },
  onSetSelectedVoteType: (selectedVoteType) => {
    dispatch(setSelectedVoteTypeAction({ selectedVoteType }));
  },
  onSetSelectedVoteMethod: (selectedVoteMethodType) => {
    dispatch(setSelectedVoteMethodTypeAction({ selectedVoteMethodType }));
  },
  onUnselectVoteMethod: () => {
    dispatch(
      setSelectedVoteMethodTypeAction({ selectedVoteMethodType: undefined })
    );
  },
  onSelectEmailVoteMethod: () => {
    dispatch(
      setSelectedVoteMethodTypeAction({ selectedVoteMethodType: "email" })
    );
  },
  onFacebookAccountCreate: (event) => {
    dispatch(createCurrentUserFacebookAccountOperation(event));
  },
  onFacebookAccountUpdate: (event) => {
    dispatch(putCurrentUserFacebookAccountOperation(event));
  },
  onVoteDelete: (event) => {
    dispatch(deleteCurrentVoteOperation(event));
  },
  onVoteCreateFromNewEmail: (event) => {
    dispatch(createVoteFromNewEmailOperation(event));
  },
  onVoteCreateFromExistedEmail: (event) => {
    dispatch(createVoteFromExistedEmailOperation(event));
  },
  onVoteCreateFromConfirmedEmail: (event) => {
    dispatch(createVoteFromConfirmedEmailOperation(event));
  },
  onVoteCreateFromExistedFacebookAccount: (event) => {
    dispatch(createVoteFromExistedFacebookAccountOperation(event));
  },
  onFirstNameUpdate: (event) => {
    dispatch(patchCurrentUserOperation(event));
  },
  onEmailVerify: (event) => {
    dispatch(verifyEmailOperation(event));
  },
  onEmailGenerateCode: (event) => {
    dispatch(generateEmailVerificationCodeOperation(event));
  },
  onUpdateVoteForm: (event) => {
    dispatch(setAddVoteFormAction(event));
  },
  onUpdateEmailVerificationForm: (event) => {
    dispatch(setEmailVerificationFormAction(event));
  },
  onResetVoteForm: () => {
    dispatch(resetAddVoteFormAction({}));
  },
  onResetVoteAuth: () => {
    dispatch(setAddVoteAuthAction({ otherError: undefined }));
  },
  onShare: () => {
    dispatch(setIsShareWindowClosedAction({ isClosed: false }));
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);

class SchoolDetailsContainer extends React.Component<
  ISchoolDetailsContainerProps,
  ISchoolDetailsContainerState
> {
  constructor(props: ISchoolDetailsContainerProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { match, selectedSchoolDetails } = this.props;
    const { schoolFullName } = match.params;
    this.handleSchoolFullNameParamChange(schoolFullName);
    this.handleSelectedSchoolDetailsChange(selectedSchoolDetails);
  }

  componentDidUpdate(oldProps: ISchoolDetailsContainerProps) {
    const { props } = this;

    if (
      props.match.params.schoolFullName !== oldProps.match.params.schoolFullName
    ) {
      const { schoolFullName } = props.match.params;
      this.handleSchoolFullNameParamChange(schoolFullName);
    }
    if (props.selectedSchoolDetails !== oldProps.selectedSchoolDetails) {
      const { selectedSchoolDetails } = props;
      this.handleSelectedSchoolDetailsChange(selectedSchoolDetails);
    }
  }

  public handleSelectedSchoolDetailsChange(
    selectedSchoolDetails: School.SchoolDetails | undefined
  ) {
    const { match } = this.props;

    selectedSchoolDetails &&
      !match.params.schoolFullName &&
      this.props.history.push(
        `/ranking/${encodeSchoolUrl(selectedSchoolDetails)}`
      );
  }

  public handleSchoolFullNameParamChange(schoolFullName: string | undefined) {
    const {
      onFetchSchoolByFullName,
      onFetchBestSchool,
      isWindow,
      isOnSide,
      selectedSchoolDetails,
      onMaximalize,
      location,
    } = this.props;

    if (
      selectedSchoolDetails &&
      (!schoolFullName ||
        schoolFullName ===
          getSchoolFullName(selectedSchoolDetails).replaceAll(" ", "-"))
    )
      return;

    const searchObj = queryString.parse(location.search);
    const index: number | undefined = searchObj["index"]
      ? Number(searchObj["index"])
      : undefined;

    schoolFullName &&
      onFetchSchoolByFullName &&
      onFetchSchoolByFullName(schoolFullName, index);
    schoolFullName && (isWindow || isOnSide) && onMaximalize && onMaximalize();
    !schoolFullName && !isWindow && onFetchBestSchool && onFetchBestSchool();
  }

  public handleSetSelectedSectionType = (newSectionType: string) => {
    const { pathname, search } = this.props.location;
    const searchObj = queryString.parse(search);
    this.props.history.push({
      pathname,
      search: queryString.stringify({ ...searchObj, section: newSectionType }),
    });
  };

  public handleShare = () => {
    const { onShare } = this.props;
    onShare && onShare();
  };

  public render(): JSX.Element {
    const { location } = this.props;
    return (
      <SchoolDetails
        {...this.props}
        onSetSelectedSectionType={this.handleSetSelectedSectionType}
        selectedSectionType={getSelectedSectionType(
          queryString.parse(location.search)
        )}
        onShare={this.handleShare}
      />
    );
  }
}

export default withRouter(connector(SchoolDetailsContainer));
