import { getCurrentUser } from "./../user/users.selectors";
import { AppState } from "./../store";

export const getIsAcceptedCookie = (appState: AppState): boolean => {
  return appState.UI.isAcceptedCookie;
};

export const getIsVerifiedByCaptcha = (appState: AppState): boolean => {
  const isVerifiedByCaptcha: boolean = appState.UI.isVerifiedByCaptcha;
  return isVerifiedByCaptcha;
};

export const getSelectedVoteType = (
  appState: AppState
): System.VoteType | undefined => {
  const selectedVoteType: System.VoteType | undefined =
    appState.UI.selectedVoteType;
  return selectedVoteType;
};

export const getSelectedVoteMethodType = (
  appState: AppState
): System.VoteMethodType | undefined => {
  const selectedVoteMethodType: System.VoteMethodType | undefined =
    appState.UI.selectedVoteMethodType;
  return selectedVoteMethodType;
};

export const getSelectedVoteMethodValue = (
  appState: AppState
): string | undefined => {
  const selectedVoteMethodValue: string | undefined =
    appState.UI.selectedVoteMethodValue;
  return selectedVoteMethodValue;
};

export const getSelectedCurrentUserFacebookAccount = (
  appState: AppState
): User.FacebookAccount | undefined => {
  const currentUser = getCurrentUser(appState);
  const selectedVoteMethodType = getSelectedVoteMethodType(appState);
  const selectedVoteMethodValue = getSelectedVoteMethodValue(appState);
  if (!currentUser) return undefined;
  if (!selectedVoteMethodType) return undefined;
  if (selectedVoteMethodType !== "facebook") return undefined;
  if (!selectedVoteMethodValue) return undefined;
  const selectedCurrentUserFacebookAccount:
    | User.FacebookAccount
    | undefined = currentUser.facebookAccounts.find(
    (account) => account.userID === selectedVoteMethodValue
  );
  return selectedCurrentUserFacebookAccount;
};

export const getProvinceTypeFilter = (
  appState: AppState
): System.ProvinceType | undefined => {
  return appState.UI.provinceTypeFilter;
};

export const getSchoolNameFilter = (appState: AppState): string | undefined => {
  return appState.UI.schoolNameFilter;
};

export const getIsFilteredSchools = (appState: AppState): boolean => {
  const provinceTypeFilter = getProvinceTypeFilter(appState);
  const schoolNameFilter = getSchoolNameFilter(appState);

  return Boolean(provinceTypeFilter || schoolNameFilter);
};

export const getAddVoteAuth = (appState: AppState) => {
  return appState.UI.addVoteAuth;
};

export const getRangeOfSchoolsDisplayed = (
  appState: AppState
): [number, number] => {
  return appState.UI.rangeOfSchoolsDisplayed;
};

export const getScrollTop = (appState: AppState): number => {
  return appState.UI.scrollTop;
};

export const getScrollHeight = (appState: AppState): number => {
  return appState.UI.scrollHeight;
};

export const getWindowWidth = (appState: AppState): number => {
  return appState.UI.windowWidth;
};

export const getWindowHeight = (appState: AppState): number => {
  return appState.UI.windowHeight;
};

export const getIsMinimalizable = (appState: AppState): boolean => {
  const windowWidth: number = appState.UI.windowWidth;
  const isMinimalizable: boolean = windowWidth < 1153;

  return isMinimalizable;
};

export const getIsOnSide = (appState: AppState): boolean => {
  const windowWidth: number = appState.UI.windowWidth;
  const windowHeight: number = appState.UI.windowHeight;
  const isOnSide: boolean =
    windowWidth < 1153 && (windowWidth > 650 || windowWidth > windowHeight);

  return isOnSide;
};

export const getIsWindow = (appState: AppState): boolean => {
  const windowWidth: number = appState.UI.windowWidth;
  const windowHeight: number = appState.UI.windowHeight;

  const isWindow: boolean = windowWidth < 650 && windowWidth < windowHeight;
  return isWindow;
};

export const getIsSchoolDetailsMinimalize = (appState: AppState): boolean => {
  return appState.UI.isSchoolDetailsMinimalize;
};

/** @deprecated */
export const getSelectedSectionType = (
  appState: AppState
): App.SchoolDetailsSectionType => {
  return appState.UI.selectedSectionType;
};

export const getLastEmailResendTimestamp = (
  appState: AppState
): number | undefined => {
  return appState.UI.lastEmailResendTimestamp;
};

export const getAddSchoolForm = (appState: AppState): App.SchoolForm => {
  return appState.UI.addSchoolForm;
};

export const getAddVoteForm = (appState: AppState): App.VoteForm => {
  return appState.UI.addVoteForm;
};

export const getEmailVerificationForm = (
  appState: AppState
): App.EmailVerificationForm => {
  return appState.UI.emailVerificationForm;
};

export const getIsShareWindowClosed = (appState: AppState): boolean => {
  return appState.UI.isShareWindowClosed;
};

export const getIsIframeWindowClosed = (appState: AppState): boolean => {
  return appState.UI.isIframeWindowClosed;
};
