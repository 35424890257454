import * as React from "react";
import { IFrameState } from "./IFrameState";
import { IFrameProps } from "./IFrameProps";
import "./Frame.scss";
import { motion } from "framer-motion";

class Frame extends React.Component<IFrameProps, IFrameState> {
  contentWrapperRef: React.RefObject<HTMLDivElement> = React.createRef();

  constructor(props: IFrameProps) {
    super(props);
    this.state = {
      scrollPosition: null,
    };
  }

  componentDidMount() {
    this.checkContentWrapper();
  }

  componentDidUpdate(prevProps: IFrameProps, prevState: IFrameState) {
    if (prevState !== this.state) return;
    this.checkContentWrapper();

    if (prevProps.currentRowIndex !== this.props.currentRowIndex)
      this.handleNextCurrentRowIndex();
  }

  handleNextCurrentRowIndex = () => {
    const { contentWrapperRef } = this;
    const { currentRowIndex } = this.props;
    const currentRow: ChildNode | undefined = this.getRow(
      contentWrapperRef.current,
      currentRowIndex
    );
    currentRow &&
      contentWrapperRef.current?.scrollTo(
        0,
        (currentRow as HTMLDivElement).offsetTop
      );
  };

  checkContentWrapper = () => {
    const { current } = this.contentWrapperRef;
    current && this.setScrollPosition(current);
  };

  handleContentWrapperScroll = (event: React.UIEvent) => {
    this.setScrollPosition(event.target as HTMLDivElement);
  };

  setScrollPosition = (contentWrapper: HTMLDivElement) => {
    const { scrollHeight, scrollTop, clientHeight } = contentWrapper;

    if (scrollHeight === clientHeight) this.setState({ scrollPosition: null });
    else if (scrollHeight - scrollTop === clientHeight)
      this.setState({ scrollPosition: "bottom" });
    else if (!scrollTop) this.setState({ scrollPosition: "top" });
    else this.setState({ scrollPosition: "middle" });
  };

  public getRow = (
    contentWrapper: HTMLDivElement | null,
    rowIndex: number
  ): ChildNode | undefined => {
    const nodes = contentWrapper?.firstChild?.firstChild?.childNodes;
    if (!nodes) return undefined;
    const row: ChildNode | undefined = nodes[rowIndex];
    return row;
  };

  private handleScrollButtonClick = () => {
    const { contentWrapperRef } = this;
    const { currentRowIndex, onScrollDownClick } = this.props;
    const nextRow = this.getRow(contentWrapperRef.current, currentRowIndex + 1);
    if (!nextRow) return;
    onScrollDownClick && onScrollDownClick(currentRowIndex + 1);
  };

  public render(): JSX.Element {
    const { children, className } = this.props;
    const { scrollPosition } = this.state;

    return (
      <div className={`Frame ${className}`}>
        <div
          className="shadow up-shadow"
          style={{
            opacity: scrollPosition && scrollPosition !== "top" ? 1 : 0,
          }}
        ></div>
        <motion.div
          className="content-wrapper"
          onScroll={this.handleContentWrapperScroll}
          ref={this.contentWrapperRef}
        >
          <div className="content">{children}</div>
        </motion.div>
        <div
          className="scroll-button-wrapper"
          style={{
            opacity: scrollPosition && scrollPosition !== "bottom" ? 1 : 0,
          }}
        >
          <button
            className="scroll-button"
            onClick={this.handleScrollButtonClick}
          >
            <i className="icon icon-down-open"></i>
          </button>
        </div>
        <div
          className="shadow down-shadow"
          style={{
            opacity: scrollPosition && scrollPosition !== "bottom" ? 1 : 0,
          }}
        ></div>
      </div>
    );
  }
}

export default Frame;
