export const isUserExist = (user: User.User | undefined): boolean => {
  return Boolean(user);
};

export const isEmailConfirmedByOtherUser = (
  otherUsers: User.User[],
  excludedUser: User.User,
  emailAddress: string
): boolean => {
  const otherUserWithConfirmedEmail: User.User | undefined = otherUsers.find(
    (user) =>
      String(user._id) !== String(excludedUser._id) &&
      user.emails.find(
        (email) => email.address === emailAddress && email.isConfirmed
      )
  );
  return Boolean(otherUserWithConfirmedEmail);
};

export const isEmailExistForUser = (
  user: User.User | undefined,
  emailAddress: string
): boolean => {
  if (!user) return false;
  const userEmail: User.UserEmail | undefined = user.emails.find(
    (email) => emailAddress === email.address
  );
  return Boolean(userEmail);
};

export const isAnyEmailIsConfirmedForUser = (
  user: User.User | undefined
): boolean => {
  if (!user) return false;
  const userEmail: User.UserEmail | undefined = user.emails.find(
    (email) => email.isConfirmed
  );
  return Boolean(userEmail);
};

export const isEmailIsConfirmedForUser = (
  user: User.User | undefined,
  emailAddress: string
): boolean => {
  if (!user) return false;
  const userEmail: User.UserEmail | undefined = user.emails.find(
    (email) => emailAddress === email.address && email.isConfirmed
  );
  return Boolean(userEmail);
};

export const isAnyFacebookAccountExistForUser = (
  user: User.User | undefined
): boolean => {
  if (!user) return false;
  return Boolean(user.facebookAccounts.length);
};

export const isFacebookAccountExistForUser = (
  user: User.User | undefined,
  fbUserID: string
): boolean => {
  if (!user) return false;
  const facebookAccount:
    | User.FacebookAccount
    | undefined = user.facebookAccounts.find(
    (account) => account.userID === fbUserID
  );
  return Boolean(facebookAccount);
};
