import { incrementThumbsDownAction } from "./../school/schools.actions";
import {
  addCurrentUserVoteAction,
  removeCurrentUserVoteAction,
  setCreateCurrentUserEmailBodyAction,
  setGenerateCodeBodyAction,
} from "./../user/users.actions";
import { createCurrentVote, deleteCurrentVote } from "./votes.service";
import {
  createCurrentUserEmail,
  generateEmailVerificationCode,
} from "./../user/users.service";
import { AppDispatch, AppThunkAction } from "../store";

import { addCurrentUserEmailAction } from "../user/users.actions";
import {
  setCreateCurrentVoteBodyAction,
  setDeleteCurrentVoteBodyAction,
} from "./vote.actions";
import {
  setAddVoteFormEmailAddressErrorAction,
  setAddVoteFormOtherErrorAction,
  setLastEmailResendTimestampAction,
} from "../@ui/ui.actions";
import {
  addSelectedSchoolDetailsVoteBriefAction,
  incrementThumbsUpAction,
} from "../school/schools.actions";

export type CreateVoteFromNewEmailOperationPayload = {
  type: -1 | 1;
  method: Vote.VoteMethod;
  addedToSchoolId: string;
  comment?: string;
  firstName?: string;
};

export const createVoteFromNewEmailOperation = (
  payload: CreateVoteFromNewEmailOperationPayload
): AppThunkAction => async (dispatch: AppDispatch) => {
  dispatch(setCreateCurrentUserEmailBodyAction({ status: "pending" }));
  const address: string = payload.method.value;
  // prettier-ignore
  const createCurrentUserEmailRes = await createCurrentUserEmail({ address  });

  dispatch(setCreateCurrentUserEmailBodyAction(createCurrentUserEmailRes));
  if (createCurrentUserEmailRes.error) {
    switch (createCurrentUserEmailRes.error.type) {
      case "email_is_confirmed_by_other_user":
        dispatch(
          setAddVoteFormEmailAddressErrorAction({
            emailAddressError: `"E-mail" jest już zajęty`,
          })
        );
        break;
    }
    return;
  }
  if (!createCurrentUserEmailRes.payload) return;
  // prettier-ignore
  dispatch(addCurrentUserEmailAction({ email: createCurrentUserEmailRes.payload.createdCurrentUserEmail }));

  // prettier-ignore
  dispatch(setCreateCurrentVoteBodyAction({ status: "pending" }));
  const createCurrentVoteRes = await createCurrentVote(payload);
  dispatch(setCreateCurrentVoteBodyAction(createCurrentVoteRes));
  if (createCurrentVoteRes.error) {
    switch (createCurrentVoteRes.error.type) {
      case "vote_has_already_added":
        dispatch(
          setAddVoteFormOtherErrorAction({
            otherError: `Głos został już oddany!`,
          })
        );
        break;
      default:
        dispatch(
          setAddVoteFormOtherErrorAction({
            otherError: `Wystąpił nieoczekiwany błąd`,
          })
        );
    }
    return;
  }
  if (!createCurrentVoteRes.payload) return;
  // prettier-ignore
  dispatch(addCurrentUserVoteAction({ vote: createCurrentVoteRes.payload.createdVote }));

  dispatch(setGenerateCodeBodyAction({ status: "pending" }));
  // prettier-ignore
  const generateCodeBody = await generateEmailVerificationCode({emailAddress: address});
  // prettier-ignore
  const lastEmailResendTimestamp: number = Date.now();
  dispatch(setLastEmailResendTimestampAction({ lastEmailResendTimestamp }));
  dispatch(setGenerateCodeBodyAction(generateCodeBody));

  if (!generateCodeBody.payload) return;
};

/** */

export type CreateVoteFromExistedEmailOperationPayload = {
  type: -1 | 1;
  method: Vote.VoteMethod;
  addedToSchoolId: string;
  comment?: string;
  firstName?: string;
};

export const createVoteFromExistedEmailOperation = (
  payload: CreateVoteFromExistedEmailOperationPayload
): AppThunkAction => async (dispatch: AppDispatch) => {
  dispatch(setCreateCurrentVoteBodyAction({ status: "pending" }));
  const createCurrentVoteRes = await createCurrentVote(payload);
  dispatch(setCreateCurrentVoteBodyAction(createCurrentVoteRes));
  if (createCurrentVoteRes.error) {
    switch (createCurrentVoteRes.error.type) {
      case "vote_has_already_added":
        dispatch(
          setAddVoteFormOtherErrorAction({
            otherError: `Głos został już oddany!`,
          })
        );
        break;
      default:
        dispatch(
          setAddVoteFormOtherErrorAction({
            otherError: `Wystąpił nieoczekiwany błąd`,
          })
        );
    }
    return;
  }
  if (!createCurrentVoteRes.payload) return;
  // prettier-ignore
  dispatch(addCurrentUserVoteAction({ vote: createCurrentVoteRes.payload.createdVote }));
};

/** */

export type CreateVoteFromConfirmedEmailOperationPayload = {
  type: -1 | 1;
  method: Vote.VoteMethod;
  addedToSchoolId: string;
  comment?: string;
  firstName?: string;
};

export const createVoteFromConfirmedEmailOperation = (
  payload: CreateVoteFromConfirmedEmailOperationPayload
): AppThunkAction => async (dispatch: AppDispatch) => {
  // prettier-ignore
  dispatch(setCreateCurrentVoteBodyAction({ status: "pending" }));
  const createCurrentVoteRes = await createCurrentVote(payload);
  dispatch(setCreateCurrentVoteBodyAction(createCurrentVoteRes));
  if (createCurrentVoteRes.error) {
    switch (createCurrentVoteRes.error.type) {
      case "vote_has_already_added":
        dispatch(
          setAddVoteFormOtherErrorAction({
            otherError: `Głos został już oddany!`,
          })
        );
        break;
      default:
        dispatch(
          setAddVoteFormOtherErrorAction({
            otherError: `Wystąpił nieoczekiwany błąd`,
          })
        );
    }
    return;
  }
  if (!createCurrentVoteRes.payload) return;
  const vote = createCurrentVoteRes.payload.createdVote;
  // prettier-ignore
  dispatch(addCurrentUserVoteAction({ vote: vote }));
  // prettier-ignore
  if(vote.type === 1) dispatch(incrementThumbsUpAction({ schoolPreviewId: vote.addedToSchoolId }));
  // prettier-ignore
  if(vote.type === -1) dispatch(incrementThumbsDownAction({schoolPreviewId: vote.addedToSchoolId}))
  // prettier-ignore
  dispatch(addSelectedSchoolDetailsVoteBriefAction({
    addedToSchoolId: vote.addedToSchoolId,
    voteBrief: {
      _id: vote._id, 
      type: vote.type,
      created_at: vote.created_at,
      firstName: vote.firstName,
      comment: vote.comment
    }
  }))
};

/** */

export type CreateVoteFromExistedFacebookAccountOperationPayload = {
  type: -1 | 1;
  method: Vote.VoteMethod;
  addedToSchoolId: string;
  comment?: string;
  firstName?: string;
};

export const createVoteFromExistedFacebookAccountOperation = (
  payload: CreateVoteFromExistedFacebookAccountOperationPayload
): AppThunkAction => async (dispatch: AppDispatch) => {
  // prettier-ignore
  dispatch(setCreateCurrentVoteBodyAction({ status: "pending" }));
  const createCurrentVoteRes = await createCurrentVote(payload);
  dispatch(setCreateCurrentVoteBodyAction(createCurrentVoteRes));
  if (!createCurrentVoteRes.payload) return;
  // prettier-ignore
  const vote = createCurrentVoteRes.payload.createdVote;
  // prettier-ignore
  dispatch(addCurrentUserVoteAction({ vote: vote }));
  // prettier-ignore
  if(vote.type === 1) dispatch(incrementThumbsUpAction({ schoolPreviewId: vote.addedToSchoolId }));
  // prettier-ignore
  if(vote.type === -1) dispatch(incrementThumbsDownAction({schoolPreviewId: vote.addedToSchoolId}))
  // prettier-ignore
  dispatch(addSelectedSchoolDetailsVoteBriefAction({
    addedToSchoolId: vote.addedToSchoolId,
    voteBrief: {
      _id: vote._id, 
      type: vote.type,
      created_at: vote.created_at,
      firstName: vote.firstName,
      comment: vote.comment
    }
  }))
};

/** */

export type DeleteCurrentVoteOperationPayload = {
  id: string;
};

export const deleteCurrentVoteOperation = (
  payload: DeleteCurrentVoteOperationPayload
): AppThunkAction => async (dispatch: AppDispatch) => {
  // prettier-ignore
  dispatch(setDeleteCurrentVoteBodyAction({ status: "pending" }));
  const deleteCurrentVoteRes = await deleteCurrentVote(payload);
  dispatch(setDeleteCurrentVoteBodyAction(deleteCurrentVoteRes));
  if (deleteCurrentVoteRes.status === "error") return;
  // prettier-ignore
  dispatch(removeCurrentUserVoteAction({voteId: payload.id}));
};

export default {
  createVoteFromNewEmailOperation,
  createVoteFromExistedEmailOperation,
  createVoteFromConfirmedEmailOperation,
  deleteCurrentVoteOperation,
};
