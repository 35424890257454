import * as React from "react";
import { IButtonState } from "./IButtonState";
import { IButtonProps } from "./IButtonProps";
import * as CSS from "csstype";

import "./Button.scss";

class Button extends React.Component<IButtonProps, IButtonState> {
  constructor(props: IButtonProps) {
    super(props);
    this.state = {
      isHover: false,
    };
  }

  private getBorder = (
    border: "top" | "bottom" | undefined
  ): string | undefined => {
    if (!border) return undefined;
    return border === "top" ? "border-top" : "border-bottom";
  };

  private handleButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    const { onClick } = this.props;
    onClick && onClick(event);
  };

  private handleButtonMouseOver = (event: React.MouseEvent): void => {
    this.setState({
      isHover: true,
    });
  };

  private handleButtonMouseOut = (event: React.MouseEvent): void => {
    this.setState({
      isHover: false,
    });
  };

  private getStyle = (): CSS.Properties => {
    const {
      colorTheme,
      hoverColorTheme,
      selectedColorTheme,
      isSelected,
    } = this.props;
    const { isHover } = this.state;

    if (isSelected)
      return {
        borderColor: selectedColorTheme || hoverColorTheme,
        color: selectedColorTheme || hoverColorTheme,
      };
    if (isHover)
      return { borderColor: hoverColorTheme, color: hoverColorTheme };
    return { borderColor: colorTheme, color: colorTheme };
  };

  public render(): JSX.Element {
    const { children, className, style, border, disabled } = this.props;

    const {
      getBorder,
      getStyle,
      handleButtonClick,
      handleButtonMouseOver,
      handleButtonMouseOut,
    } = this;
    return (
      <button
        className={`Button ${className} ${getBorder(border)}`}
        style={{
          ...style,
          ...getStyle(),
        }}
        onClick={handleButtonClick}
        onMouseOver={handleButtonMouseOver}
        onMouseOut={handleButtonMouseOut}
        disabled={disabled}
      >
        {children}
      </button>
    );
  }
}

export default Button;
