import * as React from "react";
import { IMonitorIconState } from "./IMonitorIconState";
import { IMonitorIconProps } from "./IMonitorIconProps";
import "./MonitorIcon.scss";

class MonitorIcon extends React.Component<
  IMonitorIconProps,
  IMonitorIconState
> {
  constructor(props: IMonitorIconProps) {
    super(props);
    this.state = {};
  }

  public render(): JSX.Element {
    const { text, borderColor, size, borderWidth } = this.props;

    return (
      <div
        className={`MonitorIcon`}
        style={{ width: `${size}px`, height: `${size && size * 0.7}px` }}
      >
        <div className="screen" style={{ borderColor, borderWidth }}>
          <span style={{ fontSize: `${size && size * 0.22}px` }}>{text}</span>
        </div>
        <div className="stand" style={{ borderColor, borderWidth }}></div>
        <div className="underlay" style={{ borderColor, borderWidth }}></div>
      </div>
    );
  }
}

export default MonitorIcon;
