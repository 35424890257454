export const isLimitReached = (
  userVotes: Vote.Vote[],
  voteType: -1 | 1,
  now: Date
): boolean => {
  // max. 3 głosy pozytywne na 24h
  // max. 1 głos negatywny na 72h
  const maxVotes: number = voteType === 1 ? 3 : 1;
  const perNumerOfDays: number = voteType === 1 ? 1 : 3;
  return (
    userVotes
      .filter((vote) => vote.type === voteType)
      .filter(
        (vote) =>
          new Date(vote.created_at).getTime() >
          now.getTime() - perNumerOfDays * 1000 * 3600 * 24
      ).length >= maxVotes
  );
};
