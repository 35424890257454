import * as React from "react";
import { ISchoolDetailsVoteIconState } from "./ISchoolDetailsVoteIconState";
import { ISchoolDetailsVoteIconProps } from "./ISchoolDetailsVoteIconProps";
import "./SchoolDetailsVoteIcon.scss";
import { motion } from "framer-motion";

class SchoolDetailsVoteIcon extends React.Component<
  ISchoolDetailsVoteIconProps,
  ISchoolDetailsVoteIconState
> {
  constructor(props: ISchoolDetailsVoteIconProps) {
    super(props);
    this.state = {};
  }

  private getVoteTypeIcon = (selectedVoteType: System.VoteType | undefined) => {
    return selectedVoteType === 1
      ? "icon-thumbs-up-alt"
      : "icon-thumbs-down-alt";
  };

  public render(): JSX.Element {
    const { selectedVoteType } = this.props;

    return (
      <div className={`SchoolDetailsVoteIcon`}>
        <motion.div
          key={selectedVoteType}
          transition={{ duration: 1.1 }}
          animate={{
            translateX: [-1000, 3, 0],
          }}
        >
          <motion.div
            transition={{ delay: 0.2, duration: 0.6 }}
            animate={{
              scale: [0, 1.3, 1],
              opacity: [0, 0.75, 1],
            }}
          >
            <motion.div
              transition={{ delay: 0.2, duration: 2.5, repeat: Infinity }}
              animate={{
                scale: [0.9, 1.1, 0.9, 1.1, 0.9],
                rotate: [0, 12, -15, 4, 0, 0, 0, 0],
              }}
              className="icons"
            >
              <i
                className={`vote-type-icon ${this.getVoteTypeIcon(
                  selectedVoteType
                )}`}
              ></i>
            </motion.div>
          </motion.div>
        </motion.div>
      </div>
    );
  }
}

export default SchoolDetailsVoteIcon;
