import { UISlice } from ".";

export type SetIsAcceptedCookieActionPayload = {
  isAcceptedCookie: boolean;
};

export const setIsAcceptedCookieAction = (
  payload: SetIsAcceptedCookieActionPayload
) => {
  return UISlice.actions.setIsAcceptedCookie(payload);
};

export type SetIsVerifiedByCaptchaActionPayload = {
  isVerifiedByCaptcha: boolean;
};

export const setIsVerifiedByCaptchaAction = (
  payload: SetIsVerifiedByCaptchaActionPayload
) => {
  return UISlice.actions.setIsVerifiedByCaptcha(payload);
};

/** */

export type SetSelectedVoteTypeActionPayload = {
  selectedVoteType: System.VoteType | undefined;
};

export const setSelectedVoteTypeAction = (
  payload: SetSelectedVoteTypeActionPayload
) => {
  return UISlice.actions.setSelectedVoteType(payload);
};

/** */

export type SetSelectedVoteMethodTypeActionPayload = {
  selectedVoteMethodType: System.VoteMethodType | undefined;
};

export const setSelectedVoteMethodTypeAction = (
  payload: SetSelectedVoteMethodTypeActionPayload
) => {
  return UISlice.actions.setSelectedVoteMethodType(payload);
};

/** */

export type SetSelectedVoteMethodValueActionPayload = {
  selectedVoteMethodValue: string | undefined;
};

export const setSelectedVoteMethodValueAction = (
  payload: SetSelectedVoteMethodValueActionPayload
) => {
  return UISlice.actions.setSelectedVoteMethodValue(payload);
};

/** */

export type SetProvinceTypeFilterActionPayload = {
  provinceType: System.ProvinceType | undefined;
};

export const setProvinceTypeFilterAction = (
  payload: SetProvinceTypeFilterActionPayload
) => {
  return UISlice.actions.setProvinceTypeFilter(payload);
};

/** */

export type UnsetProvinceTypeFilterActionPayload = {};

export const unsetProvinceTypeFilterAction = (
  payload: UnsetProvinceTypeFilterActionPayload
) => {
  return UISlice.actions.unsetProvinceTypeFilter(payload);
};

/** */

export type SetSchoolNameFilterActionPayload = {
  schoolName: string | undefined;
};

export const setSchoolNameFilterAction = (
  payload: SetSchoolNameFilterActionPayload
) => {
  return UISlice.actions.setSchoolNameFilter(payload);
};

/** */

export type UnsetSchoolNameFilterActionPayload = {};

export const unsetSchoolNameFilterAction = (
  payload: UnsetSchoolNameFilterActionPayload
) => {
  return UISlice.actions.unsetSchoolNameFilter(payload);
};

/** */

export type SetRangeOfSchoolsDisplayedPayload = {
  range: [number, number];
};

export const setRangeOfSchoolsDisplayedAction = (
  payload: SetRangeOfSchoolsDisplayedPayload
) => {
  return UISlice.actions.setRangeOfSchoolsDisplayed(payload);
};

/** */

export type SetScrollTopPayload = {
  scrollTop: number;
};

export const setScrollTopAction = (payload: SetScrollTopPayload) => {
  return UISlice.actions.setScrollTop(payload);
};

/** */

export type SetScrollHeightPayload = {
  scrollHeight: number;
};

export const setScrollHeightAction = (payload: SetScrollHeightPayload) => {
  return UISlice.actions.setScrollHeight(payload);
};

/** */

export type SetWindowSizePayload = {
  windowWidth: number;
  windowHeight: number;
};

export const setWindowSizeAction = (payload: SetWindowSizePayload) => {
  return UISlice.actions.setWindowSize(payload);
};

/** */

export type SetIsSchoolDetailsMinimalizeActionPayload = {
  isSchoolDetailsMinimalize: boolean;
};

export const setIsSchoolDetailsMinimalizeAction = (
  payload: SetIsSchoolDetailsMinimalizeActionPayload
) => {
  return UISlice.actions.setIsSchoolDetailsMinimalize(payload);
};

/** */

export type SetSelectedSectionTypeActionPayload = {
  selectedSectionType: App.SchoolDetailsSectionType;
};

export const setSelectedSectionTypeAction = (
  payload: SetSelectedSectionTypeActionPayload
) => {
  return UISlice.actions.setSelectedSectionType(payload);
};

/** */

export type SetLastEmailResendTimestampActionPayload = {
  lastEmailResendTimestamp: number | undefined;
};

export const setLastEmailResendTimestampAction = (
  payload: SetLastEmailResendTimestampActionPayload
) => {
  return UISlice.actions.setLastEmailResendTimestamp(payload);
};

/** */

export type SetAddSchoolFormActionPayload = {
  addSchoolForm: App.SchoolForm;
};

export const setAddSchoolFormAction = (
  payload: SetAddSchoolFormActionPayload
) => {
  return UISlice.actions.setAddSchoolForm(payload);
};

/** */

export type SetAddVoteAuthActionPayload = Partial<App.VoteAuth>;

export const setAddVoteAuthAction = (payload: SetAddVoteAuthActionPayload) => {
  return UISlice.actions.setAddVoteAuth(payload);
};

/** */

export type ResetAddSchoolFormActionPayload = {};

export const resetAddSchoolFormAction = (
  payload: ResetAddSchoolFormActionPayload
) => {
  return UISlice.actions.resetAddSchoolForm(payload);
};

/** */

export type SetAddVoteFormActionPayload = {
  addVoteForm: App.VoteForm;
};

export const setAddVoteFormAction = (payload: SetAddVoteFormActionPayload) => {
  return UISlice.actions.setAddVoteForm(payload);
};

/** */

export type ResetAddVoteFormActionPayload = {};

export const resetAddVoteFormAction = (
  payload: ResetAddVoteFormActionPayload
) => {
  return UISlice.actions.resetAddVoteForm(payload);
};

/** */

export type SetAddVoteFormEmailAddressErrorActionPayload = {
  emailAddressError: string | undefined;
};

export const setAddVoteFormEmailAddressErrorAction = (
  payload: SetAddVoteFormEmailAddressErrorActionPayload
) => {
  return UISlice.actions.setAddVoteFormEmailAddressError(payload);
};

/** */

export type SetAddVoteFormFirstNameErrorActionPayload = {
  firstNameError: string | undefined;
};

export const setAddVoteFormFirstNameErrorAction = (
  payload: SetAddVoteFormFirstNameErrorActionPayload
) => {
  return UISlice.actions.setAddVoteFormFirstNameError(payload);
};

/** */

export type SetAddVoteFormCommentErrorActionPayload = {
  commentError: string | undefined;
};

export const setAddVoteFormCommentErrorAction = (
  payload: SetAddVoteFormCommentErrorActionPayload
) => {
  return UISlice.actions.setAddVoteFormCommentError(payload);
};

/** */

export type SetAddVoteFormOtherErrorActionPayload = {
  otherError: string | undefined;
};

export const setAddVoteFormOtherErrorAction = (
  payload: SetAddVoteFormOtherErrorActionPayload
) => {
  return UISlice.actions.setAddVoteFormOtherError(payload);
};

/** */

export type SetEmailVerificationFormActionPayload = {
  emailVerificationForm: App.EmailVerificationForm;
};

export const setEmailVerificationFormAction = (
  payload: SetEmailVerificationFormActionPayload
) => {
  return UISlice.actions.setEmailVerificationForm(payload);
};

/** */

export type ResetEmailVerificationFormActionPayload = {};

export const resetEmailVerificationFormAction = (
  payload: ResetEmailVerificationFormActionPayload
) => {
  return UISlice.actions.resetEmailVerificationForm(payload);
};

/** */

export type SetEmailVerificationFormCodeErrorActionPayload = {
  codeError: string | undefined;
};

export const setEmailVerificationFormCodeErrorAction = (
  payload: SetEmailVerificationFormCodeErrorActionPayload
) => {
  return UISlice.actions.setEmailVerificationFormCodeError(payload);
};

/** */

export type SetEmailVerificationFormOtherErrorActionPayload = {
  otherError: string | undefined;
};

export const setEmailVerificationFormOtherErrorAction = (
  payload: SetEmailVerificationFormOtherErrorActionPayload
) => {
  return UISlice.actions.setEmailVerificationFormOtherError(payload);
};

/** */

export type SetIsShareWindowClosedActionPayload = {
  isClosed: boolean;
};

export const setIsShareWindowClosedAction = (
  payload: SetIsShareWindowClosedActionPayload
) => {
  return UISlice.actions.setIsShareWindowClosed(payload);
};

/** */

export type SetIsIframeWindowClosedActionPayload = {
  isClosed: boolean;
};

export const setIsIframeWindowClosedAction = (
  payload: SetIsIframeWindowClosedActionPayload
) => {
  return UISlice.actions.setIsIframeWindowClosed(payload);
};
