import * as React from "react";
import { ISchoolDetailsExpertOpinionState } from "./ISchoolDetailsExpertOpinionState";
import { ISchoolDetailsExpertOpinionProps } from "./ISchoolDetailsExpertOpinionProps";
import "./SchoolDetailsExpertOpinion.scss";
import StarGrade from "../../shared/StarGrade/StarGrade";
import Skeleton from "react-loading-skeleton";
import experts from "./experts.json";

type StaticExpert = {
  _id: string;
  avatarColor: string;
  name: string;
  title: string;
};

class SchoolDetailsExpertOpinion extends React.Component<
  ISchoolDetailsExpertOpinionProps,
  ISchoolDetailsExpertOpinionState
> {
  constructor(props: ISchoolDetailsExpertOpinionProps) {
    super(props);
    this.state = {};
  }

  public getExpert = (
    grade: Grade.Grade | undefined
  ): StaticExpert | undefined => {
    if (!grade) return undefined;
    const expert: StaticExpert | undefined = experts.find(
      (expert) => expert._id === grade.addedByExpertId
    );

    return expert;
  };

  public renderName(
    grade: Grade.Grade | undefined,
    expert: StaticExpert | undefined
  ): JSX.Element {
    return grade ? (
      <span className="name">{grade && expert && `${expert.name}`}</span>
    ) : (
      <span className="name">
        <Skeleton width={15} height={10} />
        <Skeleton width={40} height={10} />
        <Skeleton width={50} height={10} />
      </span>
    );
  }

  public render(): JSX.Element {
    const { grade, extensionText } = this.props;
    const expert = this.getExpert(grade);
    return (
      <div className={`schoolDetailsExpertOpinion`}>
        <div
          className={`expert-photo ${!grade && "pending"}`}
          style={{ backgroundColor: `${expert?.avatarColor}` }}
        >
          <i className="icon-user"></i>
        </div>
        <div className="expert-content">
          <div className="name-and-grade">
            {this.renderName(grade, expert)}
            <span className="grade-box">
              <StarGrade
                className="grade"
                gradeValue={grade ? grade.type : 0}
              />
            </span>
          </div>
          <div className="title">{expert?.title}</div>
          <div className="comment">
            {grade ? grade.opinion : <Skeleton height={30} />}
          </div>
          {grade && extensionText && (
            <div className="comment extension-text">
              Dodatkowe informacje: {extensionText}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default SchoolDetailsExpertOpinion;
