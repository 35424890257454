import { stringify } from "querystring";
import { fetchFromApi } from "../../utils/utils";

const createRedirect = async (
  requestParams: Redirect.Req.CreateRedirectParams,
  requestBody: Redirect.Req.CreateRedirectBody,
  requestQuery: Redirect.Req.CreateRedirectQuery
): Promise<Redirect.Res.CreateRedirectBody> => {
  return await fetchFromApi<
    Redirect.Req.CreateRedirectBody,
    Redirect.Res.CreateRedirectBody
  >(`/redirects`, "POST", requestBody);
};

export default {
  createRedirect,
};
