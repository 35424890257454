import * as React from "react";
import { IIconPendingState } from "./IIconPendingState";
import { IIconPendingProps } from "./IIconPendingProps";
import "./IconPending.scss";

class IconPending extends React.Component<
  IIconPendingProps,
  IIconPendingState
> {
  constructor(props: IIconPendingProps) {
    super(props);
    this.state = {};
  }

  public render(): JSX.Element {
    const { isPending, className, style } = this.props;
    return (
      <div className="IconPending">
        {isPending && (
          <i className={`icon-spin6 ${className}`} style={style}></i>
        )}
      </div>
    );
  }
}

export default IconPending;
