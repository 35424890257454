import * as React from "react";
import { ISchoolDetailsVoteCloudFormStageState } from "./ISchoolDetailsVoteCloudFormStageState";
import { ISchoolDetailsVoteCloudFormStageProps } from "./ISchoolDetailsVoteCloudFormStageProps";
import "./SchoolDetailsVoteCloudFormStage.scss";
import { Button, TextField } from "@material-ui/core";
import {
  JoiUserEmailSchema,
  JoiUserSchema,
  JoiVoteSchema,
} from "../../../models";
import Joi from "joi";
import SchoolDetailsVoteIcon from "../SchoolDetailsVoteIcon/SchoolDetailsVoteIcon";
import IconPending from "../../shared/IconPending/IconPending";

class SchoolDetailsVoteCloudFormStage extends React.Component<
  ISchoolDetailsVoteCloudFormStageProps,
  ISchoolDetailsVoteCloudFormStageState
> {
  constructor(props: ISchoolDetailsVoteCloudFormStageProps) {
    super(props);
    this.state = {
      isAutoFocused: true,
    };
  }

  componentDidMount() {
    const { props } = this;
    const {
      addVoteForm,
      defaultEmailAddress,
      defaultFirstName,
      selectedCurrentUserFacebookAccount,
      selectedVoteMethodType,
      onUpdateVoteForm,
    } = props;

    if (selectedVoteMethodType === "facebook") {
      onUpdateVoteForm &&
        onUpdateVoteForm({
          addVoteForm: {
            ...addVoteForm,
            emailAddress: selectedCurrentUserFacebookAccount?.email,
            firstName: selectedCurrentUserFacebookAccount?.first_name,
          },
        });
    } else if (selectedVoteMethodType === "email") {
      onUpdateVoteForm &&
        onUpdateVoteForm({
          addVoteForm: {
            ...addVoteForm,
            emailAddress: defaultEmailAddress,
            firstName: defaultFirstName,
          },
        });
    } else {
      onUpdateVoteForm &&
        onUpdateVoteForm({
          addVoteForm: {
            ...addVoteForm,
            emailAddress: undefined,
            firstName: undefined,
          },
        });
    }
  }

  componentDidUpdate(
    oldProps: ISchoolDetailsVoteCloudFormStageProps,
    oldState: ISchoolDetailsVoteCloudFormStageState
  ) {
    const { props } = this;
    const {
      addVoteForm,
      defaultEmailAddress,
      defaultFirstName,

      onUpdateVoteForm,
    } = props;

    if (
      oldProps.selectedCurrentUserFacebookAccount !==
      props.selectedCurrentUserFacebookAccount
    ) {
      onUpdateVoteForm &&
        onUpdateVoteForm({
          addVoteForm: {
            ...addVoteForm,
            firstName: props.selectedCurrentUserFacebookAccount?.first_name,
            emailAddress: props.selectedCurrentUserFacebookAccount?.email,
          },
        });
    }
    if (oldProps.defaultEmailAddress !== props.defaultEmailAddress) {
      onUpdateVoteForm &&
        onUpdateVoteForm({
          addVoteForm: {
            ...addVoteForm,
            emailAddress: defaultEmailAddress,
          },
        });
    }

    if (oldProps.defaultFirstName !== props.defaultFirstName) {
      onUpdateVoteForm &&
        onUpdateVoteForm({
          addVoteForm: {
            ...addVoteForm,
            firstName: defaultFirstName,
          },
        });
    }
  }

  componentWillUnmount() {
    const { onFormReset } = this.props;
    onFormReset && onFormReset();
  }

  public handleEmailInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { addVoteForm, onUpdateVoteForm } = this.props;
    const emailAddressValue: string = event.target.value;
    const emailAddress: string | undefined = emailAddressValue.length
      ? emailAddressValue
      : undefined;
    onUpdateVoteForm &&
      onUpdateVoteForm({
        addVoteForm: {
          ...addVoteForm,
          emailAddress,
        },
      });
  };

  public handleFirstNameInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { addVoteForm, onUpdateVoteForm } = this.props;
    const firstNameValue: string = event.target.value;
    const firstName: string | undefined = firstNameValue.length
      ? firstNameValue
      : undefined;
    onUpdateVoteForm &&
      onUpdateVoteForm({
        addVoteForm: {
          ...addVoteForm,
          firstName,
        },
      });
  };

  public handleCommentChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { addVoteForm, onUpdateVoteForm } = this.props;
    const commentValue: string = event.target.value;
    const comment: string | undefined = commentValue.length
      ? commentValue
      : undefined;

    onUpdateVoteForm &&
      onUpdateVoteForm({
        addVoteForm: {
          ...addVoteForm,
          comment,
        },
      });
  };

  public handleEmailInputBlur = (
    event: React.FocusEvent<HTMLInputElement>
  ): void => {
    const { addVoteForm, onUpdateVoteForm } = this.props;
    const { emailAddress } = addVoteForm;
    const validation: Joi.ValidationResult = this.validateEmailAddress(
      emailAddress
    );

    this.setState({
      isAutoFocused: false,
    });

    onUpdateVoteForm &&
      onUpdateVoteForm({
        addVoteForm: {
          ...addVoteForm,
          emailAddressError: validation.error?.details[0].message,
        },
      });
  };

  public handleFirstNameInputBlur = (
    event: React.FocusEvent<HTMLInputElement>
  ): void => {
    const { addVoteForm, onUpdateVoteForm } = this.props;
    const { firstName } = addVoteForm;
    const validation: Joi.ValidationResult = this.validateFirstName(firstName);

    onUpdateVoteForm &&
      onUpdateVoteForm({
        addVoteForm: {
          ...addVoteForm,
          firstNameError: validation.error?.details[0].message,
        },
      });
  };

  public handleCommentBlur = (
    event: React.FocusEvent<HTMLInputElement>
  ): void => {
    const { addVoteForm, onUpdateVoteForm } = this.props;
    const { comment } = addVoteForm;
    const validation: Joi.ValidationResult = this.validateComment(comment);

    onUpdateVoteForm &&
      onUpdateVoteForm({
        addVoteForm: {
          ...addVoteForm,
          commentError: validation.error?.details[0].message,
        },
      });
  };

  public validateEmailAddress = (
    emailAddress: string | undefined
  ): Joi.ValidationResult => {
    return JoiUserEmailSchema.address.validate(emailAddress);
  };

  public validateFirstName = (
    firstName: string | undefined
  ): Joi.ValidationResult => {
    return JoiUserSchema.firstName.validate(firstName);
  };

  public validateComment = (
    comment: string | undefined
  ): Joi.ValidationResult => {
    return JoiVoteSchema.comment.validate(comment);
  };

  public isValidForm = (
    emailAddress: string | undefined,
    firstName: string | undefined,
    comment: string | undefined
  ): boolean => {
    const { validateEmailAddress, validateFirstName, validateComment } = this;

    if (validateEmailAddress(emailAddress).error) return false;
    if (validateFirstName(firstName).error) return false;
    if (validateComment(comment).error) return false;
    return true;
  };

  public isPending = (): boolean => {
    const {
      patchCurrentUserBody,
      createCurrentVoteBody,
      createCurrentUserEmailBody,
    } = this.props;
    return (
      createCurrentVoteBody.status === "pending" ||
      createCurrentUserEmailBody.status === "pending" ||
      patchCurrentUserBody.status === "pending"
    );
  };

  public isVoteButtonDisabled = (payload: {
    emailAddress: string | undefined;
    emailAddressError: string | undefined;
    firstName: string | undefined;
    firstNameError: string | undefined;
    comment: string | undefined;
    commentError: string | undefined;
    createCurrentVoteBody: Vote.Res.CreateCurrentVoteBody;
    createCurrentUserEmailBody: User.Res.CreateCurrentUserEmailBody;
  }): boolean => {
    const { emailAddress, firstName, comment } = payload;
    if (!this.isValidForm(emailAddress, firstName, comment)) return true;
    if (this.isPending()) return true;
    return false;
  };

  public handleVoteButtonClick = (): void => {
    const { onVote } = this.props;
    const {
      selectedVoteMethodType,
      selectedVoteMethodValue,
      addVoteForm,
    } = this.props;
    const { emailAddress, firstName, comment } = addVoteForm;
    if (!selectedVoteMethodType) return;
    if (selectedVoteMethodType === "email") {
      if (!emailAddress) return;
      onVote &&
        onVote({
          method: { type: "email", value: emailAddress },
          firstName,
          comment,
        });
    } else if (selectedVoteMethodType === "facebook") {
      if (!selectedVoteMethodValue) return;
      onVote &&
        onVote({
          method: { type: "facebook", value: selectedVoteMethodValue },
          firstName,
          comment,
        });
    }
  };

  public handleUndoButtonClick = (): void => {
    const { onFormUndo } = this.props;
    onFormUndo && onFormUndo();
  };

  public getEmailTextFieldDefaultValue = (payload: {
    defaultEmailAddress: string | undefined;
    selectedVoteMethodType: Vote.VoteMethodType | undefined;
    selectedCurrentUserFacebookAccount: User.FacebookAccount | undefined;
  }) => {
    const {
      defaultEmailAddress,
      selectedVoteMethodType,
      selectedCurrentUserFacebookAccount,
    } = payload;
    if (selectedVoteMethodType === "facebook") {
      return selectedCurrentUserFacebookAccount?.email;
    }

    if (selectedVoteMethodType === "email") return defaultEmailAddress;
  };

  public getFirstNameTextFieldDefaultValue = (payload: {
    defaultFirstName: string | undefined;
    selectedVoteMethodType: Vote.VoteMethodType | undefined;
    selectedCurrentUserFacebookAccount: User.FacebookAccount | undefined;
  }) => {
    const {
      defaultFirstName,
      selectedVoteMethodType,
      selectedCurrentUserFacebookAccount,
    } = payload;
    if (selectedVoteMethodType === "facebook")
      return selectedCurrentUserFacebookAccount?.first_name;
    if (selectedVoteMethodType === "email") return defaultFirstName;
  };

  public handleTextFieldBlur = () => {
    this.setState({
      isAutoFocused: false,
    });
  };

  public render(): JSX.Element {
    const {
      createCurrentVoteBody,
      createCurrentUserEmailBody,
      selectedVoteType,
      selectedCurrentUserFacebookAccount,
      selectedVoteMethodType,
      defaultEmailAddress,
      defaultFirstName,
      addVoteForm,
    } = this.props;

    const { isAutoFocused } = this.state;

    const {
      emailAddress,
      emailAddressError,
      firstName,
      firstNameError,
      comment,
      commentError,
      otherError,
    } = addVoteForm;

    const isPending: boolean = this.isPending();
    return (
      <div className="SchoolDetailsVoteCloudFormStage">
        <div className="body">
          <div className="vote-icon">
            <SchoolDetailsVoteIcon selectedVoteType={selectedVoteType} />
          </div>
          <div className="form">
            <div className="row">
              {selectedVoteMethodType === "facebook" && (
                <div className="icon-wrapper">
                  <i className="icon-facebook"></i>
                  <i className="icon-ok"></i>
                </div>
              )}
              <TextField
                key={this.getEmailTextFieldDefaultValue({
                  selectedCurrentUserFacebookAccount,
                  selectedVoteMethodType,
                  defaultEmailAddress,
                })}
                tabIndex={1}
                className="cloud-input email-input"
                required={true}
                defaultValue={this.getEmailTextFieldDefaultValue({
                  selectedCurrentUserFacebookAccount,
                  selectedVoteMethodType,
                  defaultEmailAddress,
                })}
                value={emailAddress}
                type="email"
                variant="outlined"
                label="E-mail"
                disabled={isPending || selectedVoteMethodType !== "email"}
                onChange={this.handleEmailInputChange}
                onBlur={this.handleEmailInputBlur}
                inputProps={{ maxLength: 30 }}
                autoFocus={
                  isAutoFocused &&
                  selectedVoteMethodType &&
                  selectedVoteMethodType !== "facebook"
                }
                helperText={emailAddressError}
                error={Boolean(emailAddressError)}
              />
            </div>

            <div className="row">
              {selectedVoteMethodType === "facebook" && (
                <div className="icon-wrapper">
                  <i className="icon-facebook"></i>
                  <i className="icon-ok"></i>
                </div>
              )}
              <TextField
                key={this.getFirstNameTextFieldDefaultValue({
                  selectedCurrentUserFacebookAccount,
                  selectedVoteMethodType,
                  defaultFirstName,
                })}
                tabIndex={2}
                className="cloud-input"
                defaultValue={this.getFirstNameTextFieldDefaultValue({
                  selectedCurrentUserFacebookAccount,
                  selectedVoteMethodType,
                  defaultFirstName,
                })}
                value={firstName}
                type="value"
                variant="outlined"
                label="Twoje imię"
                disabled={isPending || selectedVoteMethodType !== "email"}
                onChange={this.handleFirstNameInputChange}
                onBlur={this.handleFirstNameInputBlur}
                inputProps={{ maxLength: 20 }}
                helperText={firstNameError || `"Imię" jest opcjonalnym polem.`}
                error={Boolean(firstNameError)}
              />
            </div>
            <div className="row">
              <TextField
                tabIndex={3}
                className="cloud-input"
                type="value"
                variant="outlined"
                label="Twój komentarz"
                value={comment}
                disabled={isPending}
                onChange={this.handleCommentChange}
                onBlur={this.handleCommentBlur}
                inputProps={{ maxLength: 50 }}
                helperText={
                  commentError || `"Komentarz" jest opcjonalnym polem.`
                }
                autoFocus={
                  isAutoFocused &&
                  selectedVoteMethodType &&
                  selectedVoteMethodType === "facebook"
                }
                error={Boolean(commentError)}
              />
            </div>
            <div className="error">{otherError}</div>
          </div>
        </div>
        <div className="footer">
          <Button
            className="cancel"
            disabled={isPending}
            onClick={this.handleUndoButtonClick}
            tabIndex={5}
          >
            Cofnij
          </Button>
          <Button
            tabIndex={4}
            className="app-button vote"
            disabled={this.isVoteButtonDisabled({
              emailAddress,
              emailAddressError,
              firstName,
              firstNameError,
              comment,
              commentError,
              createCurrentVoteBody,
              createCurrentUserEmailBody,
            })}
            onClick={this.handleVoteButtonClick}
          >
            <IconPending isPending={this.isPending()} />
            Zagłosuj
          </Button>
        </div>
      </div>
    );
  }
}

export default SchoolDetailsVoteCloudFormStage;
