import * as React from "react";
import { ISchoolDetailsTopState } from "./ISchoolDetailsTopState";
import { ISchoolDetailsTopProps } from "./ISchoolDetailsTopProps";
import "./SchoolDetailsTop.scss";
import SchoolBackground from "../../shared/SchoolBackground/SchoolBackground";
import SchoolAvatar from "../../shared/SchoolAvatar/SchoolAvatar";
import StarGrade from "../../shared/StarGrade/StarGrade";
import Skeleton from "react-loading-skeleton";

class SchoolDetailsTop extends React.Component<
  ISchoolDetailsTopProps,
  ISchoolDetailsTopState
> {
  constructor(props: ISchoolDetailsTopProps) {
    super(props);
    this.state = {};
  }

  public handleShare = () => {
    const { onShare } = this.props;
    onShare && onShare();
  };

  public renderTitle(): JSX.Element {
    const { name, city, getSchoolDetailsBody } = this.props;
    return getSchoolDetailsBody.status === "pending" ? (
      <Skeleton />
    ) : (
      <h3 className="title">
        {name} w: {city}
      </h3>
    );
  }

  public render(): JSX.Element {
    const {
      place,
      name,
      city,
      gradesAvg,
      backgroundPhotoUrl,
      backgroundColorTheme,
      logoPhotoUrl,
      minimalize,
      initials,
      getSchoolDetailsBody,
    } = this.props;
    const { innerWidth: width, innerHeight: height } = window;
    const forceMinimalize: boolean = width <= 650 || height < 430;

    return (
      <div className={`SchoolDetailsTop`}>
        <SchoolBackground
          isPending={getSchoolDetailsBody.status === "pending"}
          backgroundPhotoUrl={backgroundPhotoUrl}
          minimalize={minimalize || forceMinimalize}
          onShare={this.handleShare}
        >
          <SchoolAvatar
            isPending={getSchoolDetailsBody.status === "pending"}
            initials={initials}
            backgroundColorTheme={backgroundColorTheme}
            logoPhotoUrl={logoPhotoUrl}
            place={place}
            minimalize={minimalize || forceMinimalize}
          />
        </SchoolBackground>

        {this.renderTitle()}
        <div className="grade">
          <StarGrade
            gradeValue={
              !gradesAvg || getSchoolDetailsBody.status === "pending"
                ? 0
                : gradesAvg
            }
          />
        </div>
      </div>
    );
  }
}

export default SchoolDetailsTop;
