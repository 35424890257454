import * as React from "react";
import { ISchoolDetailsContentState } from "./ISchoolDetailsContentState";
import { ISchoolDetailsContentProps } from "./ISchoolDetailsContentProps";
import "./SchoolDetailsContent.scss";
import Button from "../../shared/Button/Button";
import SchoolDetailsBasicInformations from "../SchoolDetailsBasicInformations/SchoolDetailsBasicInformations";
import Frame from "../../shared/Frame/Frame";
import SchoolDetailsExpertsOpinions from "../SchoolDetailsExpertsOpinions/SchoolDetailsExpertsOpinions";
import schoolOptionsData from "./sections-options.data.json";
import SchoolDetailsComments from "../SchoolDetailsComments/SchoolDetailsComments";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import queryString from "query-string";
import {
  getGoogleReviewsScore,
  getSelectedSectionType,
  getUserVotesScore,
} from "../../../utils/utils";

class SchoolDetailsContent extends React.Component<
  ISchoolDetailsContentProps,
  ISchoolDetailsContentState
> {
  constructor(props: ISchoolDetailsContentProps) {
    super(props);
    this.state = {
      currentRowIndex: 0,
    };
  }
  /*
  componentDidUpdate(
    oldProps: ISchoolDetailsContentProps,
    oldState: ISchoolDetailsContentState
  ) {
    const { props, state } = this;

    
    if (props.selectedSectionType !== oldProps.selectedSectionType) {
      this.handleNextSelectedSectionType();
    }
    if (props.selectedSchoolDetails.grades !== oldProps.expertsOpinions) {
      this.handleNextExpertsOpinions();
    }
    if (props.usersComments !== oldProps.usersComments) {
      this.handleNextUsersComments();
    }}
    */

  private handleNextSelectedSectionType() {
    this.setState({
      currentRowIndex: 0,
    });
  }

  private handleNextExpertsOpinions() {
    this.setState({
      currentRowIndex: 0,
    });
  }

  private handleNextUsersComments() {
    this.setState({
      currentRowIndex: 0,
    });
  }

  private handleScrollDownClick = (nextRowIndex: number) => {
    this.setState({
      currentRowIndex: nextRowIndex,
    });
  };

  public render(): JSX.Element {
    const {
      selectedSchoolDetails,
      getSchoolDetailsBody,
      backgroundColorTheme,
      location,
      currentUserVoteInSelectedSchool,
    } = this.props;
    const { currentRowIndex } = this.state;

    const searchObject = queryString.parse(location.search);
    const selectedSectionType = getSelectedSectionType(searchObject);

    return (
      <div className="SchoolDetailsContent">
        <div className="section-options">
          {(schoolOptionsData as App.SchoolDetailsSectionOption[]).map(
            (option, i: number) => (
              <Link
                key={i}
                to={`?${queryString.stringify({
                  ...searchObject,
                  section: option.sectionType,
                })}`}
              >
                <Button
                  key={i}
                  className="button"
                  border="bottom"
                  hoverColorTheme={
                    backgroundColorTheme ? backgroundColorTheme : "#7a9ef6"
                  }
                  isSelected={selectedSectionType === option.sectionType}
                >
                  <i className={option.iconName}></i>
                  <span>{option.title}</span>
                </Button>
              </Link>
            )
          )}
        </div>
        <div className="content">
          {
            <Frame
              initial={{
                height: "100%",
              }}
              animate={{
                height: "100%",
              }}
              transition={{ duration: 0.8 }}
              currentRowIndex={currentRowIndex}
              onScrollDownClick={this.handleScrollDownClick}
            >
              {selectedSectionType === "basic-information" && (
                <SchoolDetailsBasicInformations
                  basicScore={0}
                  schoolType={selectedSchoolDetails?.schoolType}
                  expertsScore={
                    selectedSchoolDetails
                      ? selectedSchoolDetails.gradesAvg * 10
                      : 0
                  }
                  userVotesScore={getUserVotesScore(selectedSchoolDetails)}
                  googleReviewsScore={getGoogleReviewsScore(
                    selectedSchoolDetails
                  )}
                  distinguishingFeature={
                    selectedSchoolDetails?.distinguishingFeature
                  }
                  colorTheme={selectedSchoolDetails?.backgroundColorTheme}
                  schoolId={selectedSchoolDetails?._id}
                  city={selectedSchoolDetails?.city}
                  provinceType={selectedSchoolDetails?.provinceType}
                  additionalLocations={
                    selectedSchoolDetails?.additionalLocations
                  }
                  getSchoolDetailsBody={getSchoolDetailsBody}
                  basicInformations={{
                    schoolPageUrl: selectedSchoolDetails?.schoolPageUrl,
                    mail: selectedSchoolDetails?.schoolEmail,
                    phone: selectedSchoolDetails?.schoolPhone,
                  }}
                />
              )}
              {selectedSectionType === "experts-opinions" && (
                <SchoolDetailsExpertsOpinions
                  grades={selectedSchoolDetails?.grades}
                  generalEducationInformation={
                    selectedSchoolDetails?.generalEducationInformation
                  }
                  vocationalEducationInformation={
                    selectedSchoolDetails?.vocationalEducationInformation
                  }
                  intershipInformation={
                    selectedSchoolDetails?.intershipInformation
                  }
                  generalEducationInformationGrade={
                    selectedSchoolDetails?.generalEducationInformationGrade
                  }
                  vocationalEducationInformationGrade={
                    selectedSchoolDetails?.vocationalEducationInformationGrade
                  }
                  intershipInformationGrade={
                    selectedSchoolDetails?.intershipInformationGrade
                  }
                  getSchoolDetailsByFullNameBody={getSchoolDetailsBody}
                />
              )}
              {selectedSectionType === "users-comments" && (
                <SchoolDetailsComments
                  getSchoolDetailsByFullNameBody={getSchoolDetailsBody}
                  currentUserVoteInSelectedSchool={
                    currentUserVoteInSelectedSchool
                  }
                  voteBriefs={selectedSchoolDetails?.voteBriefs}
                  googleReviews={selectedSchoolDetails?.googleReviews}
                />
              )}
            </Frame>
          }
        </div>
      </div>
    );
  }
}

export default withRouter(SchoolDetailsContent);
