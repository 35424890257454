import { PayloadAction } from "@reduxjs/toolkit";
import { SchoolState } from ".";
import {
  AddSchoolPreviewActionPayload,
  AddSelectedSchoolDetailsVoteBriefActionPayload,
  AppendFilteredSchoolPreviewsActionPayload,
  AppendSchoolPreviewsActionPayload,
  DecrementThumbsDownActionPayload,
  DecrementThumbsUpActionPayload,
  IncrementThumbsDownActionPayload,
  IncrementThumbsUpActionPayload,
  RemoveSelectedSchoolDetailsVoteBriefActionPayload,
  SetFilteredSchoolPreviewsActionPayload,
  SetSchoolPreviewsActionPayload,
  SetSelectedSchoolDetailsActionPayload,
  UnsetSchoolPreviewsActionPayload,
  UpdateSchoolPreviewActionPayload,
} from "./schools.actions";

const setSchoolPreviews = (
  state: SchoolState,
  { payload }: PayloadAction<SetSchoolPreviewsActionPayload>
) => {
  state.schoolPreviews = payload.schoolPreviews;
};

const setFilteredSchoolPreviews = (
  state: SchoolState,
  { payload }: PayloadAction<SetFilteredSchoolPreviewsActionPayload>
) => {
  state.filteredSchoolPreviews = payload.schoolPreviews;
};

const setSelectedSchoolDetails = (
  state: SchoolState,
  { payload }: PayloadAction<SetSelectedSchoolDetailsActionPayload>
) => {
  state.selectedSchoolDetails = payload.schoolDetails;
};

const appendSchoolPreviews = (
  state: SchoolState,
  { payload }: PayloadAction<AppendSchoolPreviewsActionPayload>
) => {
  state.schoolPreviews = state.schoolPreviews
    ? [...state.schoolPreviews, ...payload.schoolPreviews].filter(
        (school, index, schools) =>
          schools.findIndex(
            (s) =>
              s.name === school.name &&
              s.city === school.city &&
              s.provinceType === school.provinceType
          ) === index
      )
    : payload.schoolPreviews;
};

const appendFilteredSchoolPreviews = (
  state: SchoolState,
  { payload }: PayloadAction<AppendFilteredSchoolPreviewsActionPayload>
) => {
  state.filteredSchoolPreviews = state.filteredSchoolPreviews
    ? [...state.filteredSchoolPreviews, ...payload.schoolPreviews].filter(
        (school, index, schools) =>
          schools.findIndex(
            (s) =>
              s.name === school.name &&
              s.city === school.city &&
              s.provinceType === school.provinceType
          ) === index
      )
    : payload.schoolPreviews;
};

const addSelectedSchoolDetailsVoteBrief = (
  state: SchoolState,
  { payload }: PayloadAction<AddSelectedSchoolDetailsVoteBriefActionPayload>
) => {
  if (!state.selectedSchoolDetails) return;
  if (state.selectedSchoolDetails._id !== payload.addedToSchoolId) return;
  state.selectedSchoolDetails.voteBriefs.push(payload.voteBrief);
};

const removeSelectedSchoolDetailsVoteBrief = (
  state: SchoolState,
  { payload }: PayloadAction<RemoveSelectedSchoolDetailsVoteBriefActionPayload>
) => {
  if (!state.selectedSchoolDetails) return;
  if (state.selectedSchoolDetails._id !== payload.addedToSchoolId) return;
  state.selectedSchoolDetails.voteBriefs = state.selectedSchoolDetails?.voteBriefs.filter(
    (vote) => vote._id !== payload.voteBriefId
  );
};

const incrementThumbsUp = (
  state: SchoolState,
  { payload }: PayloadAction<IncrementThumbsUpActionPayload>
) => {
  const schoolPreviewsIdx: number =
    state.schoolPreviews?.findIndex(
      (schoolPreview) => schoolPreview._id === payload.schoolPreviewId
    ) || -1;
  const filteredSchoolPreviewIdx: number =
    state.filteredSchoolPreviews?.findIndex(
      (schoolPreview) => schoolPreview._id === payload.schoolPreviewId
    ) || -1;

  if (state.schoolPreviews && schoolPreviewsIdx !== -1) {
    state.schoolPreviews[schoolPreviewsIdx].thumbsUp += 1;
    state.schoolPreviews[schoolPreviewsIdx].score += 1;
  }
  if (state.filteredSchoolPreviews && filteredSchoolPreviewIdx !== -1) {
    state.filteredSchoolPreviews[filteredSchoolPreviewIdx].thumbsUp += 1;
    state.filteredSchoolPreviews[filteredSchoolPreviewIdx].score += 1;
  }
};

const decrementThumbsUp = (
  state: SchoolState,
  { payload }: PayloadAction<DecrementThumbsUpActionPayload>
) => {
  const schoolPreviewsIdx: number =
    state.schoolPreviews?.findIndex(
      (schoolPreview) => schoolPreview._id === payload.schoolPreviewId
    ) || -1;
  const filteredSchoolPreviewIdx: number =
    state.filteredSchoolPreviews?.findIndex(
      (schoolPreview) => schoolPreview._id === payload.schoolPreviewId
    ) || -1;

  if (state.schoolPreviews && schoolPreviewsIdx !== -1) {
    state.schoolPreviews[schoolPreviewsIdx].thumbsUp -= 1;
    state.schoolPreviews[schoolPreviewsIdx].score -= 1;
  }
  if (state.filteredSchoolPreviews && filteredSchoolPreviewIdx !== -1) {
    state.filteredSchoolPreviews[filteredSchoolPreviewIdx].thumbsUp -= 1;
    state.filteredSchoolPreviews[filteredSchoolPreviewIdx].score -= 1;
  }
};

const incrementThumbsDown = (
  state: SchoolState,
  { payload }: PayloadAction<IncrementThumbsDownActionPayload>
) => {
  const schoolPreviewsIdx: number =
    state.schoolPreviews?.findIndex(
      (schoolPreview) => schoolPreview._id === payload.schoolPreviewId
    ) || -1;
  const filteredSchoolPreviewIdx: number =
    state.filteredSchoolPreviews?.findIndex(
      (schoolPreview) => schoolPreview._id === payload.schoolPreviewId
    ) || -1;

  if (state.schoolPreviews && schoolPreviewsIdx !== -1) {
    state.schoolPreviews[schoolPreviewsIdx].thumbsDown += 1;
    state.schoolPreviews[schoolPreviewsIdx].score -= 1;
  }
  if (state.filteredSchoolPreviews && filteredSchoolPreviewIdx !== -1) {
    state.filteredSchoolPreviews[filteredSchoolPreviewIdx].thumbsDown += 1;
    state.filteredSchoolPreviews[filteredSchoolPreviewIdx].score -= 1;
  }
};

const decrementThumbsDown = (
  state: SchoolState,
  { payload }: PayloadAction<DecrementThumbsDownActionPayload>
) => {
  const schoolPreviewsIdx: number =
    state.schoolPreviews?.findIndex(
      (schoolPreview) => schoolPreview._id === payload.schoolPreviewId
    ) || -1;
  const filteredSchoolPreviewIdx: number =
    state.filteredSchoolPreviews?.findIndex(
      (schoolPreview) => schoolPreview._id === payload.schoolPreviewId
    ) || -1;

  if (state.schoolPreviews && schoolPreviewsIdx !== -1) {
    state.schoolPreviews[schoolPreviewsIdx].thumbsDown -= 1;
    state.schoolPreviews[schoolPreviewsIdx].score += 1;
  }
  if (state.filteredSchoolPreviews && filteredSchoolPreviewIdx !== -1) {
    state.filteredSchoolPreviews[filteredSchoolPreviewIdx].thumbsDown -= 1;
    state.filteredSchoolPreviews[filteredSchoolPreviewIdx].score += 1;
  }
};

const unsetSchoolPreviews = (
  state: SchoolState,
  { payload }: PayloadAction<UnsetSchoolPreviewsActionPayload>
) => {
  state.schoolPreviews = undefined;
};

const addSchoolPreview = (
  state: SchoolState,
  { payload }: PayloadAction<AddSchoolPreviewActionPayload>
) => {
  if (!state.schoolPreviews) state.schoolPreviews = [];
  state.schoolPreviews.push(payload.newSchoolPreview);
};

const updateSchoolPreview = (
  state: SchoolState,
  { payload }: PayloadAction<UpdateSchoolPreviewActionPayload>
) => {
  if (!state.schoolPreviews) return;
  const schoolIndex: number = state.schoolPreviews.findIndex(
    (school) => school._id === payload.schoolPreviewId
  );
  if (schoolIndex === -1) return;

  state.schoolPreviews[schoolIndex] = {
    ...state.schoolPreviews[schoolIndex],
    ...payload.newSchoolPreview,
  };
};

const setGetSchoolDetailsBody = (
  state: SchoolState,
  {
    payload,
  }: PayloadAction<{ body: School.Res.GetSchoolDetailsByFullNameBody }>
) => {
  state.getSchoolDetailsBody = payload.body;
};

const setCreateMySchoolBody = (
  state: SchoolState,
  { payload }: PayloadAction<{ body: School.Res.CreateMySchoolBody }>
) => {
  state.createMySchoolBody = payload.body;
};

const setGetSchoolPreviewsBody = (
  state: SchoolState,
  { payload }: PayloadAction<{ body: School.Res.GetSchoolPreviewsBody }>
) => {
  state.getSchoolPreviewsBody = payload.body;
};

export default {
  setSchoolPreviews,
  setFilteredSchoolPreviews,
  setSelectedSchoolDetails,
  addSelectedSchoolDetailsVoteBrief,
  removeSelectedSchoolDetailsVoteBrief,
  appendSchoolPreviews,
  appendFilteredSchoolPreviews,
  incrementThumbsUp,
  decrementThumbsUp,
  incrementThumbsDown,
  decrementThumbsDown,
  addSchoolPreview,
  updateSchoolPreview,
  unsetSchoolPreviews,
  setGetSchoolDetailsBody,
  setCreateMySchoolBody,
  setGetSchoolPreviewsBody,
};
