import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";

export type SchoolState = {
  apiExperts: Array<Api.Expert> | undefined;
  selectedExpertId: string | undefined;
};

const initialState: SchoolState = {
  apiExperts: undefined,
  selectedExpertId: undefined,
};

const expertSlice: Slice<SchoolState> = createSlice({
  name: "expert",
  initialState,
  reducers: {
    setExperts(state, { payload }: PayloadAction<{ experts: Api.Expert[] }>) {
      state.apiExperts = payload.experts;
    },
    unsetExperts(state, { payload }: PayloadAction<{}>) {
      state.apiExperts = undefined;
    },
    addExpert(state, { payload }: PayloadAction<{ newExpert: Api.Expert }>) {
      if (!state.apiExperts) state.apiExperts = [];
      state.apiExperts.push(payload.newExpert);
    },
    updateExpert(
      state,
      { payload }: PayloadAction<{ expertId: string; newExpert: Api.Expert }>
    ) {
      if (!state.apiExperts) return;
      const expertIndex: number = state.apiExperts.findIndex(
        (apiExpert) => apiExpert._id === payload.expertId
      );
      if (expertIndex === -1) return;
      state.apiExperts[expertIndex] = payload.newExpert;
    },
    setSelectedExpertId(
      state,
      { payload }: PayloadAction<{ selectedExpertId: string }>
    ) {
      state.selectedExpertId = payload.selectedExpertId;
    },
    unsetSelectedSchoolId(state, action: PayloadAction<{}>) {
      state.selectedExpertId = undefined;
    },
  },
});

export const {
  setExperts,
  unsetExperts,
  addExpert,
  updateExpert,
  setSelectedExpertId,
  unsetSelectedSchoolId,
} = expertSlice.actions;

export default expertSlice.reducer;
