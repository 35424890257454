import * as React from "react";
import { IRankBoxState } from "./IRankBoxState";
import {
  IRankBoxProps,
  RankBoxDispatchProps,
  RankBoxStateProps,
} from "./IRankBoxProps";
import "./RankBox.scss";
import Bubble from "../../shared/Bubble/Bubble";

import SchoolPreview from "../SchoolPreview/SchoolPreview";
import { connect } from "react-redux";
import { AppDispatch, AppState } from "../../../store/store";
import {
  getBestSchool,
  getFilteredSchoolPreviews,
  getGetSchoolDetailsByFullNameBody,
  getGetSchoolPreviewsBody,
  getSchoolPreviews,
  getWorstSchool,
} from "../../../store/school/school.selectors";
import { withRouter } from "react-router";
import {
  setIsSchoolDetailsMinimalizeAction,
  setProvinceTypeFilterAction,
  setSchoolNameFilterAction,
} from "../../../store/@ui/ui.actions";
import LazyFetchSchools from "../LazyFetchSchools/LazyFetchSchools";
import {
  getIsFilteredSchools,
  getProvinceTypeFilter,
  getSchoolNameFilter,
} from "../../../store/@ui/ui.selectors";
import Oops from "../../shared/Oops/Oops";
import { Button } from "@material-ui/core";
import { encodeSchoolUrl } from "../../../utils/utils";

const mapStateToProps = (appState: AppState): RankBoxStateProps => ({
  schoolPreviews: getSchoolPreviews(appState),
  filteredSchoolPreviews: getFilteredSchoolPreviews(appState),
  schoolNameFilter: getSchoolNameFilter(appState),
  isFilteredSchools: getIsFilteredSchools(appState),
  provinceTypeFilter: getProvinceTypeFilter(appState),
  getSchoolDetailsBody: getGetSchoolDetailsByFullNameBody(appState),
  getSchoolPreviewsBody: getGetSchoolPreviewsBody(appState),
  bestSchool: getBestSchool(appState),
  worstSchool: getWorstSchool(appState),
});

const mapDispatchToProps = (dispatch: AppDispatch): RankBoxDispatchProps => ({
  onMaximalize: () => {
    dispatch(
      setIsSchoolDetailsMinimalizeAction({ isSchoolDetailsMinimalize: false })
    );
  },
  onResetFilters: () => {
    dispatch(setSchoolNameFilterAction({ schoolName: undefined }));
    dispatch(setProvinceTypeFilterAction({ provinceType: undefined }));
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);

class RankBox extends React.Component<IRankBoxProps, IRankBoxState> {
  constructor(props: IRankBoxProps) {
    super(props);
    this.state = {};
  }

  public componentDidMount() {}

  public componentDidUpdate(prevProps: IRankBoxProps) {}

  private handleSelectSchool = (
    selectedSchoolPreview: School.SchoolPreview,
    index: number
  ): void => {
    const { onMaximalize } = this.props;
    onMaximalize && onMaximalize();
    this.props.history.push(
      `/ranking/${encodeSchoolUrl(selectedSchoolPreview)}?index=${index}`
    );
  };

  public handleDisableFilterButtonClick = () => {
    const { onResetFilters } = this.props;
    onResetFilters();
  };

  public render(): JSX.Element {
    const {
      getSchoolPreviewsBody,
      getSchoolDetailsBody,
      schoolPreviews,
      filteredSchoolPreviews,
      schoolNameFilter,
      isFilteredSchools,
      provinceTypeFilter,
      rankingRef,
      match,
    } = this.props;

    return (
      <LazyFetchSchools>
        <Bubble className="RankBox" ready={false}>
          <div className={`rankBox`}>
            <div className="ranking">
              <div className="ranking-header">
                <h2>
                  <i className="app-header-icon icon-chart-line"></i>Ranking
                </h2>
                {isFilteredSchools && (
                  <Button
                    disabled={getSchoolPreviewsBody.status === "pending"}
                    className="app-button disable-filter-button"
                    onClick={this.handleDisableFilterButtonClick}
                  >
                    Resetuj filtr
                  </Button>
                )}
              </div>

              <div className="school-previews-header">
                <div className="place">
                  <span>Poz.</span>
                </div>
                <div className="score">
                  <span>Punkty</span>
                </div>
                <div className="name">
                  <span>Nazwa</span>
                </div>
                <div className="city">
                  <span>Miejscowość</span>
                </div>
                <div className="stars">
                  <span>Ocena</span>
                </div>
                <div className="votes-up">
                  <i className="icon-thumbs-up-alt"></i>
                </div>
                <div className="votes-down">
                  <i className="icon-thumbs-down-alt"></i>
                </div>
              </div>
              <div className="schools-previews">
                {!isFilteredSchools &&
                  schoolPreviews &&
                  schoolPreviews.map(
                    (schoolPreview: School.SchoolPreview, i: number) => (
                      <SchoolPreview
                        key={i}
                        index={i}
                        rankingRef={rankingRef}
                        schoolNameFilter={schoolNameFilter}
                        provinceTypeFilter={provinceTypeFilter}
                        isSelected={
                          `${encodeSchoolUrl(schoolPreview)}` ===
                          match.params.schoolFullName
                        }
                        schoolPreview={schoolPreview}
                        getSchoolDetailsBody={getSchoolDetailsBody}
                        onSelectSchool={this.handleSelectSchool}
                      />
                    )
                  )}
                {isFilteredSchools &&
                  getSchoolPreviewsBody.status !== "pending" &&
                  filteredSchoolPreviews &&
                  filteredSchoolPreviews.map(
                    (schoolPreview: School.SchoolPreview, i: number) => (
                      <SchoolPreview
                        key={i}
                        index={i}
                        schoolNameFilter={schoolNameFilter}
                        provinceTypeFilter={provinceTypeFilter}
                        rankingRef={rankingRef}
                        isSelected={
                          `${encodeSchoolUrl(schoolPreview)}` ===
                          match.params.schoolFullName
                        }
                        schoolPreview={schoolPreview}
                        getSchoolDetailsBody={getSchoolDetailsBody}
                        onSelectSchool={this.handleSelectSchool}
                      />
                    )
                  )}
                {getSchoolPreviewsBody.status === "pending" &&
                  (!schoolPreviews ||
                    !schoolPreviews.length ||
                    isFilteredSchools) &&
                  Array(20)
                    .fill(0)
                    .map((skeleton, i) => (
                      <SchoolPreview
                        key={i}
                        index={i}
                        rankingRef={rankingRef}
                        schoolNameFilter={undefined}
                        provinceTypeFilter={undefined}
                        isSelected={false}
                        schoolPreview={undefined}
                        getSchoolDetailsBody={getSchoolDetailsBody}
                      />
                    ))}

                {isFilteredSchools &&
                  getSchoolPreviewsBody.status !== "pending" &&
                  (!filteredSchoolPreviews ||
                    !filteredSchoolPreviews.length) && (
                    <Oops className="rank-box-oops">
                      <div>
                        <span>
                          Niestety, nie znaleziono szkół zawierających wpisaną
                          frazę
                        </span>
                      </div>
                    </Oops>
                  )}
              </div>
            </div>
          </div>
        </Bubble>
      </LazyFetchSchools>
    );
  }
}

export default withRouter(connector(RankBox));
