import {
  addCurrentUserEmailAction,
  updateCurrentUserLastAddedSchoolAtAction,
} from "./../user/users.actions";
import { AppDispatch, AppThunkAction } from "../store";

import schoolsService from "./schools.service";
import {
  appendFilteredSchoolPreviewsAction,
  appendSchoolPreviewsAction,
  setCreateMySchoolBodyAction,
  setFilteredSchoolPreviewsAction,
  setGetSchoolDetailsBodyAction,
  setGetSchoolPreviewsBodyAction,
  setSchoolPreviewsAction,
  setSelectedSchoolDetailsAction,
  updateSchoolPreviewAction,
} from "./schools.actions";
import { decodeSchoolUrl } from "../../utils/utils";

const {
  fetchSchoolPreviews,
  fetchSchoolDetails,
  createMySchool,
  fetchBestSchoolDetails,
} = schoolsService;

class SchoolsOperations {
  fetchSchoolPreviewsOperation = (
    payload: FetchSchoolPreviewsOperationPayload
  ): AppThunkAction => async (dispatch: AppDispatch) => {
    const { start, end, schoolNameFilter, provinceTypeFilter } = payload;
    try {
      dispatch(setGetSchoolPreviewsBodyAction({ status: "pending" }));
      const fetchBasicUsersRes = await fetchSchoolPreviews(
        {},
        {},
        { start, end, schoolNameFilter, provinceTypeFilter }
      );
      dispatch(setGetSchoolPreviewsBodyAction(fetchBasicUsersRes));

      if (!fetchBasicUsersRes.payload) return;
      const { schoolPreviews } = fetchBasicUsersRes.payload;
      if (!schoolNameFilter && !provinceTypeFilter) {
        payload.mode === "append"
          ? dispatch(appendSchoolPreviewsAction({ schoolPreviews }))
          : dispatch(setSchoolPreviewsAction({ schoolPreviews }));
      } else {
        payload.mode === "append"
          ? dispatch(appendFilteredSchoolPreviewsAction({ schoolPreviews }))
          : dispatch(setFilteredSchoolPreviewsAction({ schoolPreviews }));
      }
    } catch {
      dispatch(setGetSchoolPreviewsBodyAction({ status: "error" }));
    }
  };

  fetchBestSchoolDetailsOperation = (
    payload: FetchBestSchoolDetailsOperationPayload
  ): AppThunkAction => async (dispatch: AppDispatch) => {
    try {
      dispatch(setGetSchoolDetailsBodyAction({ status: "pending" }));
      const fetchSchoolByFullNameRes = await fetchBestSchoolDetails();
      dispatch(setGetSchoolDetailsBodyAction(fetchSchoolByFullNameRes));

      if (!fetchSchoolByFullNameRes.payload) return;
      dispatch(
        setSelectedSchoolDetailsAction({
          schoolDetails: fetchSchoolByFullNameRes.payload.schoolDetails,
        })
      );
    } catch {
      dispatch(setGetSchoolDetailsBodyAction({ status: "error" }));
    }
  };

  fetchSchoolDetailsOperation = (
    payload: FetchSchoolDetailsOperationPayload
  ): AppThunkAction => async (dispatch: AppDispatch) => {
    try {
      dispatch(setGetSchoolDetailsBodyAction({ status: "pending" }));
      const fetchSchoolByFullNameRes = await fetchSchoolDetails(
        { fullName: decodeSchoolUrl(payload.fullName) },
        {},
        { index: payload.index }
      );
      dispatch(setGetSchoolDetailsBodyAction(fetchSchoolByFullNameRes));

      if (!fetchSchoolByFullNameRes.payload) return;
      const { schoolDetails } = fetchSchoolByFullNameRes.payload;
      dispatch(setSelectedSchoolDetailsAction({ schoolDetails }));
      dispatch(
        updateSchoolPreviewAction({
          schoolPreviewId: schoolDetails._id,
          newSchoolPreview: {
            thumbsUp: schoolDetails.thumbsUp,
            thumbsDown: schoolDetails.thumbsDown,
            score: schoolDetails.score,
          },
        })
      );
    } catch {
      dispatch(setGetSchoolDetailsBodyAction({ status: "error" }));
    }
  };

  createMySchoolOperation = (
    payload: CreateMySchoolOperationPayload
  ): AppThunkAction => async (dispatch: AppDispatch) => {
    try {
      dispatch(setCreateMySchoolBodyAction({ status: "pending" }));
      const createMySchoolRes = await createMySchool(payload);
      dispatch(setCreateMySchoolBodyAction(createMySchoolRes));

      if (!createMySchoolRes.payload) return;
      dispatch(
        updateCurrentUserLastAddedSchoolAtAction({
          lastAddedSchool_at: createMySchoolRes.payload.lastAddedSchool_at,
        })
      );
      if (createMySchoolRes.payload.createdCurrentUserEmail) {
        dispatch(
          addCurrentUserEmailAction({
            email: createMySchoolRes.payload.createdCurrentUserEmail,
          })
        );
      }
    } catch {
      dispatch(setCreateMySchoolBodyAction({ status: "error" }));
    }
  };
}

export default new SchoolsOperations();

export type FetchSchoolPreviewsOperationPayload = {
  start?: number;
  end?: number;
  mode: "append" | "set";
  schoolNameFilter?: string;
  provinceTypeFilter?: School.ProvinceType;
};

export type FetchBestSchoolDetailsOperationPayload = {};

export type FetchSchoolDetailsOperationPayload = {
  fullName: string;
  index?: number;
};

export type CreateMySchoolOperationPayload = {
  name: string;
  city: string;
  provinceType: School.ProvinceType;
  schoolPageUrl?: string;
  schoolEmail?: string;
  schoolPhone?: string;
  userEmailAddress: string;
};
