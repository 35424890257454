import * as React from "react";
import { ISpinnerState } from "./ISpinnerState";
import { ISpinnerProps } from "./ISpinnerProps";
import "./Spinner.scss";

class Spinner extends React.Component<ISpinnerProps, ISpinnerState> {
  constructor(props: ISpinnerProps) {
    super(props);
    this.state = {};
  }

  public render(): JSX.Element {
    const { background } = this.props;
    return (
      <div className="fetch-spinner">
        <div className="fetch-spinner-shell" style={{ background }}></div>
      </div>
    );
  }
}

export default Spinner;
