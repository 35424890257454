import { isLimitReached } from "./votes.helpers";
import { getSelectedVoteType } from "../@ui/ui.selectors";
import { AppState } from "../store";

export const getCurrentUserVotes = (
  appState: AppState
): Vote.Vote[] | undefined => {
  return appState.user.currentUser?.votes;
};

export const getNegativeVotesInLastThreeDays = (
  appState: AppState
): Vote.Vote[] | undefined => {
  const currentUserVotes: Vote.Vote[] | undefined = getCurrentUserVotes(
    appState
  );
  if (!currentUserVotes) return undefined;
  const now: Date = new Date();
  return currentUserVotes
    .filter((vote) => vote.type === -1)
    .filter(
      (vote) =>
        new Date(vote.created_at).getTime() >
        now.getTime() - 3 * 1000 * 3600 * 24
    );
};

export const getPositiveVotesInLastOneDay = (
  appState: AppState
): Vote.Vote[] | undefined => {
  const currentUserVotes: Vote.Vote[] | undefined = getCurrentUserVotes(
    appState
  );
  if (!currentUserVotes) return undefined;
  const now: Date = new Date();
  return currentUserVotes
    .filter((vote) => vote.type === 1)
    .filter(
      (vote) =>
        new Date(vote.created_at).getTime() >
        now.getTime() - 1 * 1000 * 3600 * 24
    );
};

export const getIsCurrentUserLimitReached = (appState: AppState): boolean => {
  const now: Date = new Date();
  const currentUserVotes: Vote.Vote[] | undefined = getCurrentUserVotes(
    appState
  );
  const selectedVoteType: -1 | 1 | undefined = getSelectedVoteType(appState);
  if (!currentUserVotes) return false;
  if (!selectedVoteType) return false;
  return isLimitReached(currentUserVotes, selectedVoteType, now);
};

export const getCreateCurrentVoteBody = (
  appState: AppState
): Vote.Res.CreateCurrentVoteBody => {
  return appState.vote.createCurrentVoteBody;
};

export const getDeleteCurrentVoteBody = (
  appState: AppState
): Vote.Res.DeleteCurrentVoteBody => {
  return appState.vote.deleteCurrentVoteBody;
};
