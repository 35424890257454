import * as React from "react";
import { ISchoolDetailsState } from "./ISchoolDetailsState";
import { ISchoolDetailsProps } from "./ISchoolDetailsProps";
import "./SchoolDetails.scss";

import Bubble from "../../shared/Bubble/Bubble";
import SchoolDetailsTop from "../SchoolDetailsTop/SchoolDetailsTop";
import SchoolDetailsContent from "../SchoolDetailsContent/SchoolDetailsContent";
import SchoolDetailsBottom from "../SchoolDetailsBottom/SchoolDetailsBottom";
import queryString from "query-string";
import { motion } from "framer-motion";

import {
  SetAddVoteFormActionPayload,
  SetEmailVerificationFormActionPayload,
} from "../../../store/@ui/ui.actions";
import { withRouter } from "react-router";

class SchoolDetails extends React.Component<
  ISchoolDetailsProps,
  ISchoolDetailsState
> {
  bubbleRef: React.RefObject<HTMLDivElement> = React.createRef();
  resizeTimer: NodeJS.Timeout | undefined;
  defaultSectionType: App.SchoolDetailsSectionType = "basic-information";
  schoolDetailsWrapperRef: React.RefObject<HTMLDivElement> = React.createRef();
  constructor(props: ISchoolDetailsProps) {
    super(props);
    this.state = {
      touchStartClientX: undefined,
    };
  }

  componentDidMount() {
    this.handleVoteSearchUpdate();
  }

  shouldComponentUpdate(nextProps: ISchoolDetailsProps) {
    const { props } = this;

    if (props.scrollTop !== nextProps.scrollTop) {
      this.scrollBubble(nextProps.scrollTop, nextProps.isWindow);
      return false;
    }
    return true;
  }

  componentDidUpdate(oldProps: ISchoolDetailsProps) {
    const { props } = this;
    if (oldProps.selectedSchoolDetails !== props.selectedSchoolDetails) {
      this.handleSelectedSchoolDetailsUpdate();
    }
  }

  handleVoteSearchUpdate = () => {
    const { location, onSetSelectedVoteType } = this.props;
    const searchObject = queryString.parse(location.search);
    const vote: string | undefined = String(searchObject["vote"]);
    if (!vote) return;
    if (vote === "down") onSetSelectedVoteType && onSetSelectedVoteType(-1);
    if (vote === "up") onSetSelectedVoteType && onSetSelectedVoteType(1);
  };

  handleSelectedSchoolDetailsUpdate = () => {
    const {
      onResetVoteForm,
      onSetSelectedVoteType,
      onSetSelectedVoteMethod,
    } = this.props;
    onResetVoteForm && onResetVoteForm();
    onSetSelectedVoteType && onSetSelectedVoteType(undefined);
    onSetSelectedVoteMethod && onSetSelectedVoteMethod(undefined);
  };

  componentWillUnmount() {}

  public scrollBubble(nextScrollTop: number, isWindow: boolean) {
    const bubbleElement: HTMLDivElement | null = this.bubbleRef.current;
    if (!bubbleElement) return;
    if (isWindow) return;

    this.isFixed(nextScrollTop)
      ? bubbleElement.classList.add("fixed-on-side")
      : bubbleElement.classList.remove("fixed-on-side");
  }

  private handleChangeSelectedVoteType = (
    selectedVoteType: System.VoteType | undefined
  ): void => {
    const { onSetSelectedVoteType } = this.props;
    onSetSelectedVoteType && onSetSelectedVoteType(selectedVoteType);
  };

  private handleChangeSelectedVoteMethod = (
    selectedVoteMethodType: System.VoteMethodType | undefined
  ): void => {
    const { onSetSelectedVoteMethod } = this.props;
    onSetSelectedVoteMethod && onSetSelectedVoteMethod(selectedVoteMethodType);
  };

  private handleVoteDelete = (event: { id: string }) => {
    const { onVoteDelete } = this.props;
    onVoteDelete && onVoteDelete(event);
  };

  private handleVoteCreate = (event: {
    method: Vote.VoteMethod;
    firstName: string | undefined;
    comment: string | undefined;
  }) => {
    const {
      onFirstNameUpdate,
      selectedSchoolDetails,
      currentUser,
      selectedVoteType,
    } = this.props;
    if (!selectedSchoolDetails) return;
    if (!currentUser) return;
    if (!selectedVoteType) return;

    event.firstName &&
      onFirstNameUpdate &&
      onFirstNameUpdate({ firstName: event.firstName });

    if (event.method.type === "facebook")
      return this.handleVoteCreateFromFacebook(event);
    if (event.method.type === "email")
      return this.handleVoteCreateFromEmail(event);
  };

  private handleVoteCreateFromEmail = (event: {
    method: Vote.VoteMethod;
    firstName: string | undefined;
    comment: string | undefined;
  }) => {
    const {
      onVoteCreateFromNewEmail,
      onVoteCreateFromConfirmedEmail,
      onVoteCreateFromExistedEmail,
      selectedSchoolDetails,
      currentUser,
      selectedVoteType,
    } = this.props;
    if (!selectedSchoolDetails) return;
    if (!currentUser) return;
    if (!selectedVoteType) return;

    const email: User.UserEmail | undefined = currentUser.emails.find(
      (email) => email.address === event.method.value
    );

    if (!email) {
      return (
        onVoteCreateFromNewEmail &&
        onVoteCreateFromNewEmail({
          type: selectedVoteType,
          addedToSchoolId: selectedSchoolDetails._id,
          method: event.method,
          comment: event.comment,
          firstName: event.firstName,
        })
      );
    } else if (!email.isConfirmed) {
      onVoteCreateFromExistedEmail &&
        onVoteCreateFromExistedEmail({
          type: selectedVoteType,
          addedToSchoolId: selectedSchoolDetails._id,
          method: event.method,
          comment: event.comment,
          firstName: event.firstName,
        });
    } else {
      onVoteCreateFromConfirmedEmail &&
        onVoteCreateFromConfirmedEmail({
          type: selectedVoteType,
          addedToSchoolId: selectedSchoolDetails._id,
          method: event.method,
          comment: event.comment,
          firstName: event.firstName,
        });
    }
  };

  private handleVoteCreateFromFacebook = (event: {
    method: Vote.VoteMethod;
    firstName: string | undefined;
    comment: string | undefined;
  }) => {
    const {
      onVoteCreateFromExistedFacebookAccount,
      selectedSchoolDetails,
      currentUser,
      selectedVoteType,
    } = this.props;
    if (!selectedSchoolDetails) return;
    if (!currentUser) return;
    if (!selectedVoteType) return;
    const facebookAccount:
      | User.FacebookAccount
      | undefined = currentUser.facebookAccounts.find(
      (account) => account.userID === event.method.value
    );
    if (!facebookAccount) return;
    onVoteCreateFromExistedFacebookAccount &&
      onVoteCreateFromExistedFacebookAccount({
        type: selectedVoteType,
        addedToSchoolId: selectedSchoolDetails._id,
        method: event.method,
        comment: event.comment,
        firstName: event.firstName,
      });
  };

  private handleEmailVerify = (event: { verificationCode: string }) => {
    const {
      onEmailVerify,
      currentUserVoteInSelectedSchool,
      currentUserVotes,
    } = this.props;

    if (!currentUserVoteInSelectedSchool) return;
    if (!currentUserVotes) return;

    const selectedVote: Vote.Vote = currentUserVoteInSelectedSchool;
    if (selectedVote.method.type !== "email") return;
    const selectedVoteEmailAddress: string = selectedVote.method.value;
    onEmailVerify &&
      onEmailVerify({
        currentUserVotesFromEmail: currentUserVotes.filter(
          (vote: Vote.Vote) =>
            vote.method.type === "email" &&
            vote.method.value === selectedVoteEmailAddress
        ),
        emailAddress: selectedVoteEmailAddress,
        verificationCode: event.verificationCode,
      });
  };

  private handleEmailResend = (event: { emailAddress: string }) => {
    const { onEmailGenerateCode } = this.props;

    onEmailGenerateCode &&
      onEmailGenerateCode({
        emailAddress: event.emailAddress,
      });
  };

  public isFixed = (scrollTop: number): boolean => {
    const scshoolDetailsWrapper: HTMLDivElement | null = this
      .schoolDetailsWrapperRef.current;
    if (!scshoolDetailsWrapper) return false;
    const { top } = scshoolDetailsWrapper.getBoundingClientRect();
    return top <= 0;
  };

  public handleWindowHideIconClick = () => {
    const { onMinimalize, history } = this.props;
    history.push("/ranking/");
    onMinimalize && onMinimalize();
  };

  public handleOnSideHideIconClick = () => {
    const { onMinimalize } = this.props;
    onMinimalize && onMinimalize();
  };

  public handleOnSideShowIconClick = () => {
    const { onMaximalize } = this.props;
    onMaximalize && onMaximalize();
  };

  public handleSelectEmailVoteMethod = () => {
    const { onSelectEmailVoteMethod } = this.props;
    onSelectEmailVoteMethod && onSelectEmailVoteMethod();
  };

  public handleSelectFacebookVoteMethod = (event: {
    accessToken: string;
    userID: string;
  }) => {
    const {
      onFacebookAccountUpdate,
      onFacebookAccountCreate,
      currentUser,
    } = this.props;
    const isExistAccount: boolean = Boolean(
      currentUser?.facebookAccounts.find(
        (account) => account.userID === event.userID
      )
    );
    if (isExistAccount)
      return onFacebookAccountUpdate && onFacebookAccountUpdate(event);
    else return onFacebookAccountCreate && onFacebookAccountCreate(event);
  };

  public handleUnselectVoteMethod = () => {
    const { onUnselectVoteMethod } = this.props;
    onUnselectVoteMethod && onUnselectVoteMethod();
  };

  public handleUpdateAddVoteForm = (event: SetAddVoteFormActionPayload) => {
    const { onUpdateVoteForm } = this.props;
    onUpdateVoteForm && onUpdateVoteForm(event);
  };

  public handleUpdateEmailVerificationForm = (
    event: SetEmailVerificationFormActionPayload
  ) => {
    const { onUpdateEmailVerificationForm } = this.props;
    onUpdateEmailVerificationForm && onUpdateEmailVerificationForm(event);
  };

  public handleResetAddVoteForm = () => {
    const { onResetVoteForm } = this.props;
    onResetVoteForm && onResetVoteForm();
  };

  public handleResetAddVoteAuth = () => {
    const { onResetVoteAuth } = this.props;
    onResetVoteAuth && onResetVoteAuth();
  };

  public getInsideVariants = (payload: {
    isOnSide: boolean;
    isWindow: boolean;
    windowHeight: number;
  }) => {
    const { isOnSide, isWindow } = payload;

    if (isOnSide) {
      return {
        minimalized: { width: `0px` },
        maximalized: { width: `380px` },
      };
    }

    if (isWindow) {
      return {
        minimalized: { height: `0px` },
        maximalized: { height: `calc(var(--vh, 1vh) * 100 - 170px)` },
      };
    }

    return {
      minimalized: {},
      maximalized: {},
    };
  };

  public getMiddleSideVariants = (payload: { isMinimalizable: boolean }) => {
    const { isMinimalizable } = payload;

    return isMinimalizable
      ? {
          minimalized: { opacity: 0 },
          maximalized: { opacity: 1 },
        }
      : {
          minimalized: {},
          maximalized: {},
        };
  };

  public handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
    const { isWindow } = this.props;

    isWindow &&
      this.setState({
        touchStartClientX: event.touches[0].clientX,
      });
  };

  public handleTouchEnd = (event: React.TouchEvent<HTMLDivElement>) => {
    const { location, onSetSelectedSectionType } = this.props;
    const searchObject = queryString.parse(location.search);
    const selectedSectionType = searchObject.section || "basic-information";
    const { touchStartClientX } = this.state;
    const touchEndClientX = event.changedTouches[0].clientX;
    const touchDeltaClientX: number | undefined = this.getTouchDeltaClientX(
      touchStartClientX,
      touchEndClientX
    );

    if (!touchDeltaClientX) return;

    const sectionTypes: Array<App.SchoolDetailsSectionType> = [
      "basic-information",
      "experts-opinions",
      "users-comments",
    ];
    const selectedSectionTypeIdx: number = sectionTypes.findIndex(
      (sectionType) => sectionType === selectedSectionType
    );

    const prevSectionTypeIdx: number = selectedSectionTypeIdx - 1;
    const nextSectionTypeIdx: number = selectedSectionTypeIdx + 1;
    touchDeltaClientX > 60 &&
      prevSectionTypeIdx >= 0 &&
      onSetSelectedSectionType &&
      onSetSelectedSectionType(sectionTypes[prevSectionTypeIdx]);

    touchDeltaClientX < -60 &&
      nextSectionTypeIdx < sectionTypes.length &&
      onSetSelectedSectionType &&
      onSetSelectedSectionType(sectionTypes[nextSectionTypeIdx]);

    this.setState({
      touchStartClientX: undefined,
    });
  };

  public getTouchDeltaClientX = (
    touchStartClientX: number | undefined,
    touchMoveClientX: number | undefined
  ): number | undefined => {
    if (!touchStartClientX) return undefined;
    if (!touchMoveClientX) return undefined;

    return touchMoveClientX - touchStartClientX;
  };

  public handleShare = () => {
    const { onShare } = this.props;
    onShare && onShare();
  };

  public render(): JSX.Element {
    const {
      currentUser,
      currentUserVoteInSelectedSchool,
      currentUserVoteStatusInSelectedSchool,
      isCurrentUserVerified,
      isCurrentUserLimitReached,
      negativeVotesInLastThreeDays,
      positiveVotesInLastOneDay,
      selectedSchoolDetails,
      selectedVoteType,
      selectedVoteMethodType,
      selectedVoteMethodValue,
      selectedCurrentUserFacebookAccount,
      deleteCurrentVoteBody,
      patchCurrentUserBody,
      verifyCodeBody,
      generateCodeBody,
      getSchoolDetailsBody,
      addVoteForm,
      addVoteAuth,
      emailVerificationForm,
      createCurrentVoteBody,
      createCurrentUserEmailBody,
      createCurrentUserFacebookAccountBody,
      putCurrentUserFacebookAccountBody,
      scrollTop,
      isSchoolDetailsMinimalize,
      selectedSectionType,
      lastEmailResendTimestamp,
      windowHeight,
      isMinimalizable,
      isWindow,
      isOnSide,
    } = this.props;

    const animationKey: string = `${isWindow}${isOnSide}${isMinimalizable}`;

    return (
      <div
        className="SchoolDetails-wrapper"
        ref={this.schoolDetailsWrapperRef}
        onTouchStart={this.handleTouchStart}
        onTouchEnd={this.handleTouchEnd}
      >
        <Bubble
          bubbleRef={this.bubbleRef}
          ready={getSchoolDetailsBody.status === "success"}
          className={`SchoolDetails ${!isWindow && this.isFixed(scrollTop)}`}
        >
          <motion.div
            key={animationKey}
            className="inside"
            animate={isSchoolDetailsMinimalize ? "minimalized" : "maximalized"}
            initial={false}
            transition={
              isSchoolDetailsMinimalize
                ? { delay: 0.2, duration: 0.4 }
                : { duration: 0.4 }
            }
            variants={this.getInsideVariants({
              isWindow,
              isOnSide,
              windowHeight,
            })}
          >
            {isWindow && !isSchoolDetailsMinimalize && (
              <div className="top-side">
                <motion.div
                  className="hide-icon"
                  whileTap={{ scale: 0.8, color: "#bbb" }}
                  onClick={this.handleWindowHideIconClick}
                >
                  <i className={`icon-down-open`}></i>
                </motion.div>
              </div>
            )}
            {isOnSide && (
              <div className="left-side">
                {isSchoolDetailsMinimalize && (
                  <div
                    className="hide-icon"
                    onClick={this.handleOnSideShowIconClick}
                  >
                    <i className={`icon-left-open`}></i>
                  </div>
                )}
                {!isSchoolDetailsMinimalize && (
                  <div
                    className="hide-icon"
                    onClick={this.handleOnSideHideIconClick}
                  >
                    <i className={`icon-right-open`}></i>
                  </div>
                )}
              </div>
            )}
            <motion.div
              key={animationKey}
              className="middle-side"
              animate={
                isSchoolDetailsMinimalize ? "minimalized" : "maximalized"
              }
              transition={
                isSchoolDetailsMinimalize
                  ? { delay: 0, duration: 0.1 }
                  : { delay: 0.5, duration: 0.4 }
              }
              variants={this.getMiddleSideVariants({ isMinimalizable })}
            >
              <div className={`schoolDetails`}>
                <SchoolDetailsTop
                  backgroundPhotoUrl={selectedSchoolDetails?.backgroundPhotoUrl}
                  getSchoolDetailsBody={getSchoolDetailsBody}
                  backgroundColorTheme={
                    selectedSchoolDetails?.backgroundColorTheme
                  }
                  initials={selectedSchoolDetails?.initials}
                  logoPhotoUrl={selectedSchoolDetails?.logoPhotoUrl}
                  place={selectedSchoolDetails?.place}
                  name={selectedSchoolDetails?.name}
                  city={selectedSchoolDetails?.city}
                  gradesAvg={selectedSchoolDetails?.gradesAvg}
                  minimalize={selectedSectionType !== "basic-information"}
                  onShare={this.handleShare}
                />

                <SchoolDetailsContent
                  currentUserVoteInSelectedSchool={
                    currentUserVoteInSelectedSchool
                  }
                  selectedSchoolDetails={selectedSchoolDetails}
                  getSchoolDetailsBody={getSchoolDetailsBody}
                  backgroundColorTheme={
                    selectedSchoolDetails?.backgroundColorTheme
                  }
                />
                {selectedSchoolDetails && (
                  <>
                    <SchoolDetailsBottom
                      currentUserVoteInSelectedSchool={
                        currentUserVoteInSelectedSchool
                      }
                      currentUserVoteStatusInSelectedSchool={
                        currentUserVoteStatusInSelectedSchool
                      }
                      getSchoolDetailsBody={getSchoolDetailsBody}
                      selectedSchoolDetails={selectedSchoolDetails}
                      currentUser={currentUser}
                      isCurrentUserVerified={isCurrentUserVerified}
                      isCurrentUserLimitReached={isCurrentUserLimitReached}
                      negativeVotesInLastThreeDays={
                        negativeVotesInLastThreeDays
                      }
                      positiveVotesInLastOneDay={positiveVotesInLastOneDay}
                      selectedVoteMethodType={selectedVoteMethodType}
                      selectedVoteMethodValue={selectedVoteMethodValue}
                      selectedCurrentUserFacebookAccount={
                        selectedCurrentUserFacebookAccount
                      }
                      selectedVoteType={selectedVoteType}
                      patchCurrentUserBody={patchCurrentUserBody}
                      generateCodeBody={generateCodeBody}
                      verifyCodeBody={verifyCodeBody}
                      createCurrentUserEmailBody={createCurrentUserEmailBody}
                      createCurrentUserFacebookAccountBody={
                        createCurrentUserFacebookAccountBody
                      }
                      putCurrentUserFacebookAccountBody={
                        putCurrentUserFacebookAccountBody
                      }
                      deleteCurrentVoteBody={deleteCurrentVoteBody}
                      createCurrentVoteBody={createCurrentVoteBody}
                      lastEmailResendTimestamp={lastEmailResendTimestamp}
                      addVoteForm={addVoteForm}
                      emailVerificationForm={emailVerificationForm}
                      onChangeSelectedVoteType={
                        this.handleChangeSelectedVoteType
                      }
                      onChangeSelectedVoteMethod={
                        this.handleChangeSelectedVoteMethod
                      }
                      onVoteDelete={this.handleVoteDelete}
                      onVoteCreate={this.handleVoteCreate}
                      onSelectEmailVoteMethod={this.handleSelectEmailVoteMethod}
                      onSelectFacebookVoteMethod={
                        this.handleSelectFacebookVoteMethod
                      }
                      onUnselectVoteMethod={this.handleUnselectVoteMethod}
                      onEmailVerify={this.handleEmailVerify}
                      onEmailResend={this.handleEmailResend}
                      onUpdateVoteForm={this.handleUpdateAddVoteForm}
                      onUpdateEmailVerificationForm={
                        this.handleUpdateEmailVerificationForm
                      }
                      addVoteAuth={addVoteAuth}
                      onResetVoteForm={this.handleResetAddVoteForm}
                      onResetVoteAuth={this.handleResetAddVoteAuth}
                      onShare={this.handleShare}
                    />
                  </>
                )}
              </div>
            </motion.div>
          </motion.div>
        </Bubble>
      </div>
    );
  }
}

export default withRouter(SchoolDetails);
