import * as React from "react";
import { ISchoolAvatarState } from "./ISchoolAvatarState";
import { ISchoolAvatarProps } from "./ISchoolAvatarProps";
import "./SchoolAvatar.scss";

import CSS from "csstype";
import { motion } from "framer-motion";

class SchoolAvatar extends React.Component<
  ISchoolAvatarProps,
  ISchoolAvatarState
> {
  constructor(props: ISchoolAvatarProps) {
    super(props);
    this.state = {};
  }

  private getLogoBackground = (
    backgroundColorTheme: string | undefined,
    logoPhotoUrl: string | undefined
  ): CSS.Properties => {
    return {
      backgroundColor: backgroundColorTheme ? backgroundColorTheme : undefined,
      backgroundImage: logoPhotoUrl ? `url(${logoPhotoUrl})` : `none`,
    };
  };

  public render(): JSX.Element {
    const {
      isPending,
      className,
      style,
      textColorTheme,
      backgroundColorTheme,
      logoPhotoUrl,
      initials,
      place,
      minimalize,
    } = this.props;

    const { getLogoBackground } = this;

    return (
      <motion.div
        className={`schoolAvatar`}
        transition={{ delay: 0.4, duration: 1.2 }}
        animate={{
          opacity: [0, 1],
          top: [25, 35, -10, 3, -2, 0],
        }}
      >
        <motion.div
          className={`inner  ${className}`}
          style={
            isPending
              ? { ...style }
              : {
                  ...style,
                  ...getLogoBackground(backgroundColorTheme, logoPhotoUrl),
                }
          }
          animate={{
            transform: minimalize
              ? ["scale(1)", "scale(0.8)"]
              : ["scale(0.8)", "scale(1)"],
          }}
          transition={{ duration: 0.4 }}
        >
          {!isPending && !logoPhotoUrl && initials && (
            <h2
              className="initials"
              style={{
                fontSize: initials.length > 3 ? "15px" : undefined,
                color: textColorTheme ? textColorTheme : undefined,
              }}
            >
              {initials}
            </h2>
          )}
          {!isPending && place && (
            <div
              className="place"
              style={{ backgroundColor: backgroundColorTheme }}
            >
              <div>
                <span>{place}</span>
              </div>
            </div>
          )}
        </motion.div>
      </motion.div>
    );
  }
}

export default SchoolAvatar;
