import React from "react";
import { Route, Router, Switch } from "react-router";
import { createBrowserHistory } from "history";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Provider } from "react-redux";
import env from "./assets/data/.env.json";
import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  ThemeProvider,
} from "@material-ui/core";

import "./App.scss";
import store from "./store/store";

import Menu from "./components/entities/Menu/Menu";
import Pages from "./components/page/@Pages/Pages";
import Top from "./components/entities/Top/Top";
import { SkeletonTheme } from "react-loading-skeleton";
import AppLoader from "./components/entities/AppLoader/AppLoader";
import SizeProvider from "./components/entities/SizeProvider/SizeProvider";
import Embed from "./components/page/Embed/Embed";
import Redirect from "./components/page/Redirect/Redirect";

type AppProps = {};
type AppState = {};

const theme = createMuiTheme({
  palette: {
    type: "dark",
  },
});

class App extends React.Component<AppProps, AppState> {
  public state: AppState = {};
  public constructor(props: AppProps) {
    super(props);
  }

  componentDidMount() {}

  public render(): JSX.Element {
    return (
      <Provider store={store}>
        <Router history={createBrowserHistory()}>
          <GoogleReCaptchaProvider
            reCaptchaKey={env.GOOGLE_RECAPTCHA_PUBLIC_KEY}
            language="pl"
          >
            <ThemeProvider theme={theme}>
              <SkeletonTheme color="#3e3e3e" highlightColor="#484848">
                <SizeProvider>
                  <Switch>
                    <Route exact path="/embed/:schoolId">
                      <Embed />
                    </Route>
                    <Route path="/redirect">
                      <Redirect />
                    </Route>
                    <Route path="/">
                      <AppLoader>
                        <div className="App">
                          <Top />
                          <Menu />
                          <Pages />
                        </div>
                      </AppLoader>
                    </Route>
                  </Switch>
                </SizeProvider>
              </SkeletonTheme>
            </ThemeProvider>
          </GoogleReCaptchaProvider>
        </Router>
      </Provider>
    );
  }
}

export default App;
