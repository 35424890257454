import { voteSlice } from ".";

export type AddCurrentUserVoteActionPayload = {
  newVote: Vote.Vote;
};

/**@deprecated */
export const addCurrentUserVoteAction = (
  payload: AddCurrentUserVoteActionPayload
) => {
  return voteSlice.actions.addCurrentUserVote(payload);
};

/** */

export type RemoveVoteActionPayload = {
  id: string;
};

/** @deprecated */
export const removeVoteAction = (payload: RemoveVoteActionPayload) => {
  return voteSlice.actions.removeVote(payload);
};

/** */

export type AddVoteActionPayload = {
  newVote: Vote.Vote;
};

/** @deprecated */
export const addVoteAction = (payload: AddVoteActionPayload) => {
  return voteSlice.actions.addVote(payload);
};

/** Responses */
export const setGetBasicVotesBodyAction = (body: Vote.Res.GetBasicVotes) => {
  return voteSlice.actions.setGetBasicVotesBody({ body });
};

export const setGetCurrentUserVotesBodyAction = (
  body: Vote.Res.GetCurrentUserVotes
) => {
  return voteSlice.actions.setGetCurrentUserVotesBody({ body });
};

export const setCreateCurrentVoteBodyAction = (
  body: Vote.Res.CreateCurrentVoteBody
) => {
  return voteSlice.actions.setCreateCurrentVoteBody({ body });
};

export const setDeleteCurrentVoteBodyAction = (
  body: Vote.Res.DeleteCurrentVoteBody
) => {
  return voteSlice.actions.setDeleteCurrentVoteBody({ body });
};
