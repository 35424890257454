import * as React from "react";
import { ICloudState } from "./ICloudState";
import { ICloudProps } from "./ICloudProps";
import "./Cloud.scss";
import { motion } from "framer-motion";

class Cloud extends React.Component<ICloudProps, ICloudState> {
  constructor(props: ICloudProps) {
    super(props);
    this.state = {
      isAnimationComplete: false,
    };
  }

  private handleCloseButton = () => {
    const { onClose } = this.props;
    onClose && onClose();
  };

  public render(): JSX.Element {
    const { children, isOpened, className, style } = this.props;

    return (
      <>
        {isOpened && (
          <motion.div className={`Cloud`} style={style}>
            <button
              className="close"
              tabIndex={10}
              onClick={this.handleCloseButton}
            >
              <i className="icon-cancel"></i>
            </button>
            <div className={`content ${className}`}>{children}</div>
          </motion.div>
        )}
      </>
    );
  }
}

export default Cloud;
