import * as React from "react";
import { IWelcomeAddSchoolState } from "./IWelcomeAddSchoolState";
import { IWelcomeAddSchoolProps } from "./IWelcomeAddSchoolProps";
import "./WelcomeAddSchool.scss";
import Bubble from "../../shared/Bubble/Bubble";
import contents from "../../../assets/data/contents.data.json";

class WelcomeAddSchool extends React.Component<
  IWelcomeAddSchoolProps,
  IWelcomeAddSchoolState
> {
  constructor(props: IWelcomeAddSchoolProps) {
    super(props);
    this.state = {};
  }

  public render(): JSX.Element {
    return (
      <Bubble className="WelcomeAddSchool" ready={true}>
        <div className="content">
          <div
            className="icon"
            style={{
              backgroundImage: `url(${contents["add-school-welcome"].image_url})`,
            }}
          ></div>
          <h1>{contents["add-school-welcome"].header}</h1>
          <p>{contents["add-school-welcome"].description}</p>
        </div>
      </Bubble>
    );
  }
}

export default WelcomeAddSchool;
