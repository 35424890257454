import * as React from "react";
import { ISchoolDetailsVoteCloudRecaptchaStageState } from "./ISchoolDetailsVoteCloudRecaptchaStageState";
import {
  ISchoolDetailsVoteCloudRecaptchaStageDispatchProps,
  ISchoolDetailsVoteCloudRecaptchaStageProps,
  ISchoolDetailsVoteCloudRecaptchaStageStateProps,
} from "./ISchoolDetailsVoteCloudRecaptchaStageProps";
import "./SchoolDetailsVoteCloudRecaptchaStage.scss";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { AppDispatch, AppState } from "../../../store/store";
import { verifyCurrentUserOperation } from "../../../store/user/users.operations";
import { connect } from "react-redux";
import Loader from "../../shared/Loader/Loader";

const mapStateToProps = (
  state: AppState
): ISchoolDetailsVoteCloudRecaptchaStageStateProps => ({});

const mapDispatchToProps = (
  dispatch: AppDispatch
): ISchoolDetailsVoteCloudRecaptchaStageDispatchProps => ({
  onCurrentUserVerify: (token) => {
    dispatch(verifyCurrentUserOperation({ token }));
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);

class SchoolDetailsVoteCloudRecaptchaStage extends React.Component<
  ISchoolDetailsVoteCloudRecaptchaStageProps,
  ISchoolDetailsVoteCloudRecaptchaStageState
> {
  timeout: NodeJS.Timeout | undefined = undefined;
  constructor(props: ISchoolDetailsVoteCloudRecaptchaStageProps) {
    super(props);
    this.state = {};
  }

  public handleGoogleReCaptchaVerify = (token: string) => {
    const { onCurrentUserVerify } = this.props;

    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      onCurrentUserVerify(token);
    }, 1500);
  };

  public render(): JSX.Element {
    return (
      <div className={`SchoolDetailsVoteCloudRecaptchaStage`}>
        <GoogleReCaptcha onVerify={this.handleGoogleReCaptchaVerify} />

        <div className="info">
          <div>
            <Loader />
          </div>
          <div>
            <div>
              <span>Momencik... Sprawdzamy tylko czy nie jesteś robotem.</span>
            </div>
            <div className="google-label">
              <span>
                This site is protected by <b>reCAPTCHA</b> and the Google{" "}
                <a
                  className="app-link"
                  href="https://policies.google.com/privacy"
                >
                  Privacy Policy
                </a>{" "}
                and{" "}
                <a
                  className="app-link"
                  href="https://policies.google.com/terms"
                >
                  Terms of Service
                </a>{" "}
                apply.
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connector(SchoolDetailsVoteCloudRecaptchaStage);
