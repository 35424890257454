import { schoolSlice } from ".";

export type SetSchoolPreviewsActionPayload = {
  schoolPreviews: School.SchoolPreview[];
};

export const setSchoolPreviewsAction = (
  payload: SetSchoolPreviewsActionPayload
) => {
  return schoolSlice.actions.setSchoolPreviews(payload);
};

/** */

export type SetFilteredSchoolPreviewsActionPayload = {
  schoolPreviews: School.SchoolPreview[] | undefined;
};

export const setFilteredSchoolPreviewsAction = (
  payload: SetFilteredSchoolPreviewsActionPayload
) => {
  return schoolSlice.actions.setFilteredSchoolPreviews(payload);
};

/** */

export type AddSelectedSchoolDetailsVoteBriefActionPayload = {
  addedToSchoolId: string;
  voteBrief: Vote.VoteBrief;
};

export const addSelectedSchoolDetailsVoteBriefAction = (
  payload: AddSelectedSchoolDetailsVoteBriefActionPayload
) => {
  return schoolSlice.actions.addSelectedSchoolDetailsVoteBrief(payload);
};

/** */

export type RemoveSelectedSchoolDetailsVoteBriefActionPayload = {
  addedToSchoolId: string;
  voteBriefId: string;
};

export const removeSelectedSchoolDetailsVoteBriefAction = (
  payload: RemoveSelectedSchoolDetailsVoteBriefActionPayload
) => {
  return schoolSlice.actions.removeSelectedSchoolDetailsVoteBrief(payload);
};

/** */

export type IncrementThumbsUpActionPayload = {
  schoolPreviewId: string;
};

export const incrementThumbsUpAction = (
  payload: IncrementThumbsUpActionPayload
) => {
  return schoolSlice.actions.incrementThumbsUp(payload);
};

/** */

export type DecrementThumbsUpActionPayload = {
  schoolPreviewId: string;
};

export const decrementThumbsUpAction = (
  payload: DecrementThumbsUpActionPayload
) => {
  return schoolSlice.actions.decrementThumbsUp(payload);
};

/** */

export type IncrementThumbsDownActionPayload = {
  schoolPreviewId: string;
};

export const incrementThumbsDownAction = (
  payload: IncrementThumbsDownActionPayload
) => {
  return schoolSlice.actions.incrementThumbsDown(payload);
};

/** */

export type DecrementThumbsDownActionPayload = {
  schoolPreviewId: string;
};

export const decrementThumbsDownAction = (
  payload: DecrementThumbsDownActionPayload
) => {
  return schoolSlice.actions.decrementThumbsDown(payload);
};

/** */

export type AppendSchoolPreviewsActionPayload = {
  schoolPreviews: School.SchoolPreview[];
};

export const appendSchoolPreviewsAction = (
  payload: AppendSchoolPreviewsActionPayload
) => {
  return schoolSlice.actions.appendSchoolPreviews(payload);
};

/** */

export type AppendFilteredSchoolPreviewsActionPayload = {
  schoolPreviews: School.SchoolPreview[];
};

export const appendFilteredSchoolPreviewsAction = (
  payload: AppendFilteredSchoolPreviewsActionPayload
) => {
  return schoolSlice.actions.appendFilteredSchoolPreviews(payload);
};

/** */

export type UnsetSchoolPreviewsActionPayload = {};

export const unsetSchoolPreviewsAction = (
  payload: UnsetSchoolPreviewsActionPayload
) => {
  return schoolSlice.actions.unsetSchoolPreviews(payload);
};

/** */

export type AddSchoolPreviewActionPayload = {
  newSchoolPreview: School.SchoolPreview;
};

export const addSchoolPreviewsAction = (
  payload: AddSchoolPreviewActionPayload
) => {
  return schoolSlice.actions.addSchoolPreviews(payload);
};

/** */

export type UpdateSchoolPreviewActionPayload = {
  schoolPreviewId: string;
  newSchoolPreview: Partial<School.SchoolPreview>;
};

export const updateSchoolPreviewAction = (
  payload: UpdateSchoolPreviewActionPayload
) => {
  return schoolSlice.actions.updateSchoolPreview(payload);
};

/** */

export type SetSelectedSchoolDetailsActionPayload = {
  schoolDetails: School.SchoolDetails | undefined;
};

export const setSelectedSchoolDetailsAction = (
  payload: SetSelectedSchoolDetailsActionPayload
) => {
  return schoolSlice.actions.setSelectedSchoolDetails(payload);
};

/** */

export const setGetSchoolDetailsBodyAction = (
  body: School.Res.GetSchoolDetailsByFullNameBody
) => {
  return schoolSlice.actions.setGetSchoolDetailsBody({ body });
};

export const setCreateMySchoolBodyAction = (
  body: School.Res.CreateMySchoolBody
) => {
  return schoolSlice.actions.setCreateMySchoolBody({ body });
};

export const setGetSchoolPreviewsBodyAction = (
  body: School.Res.GetSchoolPreviewsBody
) => {
  return schoolSlice.actions.setGetSchoolPreviewsBody({ body });
};
