import { userSlice } from ".";

export type SetCurrentUserPayload = {
  currentUser: User.CurrentUser | undefined;
};

export const setCurrentUserAction = (payload: SetCurrentUserPayload) => {
  return userSlice.actions.setCurrentUser(payload);
};

/** */

export type SetCurrentUserLastCaptchaVerificationActionPayload = {
  lastCaptchaVerification: Date;
};

export const setCurrentUserLastCaptchaVerificationAction = (
  payload: SetCurrentUserLastCaptchaVerificationActionPayload
) => {
  return userSlice.actions.setCurrentUserLastCaptchaVerification(payload);
};

/** */

export type UpdateCurrentUserFirstNamePayload = {
  firstName: string | undefined;
};

export const updateCurrentUserFirstNameAction = (
  payload: UpdateCurrentUserFirstNamePayload
) => {
  return userSlice.actions.updateCurrentUserFirstName(payload);
};

/** */

export type UpdateCurrentUserLastAddedSchoolAtPayload = {
  lastAddedSchool_at: Date;
};

export const updateCurrentUserLastAddedSchoolAtAction = (
  payload: UpdateCurrentUserLastAddedSchoolAtPayload
) => {
  return userSlice.actions.updateCurrentUserLastAddedSchoolAt(payload);
};

/** */

export type AddCurrentUserEmailPayload = {
  email: User.UserEmail;
};

export const addCurrentUserEmailAction = (
  payload: AddCurrentUserEmailPayload
) => {
  return userSlice.actions.addCurrentUserEmail(payload);
};

/** */

export type AddCurrentUserFacebookAccountPayload = {
  facebookAccount: User.FacebookAccount;
};

export const addCurrentUserFacebookAccountAction = (
  payload: AddCurrentUserFacebookAccountPayload
) => {
  return userSlice.actions.addCurrentUserFacebookAccount(payload);
};

/** */

export type AddCurrentUserVotePayload = {
  vote: Vote.Vote;
};

export const addCurrentUserVoteAction = (
  payload: AddCurrentUserVotePayload
) => {
  return userSlice.actions.addCurrentUserVote(payload);
};

/** */

export type ConfirmCurrentUserVotePayload = {
  voteId: string;
};

export const confirmCurrentUserVoteAction = (
  payload: ConfirmCurrentUserVotePayload
) => {
  return userSlice.actions.confirmCurrentUserVote(payload);
};

/** */

export type RemoveCurrentUserVotePayload = {
  voteId: string;
};

export const removeCurrentUserVoteAction = (
  payload: RemoveCurrentUserVotePayload
) => {
  return userSlice.actions.removeCurrentUserVote(payload);
};

/** */

export type RemoveCurrentUserFacebookAccountPayload = {
  userID: string;
};

export const removeCurrentUserFacebookAccountAction = (
  payload: RemoveCurrentUserFacebookAccountPayload
) => {
  return userSlice.actions.removeCurrentUserFacebookAccount(payload);
};

/** */

export type UpdateCurrentUserFacebookAccountPayload = {
  userID: string;
  newAccount: Partial<User.FacebookAccount>;
};

export const updateCurrentUserFacebookAccountAction = (
  payload: UpdateCurrentUserFacebookAccountPayload
) => {
  return userSlice.actions.updateCurrentUserFacebookAccount(payload);
};

/** */

export type VerifyCurrentUserEmailPayload = {
  emailAddress: string;
};

export const verifyCurrentUserEmailAction = (
  payload: VerifyCurrentUserEmailPayload
) => {
  return userSlice.actions.verifyCurrentUserEmail(payload);
};

/** Responses: */

export const setGetBasicUsersBodyAction = (
  body: User.Res.GetBasicUsersBody
) => {
  return userSlice.actions.setGetBasicUsersBody({ body });
};

export const setGetCurrentUserBodyAction = (
  body: User.Res.GetCurrentUserBody
) => {
  return userSlice.actions.setGetCurrentUserBody({ body });
};

export const setPatchCurrentUserBodyAction = (
  body: User.Res.PatchCurrentUserBody
) => {
  return userSlice.actions.setPatchCurrentUserBody({ body });
};

export const setCreateCurrentUserEmailBodyAction = (
  body: User.Res.CreateCurrentUserEmailBody
) => {
  return userSlice.actions.setCreateCurrentUserEmailBody({ body });
};

export const setCreateCurrentUserFacebookAccountBodyAction = (
  body: User.Res.CreateCurrentUserFacebookAccountBody
) => {
  return userSlice.actions.setCreateCurrentUserFacebookAccountBody({ body });
};

export const setPutCurrentUserFacebookAccountBodyAction = (
  body: User.Res.PutCurrentUserFacebookAccountBody
) => {
  return userSlice.actions.setPutCurrentUserFacebookAccountBody({ body });
};

export const setGenerateCodeBodyAction = (body: Code.Res.GenerateCodeBody) => {
  return userSlice.actions.setGenerateCodeBody({ body });
};

export const setVerifyCodeBodyAction = (body: Code.Res.VerifyCodeBody) => {
  return userSlice.actions.setVerifyCodeBody({ body });
};

export const setVerifyCurrentUserBodyAction = (
  body: User.Res.VerifyCurrentUserBody
) => {
  return userSlice.actions.setVerifyCurrentUserBody({ body });
};
