import * as React from "react";
import { ILogoIconState } from "./ILogoIconState";
import { ILogoIconProps } from "./ILogoIconProps";
import "./LogoIcon.scss";
import MonitorIcon from "../MonitorIcon/MonitorIcon";

class LogoIcon extends React.Component<ILogoIconProps, ILogoIconState> {
  constructor(props: ILogoIconProps) {
    super(props);
    this.state = {};
  }

  public render(): JSX.Element {
    return (
      <div className={`LogoIcon`}>
        <MonitorIcon text={"</>"} />
      </div>
    );
  }
}

export default LogoIcon;
