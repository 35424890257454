import Joi from "joi";

export const JoiObjectId = Joi.string()
  .regex(/^[0-9a-fA-F]{24}$/)
  .required();

export const JoiUserEmailSchema = {
  _id: JoiObjectId.required(),
  address: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .label("E-mail")
    .messages({
      "any.required": `{#label} jest wymaganym polem`,
      "string.email": `{#label} musi być poprawnym adresem`,
    }),
  isConfirmed: Joi.bool().required(),
  created_at: Joi.date().required(),
  updated_at: Joi.date().required(),
  confirmed_at: Joi.date().optional(),
};

export const JoiFacebookAccountSchema = {
  userID: Joi.string().required(),
  accessToken: Joi.string().alphanum().required(),
  first_name: Joi.string().optional(),
  last_name: Joi.string().optional(),
  picture: Joi.string().optional(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .optional(),
  created_at: Joi.date().required(),
  updated_at: Joi.date().required(),
};

export const JoiUserSchema = {
  _id: Joi.string()
    .regex(/^[0-9a-fA-F]{24}$/)
    .required(),
  isIncluded: Joi.bool().required(),
  remoteAddress: Joi.string()
    .regex(/^[a-f0-9.:]/)
    .required(),
  emails: Joi.array().items(Joi.object(JoiUserEmailSchema)).required(),
  facebookAccounts: Joi.array()
    .items(Joi.object(JoiFacebookAccountSchema))
    .required(),
  created_at: Joi.date().optional(),
  updated_at: Joi.date().optional(),
  lastAddedSchool_at: Joi.date().optional(),
  firstName: Joi.string()
    .regex(/^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ\s]*$/)
    .min(3)
    .max(30)
    .optional()
    .label("Imię")
    .messages({
      "string.pattern.base": `{#label} może składać się z liter oraz spacji`,
      "string.base": `{#label} musi być tekstem`,
      "string.min": `{#label} musi mieć min. {#limit} znaki`,
      "string.max": `{#label} może mieć maks. {#limit} znaków`,
    }),
};

export const JoiSchoolSchema = {
  _id: Joi.string()
    .regex(/^[0-9a-fA-F]{24}$/)
    .optional(),
  isIncluded: Joi.bool().required(),
  name: Joi.string()
    .regex(/^[0-9A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ\s.-]*$/)
    .min(4)
    .max(80)
    .required()
    .label("Nazwa szkoły")
    .messages({
      "string.pattern.base": `{#label} może składać się z liter, cyfr, spacji, kropek oraz myślników`,
      "string.base": `{#label} musi być tekstem`,
      "string.min": `{#label} musi mieć min. {#limit} znaki`,
      "string.max": `{#label} może mieć maks. {#limit} znaków`,
      "any.required": `{#label} jest wymaganym polem`,
    }),
  city: Joi.string()
    .regex(/^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ\s-]*$/)
    .min(3)
    .max(30)
    .required()
    .label("Miasto")
    .messages({
      "string.pattern.base": `{#label} może składać się z liter, spacji oraz myśliników`,
      "string.base": `{#label} musi być tekstem`,
      "string.min": `{#label} musi mieć min. {#limit} znaki`,
      "string.max": `{#label} może mieć maks. {#limit} znaków`,
      "any.required": `{#label} jest wymaganym polem`,
    }),
  provinceType: Joi.string()
    .min(2)
    .max(2)
    .valid(
      "dl",
      "kp",
      "le",
      "li",
      "lo",
      "mp",
      "ma",
      "op",
      "pk",
      "pl",
      "pm",
      "sl",
      "sw",
      "wm",
      "wp",
      "zp"
    )
    .required()
    .label("Województwo")
    .messages({ "any.required": `{#label} jest wymaganym polem` }),
  googlePlaceId: Joi.string().optional(),
  googleSearchUrl: Joi.string()
    .uri({ scheme: ["http", "https"] })
    .optional(),
  statsScore: Joi.number().optional(),
  backgroundColorTheme: Joi.string().required(),
  colorTheme: Joi.string().required(),
  backgroundPhotoUrl: Joi.string()
    .uri({ scheme: ["http", "https"] })
    .optional(),
  logoPhotoUrl: Joi.string()
    .uri({ scheme: ["http", "https"] })
    .optional(),
  schoolPageUrl: Joi.string()
    .uri({ scheme: ["http", "https"] })
    .optional()
    .label("Strona internetowa szkoły")
    .messages({
      "string.uri": "{#label} musi być poprawnym adresem URL",
      "string.uriCustomScheme": `{#label} musi być poprawnym adresem URL`,
      "string.uriRelativeOnly": `{#label} musi być poprawnym adresem URL`,
      "string.domain": `{#label} musi być poprawnym adresem URL`,
    }),

  schoolEmail: Joi.string()
    .email({ tlds: { allow: false } })
    .optional()
    .label("E-mail szkoły")
    .messages({
      "string.email": `{#label} musi być poprawnym adresem`,
    }),
  schoolPhone: Joi.string()
    .regex(/^\d+$/)
    .min(9)
    .max(13)
    .optional()
    .label("Telefon szkoły")
    .messages({
      "string.base": `{#label} musi być tekstem składającym się z cyfr`,
      "string.pattern.base": `{#label} musi składać się wyłącznie z cyfr`,
      "string.min": `{#label} musi mieć min. {#limit} znaki`,
      "string.max": `{#label} może mieć maks. {#limit} znaków`,
    }),
};

export const JoiGradeSchema = {
  _id: JoiObjectId.required(),
  type: Joi.number().valid(0, 1, 2, 3, 4, 5).required(),
  addedByExpertId: JoiObjectId.required(),
  addedToSchoolId: JoiObjectId.required(),
  isIncluded: Joi.bool().required(),
  isVisible: Joi.bool().required(),
  opinion: Joi.string()
    .regex(/^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ\s.,?!-+%()]*$/)
    .max(500)
    .optional(),
};

export const JoiExpertSchema = {
  _id: JoiObjectId.required(),
  prefixName: Joi.string()
    .regex(/^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ\s.]*$/)
    .min(2)
    .max(20)
    .optional(),
  firstName: Joi.string()
    .regex(/^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ]*$/)
    .min(3)
    .max(30)
    .required(),
  lastName: Joi.string()
    .regex(/^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ]*$/)
    .min(3)
    .max(30)
    .required(),
  title: Joi.string()
    .regex(/^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ\s,.-]*$/)
    .min(3)
    .max(80)
    .optional(),
  photoUrl: Joi.string()
    .uri({ scheme: ["http", "https"] })
    .optional(),
  description: Joi.string().max(512).optional(),
};

export const JoiCodeSchema = {
  _id: JoiObjectId.required(),
  generatedByUserId: JoiObjectId.required(),
  emailAddress: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  verificationCode: Joi.string()
    .regex(/^\d+$/)
    .min(6)
    .max(6)
    .required()
    .label("Kod")
    .messages({
      "any.required": `{#label} jest wymaganym polem`,
      "string.pattern.base": `{#label} musi składać się wyłącznie z cyfr`,
      "string.base": `{#label} musi być tekstem`,
      "string.min": `{#label} musi mieć {#limit} znaków`,
      "string.max": `{#label} może mieć {#limit} znaków`,
    }),
  availableAttempts: Joi.number().min(0).max(100).required(),
  attempts: Joi.array().items(Joi.date()).required(),
  expiration_at: Joi.date().optional(),
};

export const JoiVoteSchema = {
  _id: JoiObjectId.required(),
  type: Joi.number().valid(-1, 1).required(),
  addedByUserId: JoiObjectId.required(),
  addedToSchoolId: JoiObjectId.required(),
  userMailAddress: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  isIncluded: Joi.bool().required(),
  isVisible: Joi.bool().required(),
  comment: Joi.string()
    .regex(/^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ\s.,?!-+%()]*$/)
    .min(3)
    .max(255)
    .optional()
    .label("Komentarz")
    .messages({
      "string.pattern.base": `{#label} może składać się z liter oraz spacji`,
      "string.base": `{#label} musi być tekstem`,
      "string.min": `{#label} musi mieć min. {#limit} znaki`,
      "string.max": `{#label} może mieć maks. {#limit} znaków`,
    }),
  firstName: Joi.string()
    .regex(/^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ\s]*$/)
    .min(3)
    .max(30),
};
