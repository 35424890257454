import * as React from "react";
import { ILazyFetchSchoolsState } from "./ILazyFetchSchoolsState";
import {
  ILazyFetchSchoolsDispatchProps,
  ILazyFetchSchoolsProps,
  ILazyFetchSchoolsStateProps,
} from "./ILazyFetchSchoolsProps";
import "./LazyFetchSchools.scss";
import { AppDispatch, AppState } from "../../../store/store";
import { connect } from "react-redux";

import {
  getGetSchoolPreviewsBody,
  getSchoolPreviewsCount,
} from "../../../store/school/school.selectors";
import schoolsOperations from "../../../store/school/schools.operations";
import {
  getSchoolNameFilter,
  getProvinceTypeFilter,
} from "../../../store/@ui/ui.selectors";
import Spinner from "../../shared/Spinner/Spinner";

const mapStateToProps = (appState: AppState): ILazyFetchSchoolsStateProps => ({
  schoolNameFilter: getSchoolNameFilter(appState),
  provinceTypeFilter: getProvinceTypeFilter(appState),
  schoolPreviewsCount: getSchoolPreviewsCount(appState),
  getSchoolPreviewsBody: getGetSchoolPreviewsBody(appState),
});

const mapDispatchToProps = (
  dispatch: AppDispatch
): ILazyFetchSchoolsDispatchProps => ({
  onFetchSchools: (start, end, mode, schoolNameFilter, provinceTypeFilter) => {
    dispatch(
      schoolsOperations.fetchSchoolPreviewsOperation({
        start,
        end,
        mode,
        schoolNameFilter,
        provinceTypeFilter,
      })
    );
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);

class LazyFetchSchools extends React.Component<
  ILazyFetchSchoolsProps,
  ILazyFetchSchoolsState
> {
  scrollPageTimer: NodeJS.Timeout | undefined;
  afterFetchAllSchoolsTimer: NodeJS.Timeout | undefined;
  constructor(props: ILazyFetchSchoolsProps) {
    super(props);
    this.state = {
      oldSchoolPreviewsCount: undefined,
    };
  }

  componentDidMount() {
    this.initFetch();
    window.addEventListener("scroll", this.handleScrollTopChange);
  }

  componentDidUpdate(oldProps: ILazyFetchSchoolsProps) {
    const { props } = this;
    if (oldProps.getSchoolPreviewsBody !== props.getSchoolPreviewsBody) {
      this.handleGetSchoolsBodyChange(oldProps);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScrollTopChange);
  }

  initFetch() {
    const {
      onFetchSchools,
      schoolPreviewsCount,
      getSchoolPreviewsBody,
    } = this.props;
    const {} = this.state;
    !schoolPreviewsCount &&
      getSchoolPreviewsBody.status !== "pending" &&
      onFetchSchools &&
      onFetchSchools(0, 40, "set");

    schoolPreviewsCount &&
      getSchoolPreviewsBody.status !== "pending" &&
      onFetchSchools &&
      onFetchSchools(0, schoolPreviewsCount, "set");
  }

  public handleScrollTopChange = (event: Event) => {
    const { scrollTop, scrollHeight } = document.documentElement;
    const isScrolledToBottom: boolean =
      scrollTop + window.innerHeight >= scrollHeight - 10;
    this.scrollPageTimer && clearTimeout(this.scrollPageTimer);
    this.scrollPageTimer = setTimeout(() => {
      const {
        onFetchSchools,
        getSchoolPreviewsBody,
        schoolPreviewsCount,
        schoolNameFilter,
        provinceTypeFilter,
      } = this.props;
      const { oldSchoolPreviewsCount } = this.state;
      if (!isScrolledToBottom) return;
      if (oldSchoolPreviewsCount === schoolPreviewsCount) {
        this.afterFetchAllSchoolsTimer &&
          clearTimeout(this.afterFetchAllSchoolsTimer);
        setTimeout(() => {
          this.setState({ oldSchoolPreviewsCount: undefined });
        }, 30000);
        return;
      }
      if (getSchoolPreviewsBody.status === "pending") return;
      if (schoolNameFilter || provinceTypeFilter) return;
      onFetchSchools &&
        onFetchSchools(schoolPreviewsCount, schoolPreviewsCount + 40, "append");

      this.setState({ oldSchoolPreviewsCount: schoolPreviewsCount });
    }, 150);
  };

  public handleGetSchoolsBodyChange(oldProps: ILazyFetchSchoolsProps) {}

  public render(): JSX.Element {
    const { children, schoolPreviewsCount, getSchoolPreviewsBody } = this.props;
    const isNextPartOfSchoolsPending: boolean =
      schoolPreviewsCount > 0 && getSchoolPreviewsBody.status === "pending";
    return (
      <div className="LazyFetchSchools">
        {children}

        <div className="fetch-spinner-wrapper">
          {isNextPartOfSchoolsPending && <Spinner />}
        </div>
      </div>
    );
  }
}

export default connector(LazyFetchSchools);
