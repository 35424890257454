import * as React from "react";
import { IFooterState } from "./IFooterState";
import {
  IFooterDispatchProps,
  IFooterProps,
  IFooterStateProps,
} from "./IFooterProps";
import "./Footer.scss";
import { AppDispatch, AppState } from "../../../store/store";
import { connect } from "react-redux";
import {
  getCurrentUser,
  getGetCurrentUserBody,
  getIsCurrentUserVerified,
} from "../../../store/user/users.selectors";
import { motion } from "framer-motion";

export const mapStateToProps = (appState: AppState): IFooterStateProps => ({
  currentUser: getCurrentUser(appState),
  isCurrentUserVerified: getIsCurrentUserVerified(appState),
  getCurrentUserBody: getGetCurrentUserBody(appState),
});

export const mapDispatchToProps = (
  dispatch: AppDispatch
): IFooterDispatchProps => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);

class Footer extends React.Component<IFooterProps, IFooterState> {
  constructor(props: IFooterProps) {
    super(props);
    this.state = {};
  }

  public getName = (payload: {
    currentUser: User.CurrentUser | undefined;
    getCurrentUserBody: User.Res.GetCurrentUserBody;
  }): string => {
    const { getCurrentUserBody, currentUser } = payload;
    if (getCurrentUserBody.status === "pending" && !currentUser)
      return "Trwa identyfikacja...";
    if (getCurrentUserBody.status !== "pending" && !currentUser)
      return "Niezaufany klient";
    return `Witaj ${currentUser?.firstName || "Anonim"}!`;
  };

  public getIp = (payload: {
    currentUser: User.CurrentUser | undefined;
    getCurrentUserBody: User.Res.GetCurrentUserBody;
  }): string => {
    const { getCurrentUserBody, currentUser } = payload;
    return currentUser ? `${currentUser.remoteAddress}` : "";
  };

  public getIcon = (payload: {
    currentUser: User.CurrentUser | undefined;
    getCurrentUserBody: User.Res.GetCurrentUserBody;
  }) => {
    const { getCurrentUserBody, currentUser } = payload;
    if (getCurrentUserBody.status === "pending") return "icon-spin6";
    if (!currentUser || !currentUser.isIncluded) return "icon-user-times";
    if (currentUser) return "icon-user";
  };

  public getRole = (payload: {
    isCurrentUserVerified: boolean;
    currentUser: User.CurrentUser | undefined;
    getCurrentUserBody: User.Res.GetCurrentUserBody;
  }): string | undefined => {
    const { getCurrentUserBody, currentUser, isCurrentUserVerified } = payload;
    if (getCurrentUserBody.status === "pending") return undefined;
    if (!currentUser || !currentUser.isIncluded)
      return "Użytkownik nieautoryzowany";
    if (!isCurrentUserVerified) return "Użytkownik niezweryfikowany";
    return "Użytkownik autoryzowany";
  };

  public render(): JSX.Element {
    const {
      currentUser,
      isCurrentUserVerified,
      getCurrentUserBody,
    } = this.props;

    return (
      <div className={`Footer`}>
        <div className="bar">
          <div className="photo">
            <i
              className={this.getIcon({ currentUser, getCurrentUserBody })}
            ></i>
            {getCurrentUserBody.status !== "pending" && (
              <motion.div
                className={`status ${
                  !currentUser || !currentUser.isIncluded ? "offline" : "online"
                }`}
                transition={{ duration: 0.4, delay: 0.15 }}
                animate={{
                  opacity: [0, 1],
                  scale: [0, 2, 0.6, 1],
                }}
              ></motion.div>
            )}
          </div>
          <div className="content">
            <div className="main">
              {this.getName({ currentUser, getCurrentUserBody })}
            </div>
            <div className="sub">
              <div className="role">
                {this.getRole({
                  currentUser,
                  getCurrentUserBody,
                  isCurrentUserVerified,
                })}
              </div>
              <div className="ip">
                {this.getIp({ currentUser, getCurrentUserBody })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connector(Footer);
