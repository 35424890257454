import * as React from "react";
import { IScoreBoxState } from "./IScoreBoxState";
import { IScoreBoxProps } from "./IScoreBoxProps";
import "./ScoreBox.scss";
import CountUp from "react-countup";
import { motion } from "framer-motion";
import { HSLToHex } from "../../../utils/utils";
import Skeleton from "react-loading-skeleton";

class ScoreBox extends React.Component<IScoreBoxProps, IScoreBoxState> {
  constructor(props: IScoreBoxProps) {
    super(props);
    this.state = {};
  }

  public handleCountUpUpdate = (event: any) => {};

  public render(): JSX.Element {
    const {
      title,
      score,
      description,
      schoolId,
      colorTheme,
      isPending,
    } = this.props;
    const color = HSLToHex(colorTheme);
    return (
      <div className={`ScoreBox`}>
        {!isPending && (
          <div className="score-wrapper">
            <span className="count">
              {score >= 0 ? "+" : ""}
              <CountUp
                key={schoolId}
                end={score}
                duration={3}
                onUpdate={this.handleCountUpUpdate}
              />
            </span>
            <motion.div
              key={schoolId}
              style={{
                backgroundColor: "#414141",
              }}
              className="score"
              animate={{
                backgroundColor: (color && ["#414141", color]) || undefined,
                filter:
                  score < 0 ? ["brightness(1)", "brightness(0.6)"] : undefined,
              }}
              transition={{
                duration: 2.5,
              }}
            ></motion.div>
          </div>
        )}
        {isPending && <Skeleton className="score-wrapper" />}
        {!isPending && (
          <div className="score-text">
            <div className="score-title">{title}</div>
            <div className="score-description">{description}</div>
          </div>
        )}
        {isPending && (
          <div className="score-text">
            <Skeleton />
            <Skeleton />
          </div>
        )}
      </div>
    );
  }
}

export default ScoreBox;
