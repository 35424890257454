import * as React from "react";
import { IRankingState } from "./IRankingState";
import {
  IRankingProps,
  RankingDispatchProps,
  RankingStateProps,
} from "./IRankingProps";
import "./Ranking.scss";
import WelcomeComponent from "../../entities/Welcome/Welcome";
import RankBoxComponent from "../../entities/RankBox/RankBox";
import Podium from "../../entities/Podium/Podium";
import { RouteComponentProps, withRouter } from "react-router";
import RankSearch from "../../entities/RankSearch/RankSearch";
import { AppDispatch, AppState } from "../../../store/store";
import { connect } from "react-redux";
import SchoolDetailsContainer from "../../entities/SchoolDetailsContainer/SchoolDetailsContainer";
import queryString from "query-string";

const mapStateToProps = (appState: AppState): RankingStateProps => ({});

const mapDispatchToProps = (
  dispatch: AppDispatch
): RankingDispatchProps => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);

class Ranking extends React.Component<
  IRankingProps & RouteComponentProps,
  IRankingState
> {
  scrollTimer: NodeJS.Timeout | undefined;
  rankingRef: React.RefObject<HTMLDivElement> = React.createRef();
  constructor(props: IRankingProps & RouteComponentProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.handleVoteSearchUpdate();
  }

  componentDidUpdate(oldProps: IRankingProps) {
    const { props } = this;

    if (
      props.match.params.schoolFullName !== oldProps.match.params.schoolFullName
    ) {
      this.handleSchoolFullNameParamChange();
    }
  }

  handleVoteSearchUpdate = () => {
    const { location } = this.props;
    const searchObject = queryString.parse(location.search);
    const vote: string | undefined = String(searchObject["vote"]);
    if (!vote) return;
    this.scrollToRankBox();
  };

  public scrollToRankBox = () => {
    const { rankingRef } = this;
    const rankingElement: HTMLDivElement | null = rankingRef.current;
    if (!rankingElement) return;
    this.scrollTimer && clearTimeout(this.scrollTimer);
    this.scrollTimer = setTimeout(() => {
      rankingElement.scrollIntoView({ behavior: "smooth" });
    }, 500);
  };

  public handleSchoolFullNameParamChange() {
    const { match } = this.props;
  }

  public render(): JSX.Element {
    const {} = this.props;

    return (
      <div className={`Ranking`}>
        <div className="top">
          <WelcomeComponent />
          <Podium />
        </div>
        <div className="bottom">
          <div
            className="ranking-area"
            style={{ flex: 1 }}
            ref={this.rankingRef}
          >
            <RankSearch />
            <RankBoxComponent rankingRef={this.rankingRef} />
          </div>

          <SchoolDetailsContainer />
        </div>
      </div>
    );
  }
}

export default withRouter(connector(Ranking));
