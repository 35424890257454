import * as React from "react";
import { ISchoolDetailsVoteCloudErrorStageState } from "./ISchoolDetailsVoteCloudErrorStageState";
import { ISchoolDetailsVoteCloudErrorStageProps } from "./ISchoolDetailsVoteCloudErrorStageProps";
import "./SchoolDetailsVoteCloudErrorStage.scss";
import SchoolDetailsVoteIcon from "../SchoolDetailsVoteIcon/SchoolDetailsVoteIcon";

class SchoolDetailsVoteCloudErrorStage extends React.Component<
  ISchoolDetailsVoteCloudErrorStageProps,
  ISchoolDetailsVoteCloudErrorStageState
> {
  constructor(props: ISchoolDetailsVoteCloudErrorStageProps) {
    super(props);
    this.state = {};
  }

  public render(): JSX.Element {
    const { selectedVoteType } = this.props;
    return (
      <div className={`schoolDetailsVoteCloudErrorStage`}>
        <div className="body">
          <div className="vote-icon">
            <SchoolDetailsVoteIcon selectedVoteType={selectedVoteType} />
          </div>
          <div className="form">
            <div>
              <p>
                Niestety, nie masz odpowiednich uprawnień, aby móc zagłosować.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SchoolDetailsVoteCloudErrorStage;
