import * as React from "react";
import { IEmbedSchoolState } from "./IEmbedSchoolState";
import { IEmbedSchoolProps } from "./IEmbedSchoolProps";
import "./EmbedSchool.scss";
import { Button } from "@material-ui/core";
import { shuffle } from "../../../utils/utils";

type Comment = {
  author: string;
  type: -1 | 1;
  created_at: number;
  text: string;
};

class EmbedSchool extends React.Component<
  IEmbedSchoolProps,
  IEmbedSchoolState
> {
  constructor(props: IEmbedSchoolProps) {
    super(props);
    this.state = {};
  }

  public getComments = (schoolDetails: School.SchoolDetails | undefined) => {
    if (!schoolDetails) return [];
    const { voteBriefs, googleReviews } = schoolDetails;
    const voteComments: Comment[] = voteBriefs
      .filter((vote) => vote.comment)
      .filter((vote) => vote.type === 1)
      .map((vote) => ({
        author: vote.firstName || "Anonim",
        type: vote.type,
        created_at: new Date(vote.created_at).getTime(),
        text: vote.comment as string,
      }));

    const googleReviewsComments: Comment[] = googleReviews
      .filter((review) => review.text)
      .filter((review) => review.rate >= 3)
      .map((review) => ({
        author: review.author,
        type: 1,
        created_at: review.date,
        text: review.text as string,
      }));
    return shuffle([...voteComments, ...googleReviewsComments]).slice(0, 3);
  };

  public handleVoteButtonClick = () => {
    const { onVote } = this.props;
    onVote();
  };

  public render(): JSX.Element {
    const { schoolDetails, sizeType } = this.props;
    return (
      <div className={`EmbedSchool`}>
        <div className="school-top">
          <div
            className="school-logo"
            style={{
              backgroundColor: schoolDetails?.backgroundColorTheme,
              backgroundImage: `url(${schoolDetails?.logoPhotoUrl})`,
            }}
          >
            {schoolDetails && !schoolDetails.logoPhotoUrl
              ? schoolDetails.initials
              : ""}
          </div>
          <div className="school-label">
            <h1 className="school-name">{schoolDetails?.name}</h1>

            <h3 className="school-city">{schoolDetails?.city}</h3>
          </div>
        </div>

        <div className="school-bottom">
          <div className="school-stats">
            <div className="stat">
              <div className="left-stat">
                <i className="icon-award"></i>
              </div>
              <div className="right-stat">
                <span className="value">{schoolDetails?.place || 0}.</span>
                <span>miejsce</span>
              </div>
            </div>
            <div className="stat">
              <div className="left-stat">
                <i className="icon-crown"></i>
              </div>
              <div className="right-stat">
                <span className="value">{schoolDetails?.score}</span>
                <span>punkty</span>
              </div>
            </div>
            <div className="stat">
              <div className="left-stat">
                <i className="icon-star"></i>
              </div>
              <div className="right-stat">
                <span className="value" style={{ fontSize: "10px" }}>
                  {schoolDetails?.gradesAvg} / 5.0
                </span>
                <span>ocena</span>
              </div>
            </div>
          </div>
          <div className="buttons">
            <Button
              className="embed-button"
              onClick={this.handleVoteButtonClick}
            >
              <i className="icon-thumbs-up"></i>
              <span>Zagłosuj</span>
            </Button>
            <Button className="embed-button">
              <i className="icon-search"></i>
              <span>Zobacz więcej...</span>
            </Button>
          </div>
          {sizeType === "large" && (
            <div className="comments-previews">
              {this.getComments(schoolDetails).map((comment, i) => (
                <div
                  key={i}
                  className="comment"
                  style={{ marginBottom: "5px" }}
                >
                  <div className="author">{comment.author}</div>
                  <div className="content">
                    <span>{comment.text}</span>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default EmbedSchool;
