import * as React from "react";
import { IScrollTopState } from "./IScrollTopState";
import { IScrollTopProps } from "./IScrollTopProps";
import "./ScrollTop.scss";
import { withRouter } from "react-router";

class ScrollToTop extends React.Component<IScrollTopProps, IScrollTopState> {
  componentDidUpdate(prevProps: IScrollTopProps) {
    const { pagesRef } = this.props;
    if (this.props.location.pathname !== prevProps.location.pathname) {
      pagesRef.current?.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
