import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";
import { VerifyEmailOperationPayload } from "../user/users.operations";
import {
  ResetAddSchoolFormActionPayload,
  ResetAddVoteFormActionPayload,
  ResetEmailVerificationFormActionPayload,
  SetAddSchoolFormActionPayload,
  SetAddVoteAuthActionPayload,
  SetAddVoteFormActionPayload,
  SetAddVoteFormCommentErrorActionPayload,
  SetAddVoteFormEmailAddressErrorActionPayload,
  SetAddVoteFormFirstNameErrorActionPayload,
  SetAddVoteFormOtherErrorActionPayload,
  SetEmailVerificationFormActionPayload,
  SetEmailVerificationFormCodeErrorActionPayload,
  SetEmailVerificationFormOtherErrorActionPayload,
  SetIsAcceptedCookieActionPayload,
  SetIsIframeWindowClosedActionPayload,
  SetIsSchoolDetailsMinimalizeActionPayload,
  SetIsShareWindowClosedActionPayload,
  SetIsVerifiedByCaptchaActionPayload,
  SetLastEmailResendTimestampActionPayload,
  SetProvinceTypeFilterActionPayload,
  SetRangeOfSchoolsDisplayedPayload,
  SetSchoolNameFilterActionPayload,
  SetScrollHeightPayload,
  SetScrollTopPayload,
  SetSelectedSectionTypeActionPayload,
  SetSelectedVoteMethodTypeActionPayload,
  SetSelectedVoteMethodValueActionPayload,
  SetSelectedVoteTypeActionPayload,
  SetWindowSizePayload,
  UnsetProvinceTypeFilterActionPayload,
  UnsetSchoolNameFilterActionPayload,
} from "./ui.actions";

export type UIState = {
  isAcceptedCookie: boolean;
  isVerifiedByCaptcha: boolean;
  selectedVoteType: System.VoteType | undefined;
  selectedVoteMethodType: System.VoteMethodType | undefined;
  selectedVoteMethodValue: string | undefined;
  provinceTypeFilter: System.ProvinceType | undefined;
  schoolNameFilter: string | undefined;
  rangeOfSchoolsDisplayed: [number, number];
  windowWidth: number;
  windowHeight: number;
  scrollTop: number;
  scrollHeight: number;
  isSchoolDetailsMinimalize: boolean;
  selectedSectionType: App.SchoolDetailsSectionType;
  lastEmailResendTimestamp: number | undefined;
  addVoteAuth: App.VoteAuth;
  addSchoolForm: App.SchoolForm;
  addVoteForm: App.VoteForm;
  emailVerificationForm: App.EmailVerificationForm;
  isShareWindowClosed: boolean;
  isIframeWindowClosed: boolean;
};

const initialState: UIState = {
  isAcceptedCookie: false,
  isVerifiedByCaptcha: false,
  selectedVoteType: undefined,
  selectedVoteMethodType: undefined,
  selectedVoteMethodValue: undefined,
  provinceTypeFilter: undefined,
  schoolNameFilter: undefined,
  rangeOfSchoolsDisplayed: [0, 50],
  windowWidth: 0,
  windowHeight: 0,
  scrollTop: 0,
  scrollHeight: 1,
  isSchoolDetailsMinimalize: true,
  selectedSectionType: "basic-information",
  lastEmailResendTimestamp: undefined,
  addSchoolForm: {
    name: undefined,
    nameError: undefined,
    city: undefined,
    cityError: undefined,
    provinceType: undefined,
    provinceTypeError: undefined,
    schoolEmail: undefined,
    schoolEmailError: undefined,
    schoolPhone: undefined,
    schoolPhoneError: undefined,
    schoolPageUrl: undefined,
    schoolPageUrlError: undefined,
    userEmailAddress: undefined,
    userEmailAddressError: undefined,
  },
  addVoteAuth: {
    otherError: undefined,
  },
  addVoteForm: {
    emailAddress: undefined,
    emailAddressError: undefined,
    firstName: undefined,
    firstNameError: undefined,
    comment: undefined,
    commentError: undefined,
    otherError: undefined,
  },
  emailVerificationForm: {
    code: undefined,
    codeError: undefined,
    otherError: undefined,
  },
  isShareWindowClosed: true,
  isIframeWindowClosed: true,
};

export const UISlice: Slice<UIState> = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setIsAcceptedCookie(
      state,
      { payload }: PayloadAction<SetIsAcceptedCookieActionPayload>
    ) {
      state.isAcceptedCookie = payload.isAcceptedCookie;
    },
    setIsVerifiedByCaptcha(
      state,
      { payload }: PayloadAction<SetIsVerifiedByCaptchaActionPayload>
    ) {
      state.isVerifiedByCaptcha = payload.isVerifiedByCaptcha;
    },

    setSelectedVoteType(
      state,
      { payload }: PayloadAction<SetSelectedVoteTypeActionPayload>
    ) {
      state.selectedVoteType = payload.selectedVoteType;
    },

    setSelectedVoteMethodType(
      state,
      { payload }: PayloadAction<SetSelectedVoteMethodTypeActionPayload>
    ) {
      state.selectedVoteMethodType = payload.selectedVoteMethodType;
    },

    setSelectedVoteMethodValue(
      state,
      { payload }: PayloadAction<SetSelectedVoteMethodValueActionPayload>
    ) {
      state.selectedVoteMethodValue = payload.selectedVoteMethodValue;
    },

    setProvinceTypeFilter(
      state,
      { payload }: PayloadAction<SetProvinceTypeFilterActionPayload>
    ) {
      state.provinceTypeFilter = payload.provinceType;
    },

    unsetProvinceTypeFilter(
      state,
      { payload }: PayloadAction<UnsetProvinceTypeFilterActionPayload>
    ) {
      state.provinceTypeFilter = undefined;
    },

    setSchoolNameFilter(
      state,
      { payload }: PayloadAction<SetSchoolNameFilterActionPayload>
    ) {
      state.schoolNameFilter = payload.schoolName;
    },

    setRangeOfSchoolsDisplayed(
      state,
      { payload }: PayloadAction<SetRangeOfSchoolsDisplayedPayload>
    ) {
      state.rangeOfSchoolsDisplayed = payload.range;
    },

    unsetSchoolNameFilter(
      state,
      { payload }: PayloadAction<UnsetSchoolNameFilterActionPayload>
    ) {
      state.schoolNameFilter = undefined;
    },

    setScrollTop(state, { payload }: PayloadAction<SetScrollTopPayload>) {
      state.scrollTop = payload.scrollTop;
    },

    setScrollHeight(state, { payload }: PayloadAction<SetScrollHeightPayload>) {
      state.scrollHeight = payload.scrollHeight;
    },

    setWindowSize(state, { payload }: PayloadAction<SetWindowSizePayload>) {
      state.windowWidth = payload.windowWidth;
      state.windowHeight = payload.windowHeight;
    },

    setIsSchoolDetailsMinimalize(
      state,
      { payload }: PayloadAction<SetIsSchoolDetailsMinimalizeActionPayload>
    ) {
      state.isSchoolDetailsMinimalize = payload.isSchoolDetailsMinimalize;
    },

    setSelectedSectionType(
      state,
      { payload }: PayloadAction<SetSelectedSectionTypeActionPayload>
    ) {
      state.selectedSectionType = payload.selectedSectionType;
    },

    setLastEmailResendTimestamp(
      state,
      { payload }: PayloadAction<SetLastEmailResendTimestampActionPayload>
    ) {
      state.lastEmailResendTimestamp = payload.lastEmailResendTimestamp;
    },

    setAddVoteAuth(
      state,
      { payload }: PayloadAction<SetAddVoteAuthActionPayload>
    ) {
      state.addVoteAuth = { ...state.addVoteAuth, ...payload };
    },

    setAddSchoolForm(
      state,
      { payload }: PayloadAction<SetAddSchoolFormActionPayload>
    ) {
      state.addSchoolForm = payload.addSchoolForm;
    },

    resetAddSchoolForm(
      state,
      { payload }: PayloadAction<ResetAddSchoolFormActionPayload>
    ) {
      state.addSchoolForm = {
        name: undefined,
        nameError: undefined,
        city: undefined,
        cityError: undefined,
        provinceType: undefined,
        provinceTypeError: undefined,
        schoolEmail: undefined,
        schoolEmailError: undefined,
        schoolPhone: undefined,
        schoolPhoneError: undefined,
        schoolPageUrl: undefined,
        schoolPageUrlError: undefined,
        userEmailAddress: undefined,
        userEmailAddressError: undefined,
      };
    },

    setAddVoteForm(
      state,
      { payload }: PayloadAction<SetAddVoteFormActionPayload>
    ) {
      state.addVoteForm = payload.addVoteForm;
    },

    resetAddVoteForm(
      state,
      { payload }: PayloadAction<ResetAddVoteFormActionPayload>
    ) {
      state.addVoteForm = {
        emailAddress: undefined,
        emailAddressError: undefined,
        firstName: undefined,
        firstNameError: undefined,
        comment: undefined,
        commentError: undefined,
        otherError: undefined,
      };
    },

    setAddVoteFormEmailAddressError(
      state,
      { payload }: PayloadAction<SetAddVoteFormEmailAddressErrorActionPayload>
    ) {
      state.addVoteForm.emailAddressError = payload.emailAddressError;
    },

    setAddVoteFormFirstNameError(
      state,
      { payload }: PayloadAction<SetAddVoteFormFirstNameErrorActionPayload>
    ) {
      state.addVoteForm.firstNameError = payload.firstNameError;
    },

    setAddVoteFormCommentError(
      state,
      { payload }: PayloadAction<SetAddVoteFormCommentErrorActionPayload>
    ) {
      state.addVoteForm.commentError = payload.commentError;
    },

    setAddVoteFormOtherError(
      state,
      { payload }: PayloadAction<SetAddVoteFormOtherErrorActionPayload>
    ) {
      state.addVoteForm.otherError = payload.otherError;
    },

    setEmailVerificationForm(
      state,
      { payload }: PayloadAction<SetEmailVerificationFormActionPayload>
    ) {
      state.emailVerificationForm = payload.emailVerificationForm;
    },

    resetEmailVerificationForm(
      state,
      { payload }: PayloadAction<ResetEmailVerificationFormActionPayload>
    ) {
      state.emailVerificationForm = {
        code: undefined,
        codeError: undefined,
        otherError: undefined,
      };
    },

    setEmailVerificationFormCodeError(
      state,
      { payload }: PayloadAction<SetEmailVerificationFormCodeErrorActionPayload>
    ) {
      state.emailVerificationForm.codeError = payload.codeError;
    },

    setEmailVerificationFormOtherError(
      state,
      {
        payload,
      }: PayloadAction<SetEmailVerificationFormOtherErrorActionPayload>
    ) {
      state.emailVerificationForm.otherError = payload.otherError;
    },

    setIsShareWindowClosed(
      state,
      { payload }: PayloadAction<SetIsShareWindowClosedActionPayload>
    ) {
      state.isShareWindowClosed = payload.isClosed;
    },

    setIsIframeWindowClosed(
      state,
      { payload }: PayloadAction<SetIsIframeWindowClosedActionPayload>
    ) {
      state.isIframeWindowClosed = payload.isClosed;
    },
  },
});

export default UISlice.reducer;
