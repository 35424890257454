import * as React from "react";
import { IIFramePreviewWindowState } from "./IIFramePreviewWindowState";
import {
  IIFramePreviewWindowDispatchProps,
  IIFramePreviewWindowProps,
  IIFramePreviewWindowStateProps,
} from "./IIFramePreviewWindowProps";
import "./IFramePreviewWindow.scss";
import Window from "../../shared/Window/Window";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { AppDispatch, AppState } from "../../../store/store";
import { connect } from "react-redux";
import { getIsIframeWindowClosed } from "../../../store/@ui/ui.selectors";
import { setIsIframeWindowClosedAction } from "../../../store/@ui/ui.actions";
import { getSelectedSchoolDetails } from "../../../store/school/school.selectors";
import { EmbedSizeType, EmbedThemeType, sizes } from "../../page/Embed/Embed";

const mapStateToProps = (state: AppState): IIFramePreviewWindowStateProps => ({
  isIframeWindowClosed: getIsIframeWindowClosed(state),
  selectedSchoolDetails: getSelectedSchoolDetails(state),
});

const mapDispatchToProps = (
  dispatch: AppDispatch
): IIFramePreviewWindowDispatchProps => ({
  onIframeWindowClosed: () => {
    dispatch(setIsIframeWindowClosedAction({ isClosed: true }));
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);

class IFramePreviewWindow extends React.Component<
  IIFramePreviewWindowProps,
  IIFramePreviewWindowState
> {
  constructor(props: IIFramePreviewWindowProps) {
    super(props);
    this.state = {
      sizeType: "medium",
      themeType: "light",
    };
  }

  public handleWindowClose = () => {
    const { onIframeWindowClosed } = this.props;
    onIframeWindowClosed();
  };

  public handleThemeTypeRadioButtonChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    const themeType = value as EmbedThemeType;
    this.setState({ themeType });
  };

  public handleSizeTypeRadioButtonChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    const sizeType = value as EmbedSizeType;
    this.setState({ sizeType });
  };

  public render(): JSX.Element {
    const { isIframeWindowClosed, selectedSchoolDetails } = this.props;
    const { sizeType, themeType } = this.state;
    const size = sizes[sizeType];
    return (
      <Window
        className={`iFramePreviewWindow`}
        isClosed={isIframeWindowClosed}
        onClose={this.handleWindowClose}
      >
        <div>
          <h3>Wstaw wizytówkę szkoły</h3>
          <div className="content">
            <div className="preview-and-options">
              {selectedSchoolDetails && (
                <iframe
                  src={`http://szkolytechnologiczne.pl/embed/${selectedSchoolDetails._id}?theme=${themeType}`}
                  frameBorder="0"
                  title={"Szkoły Technologiczne"}
                  allow="geolocation"
                  width={size.width}
                  height={size.height}
                />
              )}
              <div className="options">
                <FormControl component="fieldset">
                  <FormLabel component="legend">Wybierz motyw</FormLabel>
                  <RadioGroup
                    aria-label="theme"
                    name="theme"
                    value={themeType}
                    onChange={this.handleThemeTypeRadioButtonChange}
                  >
                    <FormControlLabel
                      value="light"
                      control={<Radio />}
                      label="Jasny"
                    />
                    <FormControlLabel
                      value="gray"
                      control={<Radio />}
                      label="Szary"
                    />
                    <FormControlLabel
                      value="dark"
                      control={<Radio />}
                      label="Ciemny"
                    />
                  </RadioGroup>
                </FormControl>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Wybierz rozmiar</FormLabel>
                  <RadioGroup
                    aria-label="size"
                    name="size"
                    value={sizeType}
                    onChange={this.handleSizeTypeRadioButtonChange}
                  >
                    <FormControlLabel
                      value="small"
                      control={<Radio />}
                      label="Mały"
                    />
                    <FormControlLabel
                      value="medium"
                      control={<Radio />}
                      label="Średni"
                    />
                    <FormControlLabel
                      value="large"
                      control={<Radio />}
                      label="Duży"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <div className="code-wrapper">
              <code>{`<iframe
                title="Szkoły Technologiczne"
                width="${size.width}"
                height="${size.height}" src="https://szkolytechnologiczne.pl/embed/${selectedSchoolDetails?._id}?theme=${themeType}" frameborder="0"></iframe>`}</code>
            </div>
          </div>
        </div>
      </Window>
    );
  }
}

export default connector(IFramePreviewWindow);
