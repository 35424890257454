import * as React from "react";
import { IQueryAnalyzerState } from "./IQueryAnalyzerState";
import {
  IQueryAnalyzerProps,
  QueryAnalyzerSearch,
} from "./IQueryAnalyzerProps";
import { Route, Switch, withRouter } from "react-router";
import qs from "query-string";
import visitsService from "../../store/visits/visits.service";
import { JoiObjectId } from "../../models";
import Joi from "joi";
import { decodeSchoolUrl } from "../../utils/utils";

export const JoiVisitSchema = {
  _id: JoiObjectId.required(),
  userId: JoiObjectId.required(),
  schoolId: JoiObjectId.optional(),
  from: Joi.string().valid(
    "invite",
    "facebook",
    "twitter",
    "linkedin",
    "whatsapp",
    "iframe"
  ),
  fromDetails: Joi.string().min(3).max(255).alphanum().optional(),
};

class QueryAnalyzer extends React.Component<
  IQueryAnalyzerProps,
  IQueryAnalyzerState
> {
  constructor(props: IQueryAnalyzerProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.analyze();
  }

  public isValidFromSearch = (from: any): boolean => {
    return !JoiVisitSchema.from.validate(from).error;
  };

  public isValidFromDetailsSearch = (fromDetails: any): boolean => {
    return !JoiVisitSchema.fromDetails.validate(fromDetails).error;
  };

  public async analyze() {
    const { match, location, history } = this.props;
    const { pathname, search } = location;
    const { isValidFromDetailsSearch, isValidFromSearch } = this;

    const searchObj: QueryAnalyzerSearch = qs.parse(search);
    let { from, from_details } = searchObj;
    const { schoolId } = match.params;

    from = isValidFromSearch(from) && from;
    from_details = isValidFromDetailsSearch(from_details) && from_details;

    console.log(from);
    await visitsService.postVisit(
      {},
      {
        schoolFullName: schoolId && decodeSchoolUrl(schoolId),
        from,
        fromDetails: from_details,
      },
      {}
    );
    this.clearQuery(searchObj);
  }

  public clearQuery(searchObj: QueryAnalyzerSearch) {
    const { location, history } = this.props;
    const { pathname } = location;
    history.push({
      pathname,
      search: qs.stringify({
        ...searchObj,
        from: undefined,
        from_details: undefined,
      }),
    });
  }

  public render(): JSX.Element {
    return <></>;
  }
}

export default withRouter(QueryAnalyzer);
