import * as React from "react";
import { ICookieAlertState } from "./ICookieAlertState";
import {
  ICookieAlertDispatchProps,
  ICookieAlertProps,
  ICookieAlertStateProps,
} from "./ICookieAlertProps";
import "./CookieAlert.scss";
import { getIsAcceptedCookie } from "../../../store/@ui/ui.selectors";
import { AppDispatch, AppState } from "../../../store/store";
import { setIsAcceptedCookieAction } from "../../../store/@ui/ui.actions";
import { connect } from "react-redux";
import { withRouter } from "react-router";

const mapStateToProps = (state: AppState): ICookieAlertStateProps => ({
  isAcceptedCookie: getIsAcceptedCookie(state),
});

const mapDispatchToProps = (
  dispatch: AppDispatch
): ICookieAlertDispatchProps => ({
  onAcceptCookie: () => {
    dispatch(setIsAcceptedCookieAction({ isAcceptedCookie: true }));
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);

class CookieAlert extends React.Component<
  ICookieAlertProps,
  ICookieAlertState
> {
  constructor(props: ICookieAlertProps) {
    super(props);
    this.state = {};
  }

  public handleConfirmButtonClick = () => {
    const { onAcceptCookie } = this.props;
    onAcceptCookie && onAcceptCookie();
  };

  public handlePrivacyPolicyButtonClick = () => {
    const {} = this.props;

    this.props.history.push("/rules?section=privacy-policy");
  };

  public render(): JSX.Element {
    const { isAcceptedCookie } = this.props;
    return (
      <>
        {!isAcceptedCookie && (
          <div className={`CookieAlert`}>
            <span>
              Ta strona używa plików cookies. Korzystając z aplikacji
              automatycznie akceptujesz politykę prywatności.
            </span>
            <div>
              <button onClick={this.handlePrivacyPolicyButtonClick}>
                Polityka prywatności
              </button>
              <button className="light" onClick={this.handleConfirmButtonClick}>
                Okej!
              </button>
            </div>

            <button className="close" onClick={this.handleConfirmButtonClick}>
              <i className="icon-cancel"></i>
            </button>
          </div>
        )}
      </>
    );
  }
}

export default withRouter(connector(CookieAlert));
