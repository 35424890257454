import * as React from "react";
import { ISchoolDetailsUserCommentState } from "./ISchoolDetailsUserCommentState";
import { ISchoolDetailsUserCommentProps } from "./ISchoolDetailsUserCommentProps";
import "./SchoolDetailsUserComment.scss";
import Moment from "react-moment";
import "moment/locale/pl";
import Skeleton from "react-loading-skeleton";

class SchoolDetailsUserComment extends React.Component<
  ISchoolDetailsUserCommentProps,
  ISchoolDetailsUserCommentState
> {
  constructor(props: ISchoolDetailsUserCommentProps) {
    super(props);
    this.state = {};
  }

  public getVoteIcon = (
    voteType: System.VoteType | undefined
  ): string | undefined => {
    if (!voteType) return undefined;
    return voteType === 1 ? "icon-thumbs-up-alt" : "icon-thumbs-down-alt";
  };

  public getName = (): string | undefined => {
    const { voteBrief } = this.props;
    if (!voteBrief) return undefined;
    return voteBrief.firstName || "Anonim";
  };

  public render(): JSX.Element {
    const { voteBrief } = this.props;
    return (
      <div className={`schoolDetailsUserComment`}>
        <div className="comment-content">
          <div className="name-and-date">
            <span className="name">
              {this.getName() || <Skeleton width={120} height={10} />}
            </span>
            <span className="date">
              {voteBrief ? (
                <Moment locale="pl" fromNow={true}>
                  {voteBrief.created_at}
                </Moment>
              ) : (
                <Skeleton width={45} />
              )}
            </span>
          </div>
          <div className="comment">
            <span>{voteBrief?.comment || <Skeleton height={30} />}</span>
          </div>
        </div>
        {voteBrief ? (
          <div
            className={`comment-icon ${voteBrief.type === 1 ? "up" : "down"}`}
          >
            <i className={`icon ${this.getVoteIcon(voteBrief?.type)}`}></i>
          </div>
        ) : (
          <Skeleton className={`comment-icon`} width={25} height={25} />
        )}
      </div>
    );
  }
}

export default SchoolDetailsUserComment;
