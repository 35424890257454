import * as React from "react";
import { ISchoolDetailsGoogleReviewsState } from "./ISchoolDetailsGoogleReviewsState";
import { ISchoolDetailsGoogleReviewsProps } from "./ISchoolDetailsGoogleReviewsProps";
import "./SchoolDetailsGoogleReviews.scss";
import SchoolDetailsGoogleReview from "../SchoolDetailsGoogleReview/SchoolDetailsGoogleReview";

class SchoolDetailsGoogleReviews extends React.Component<
  ISchoolDetailsGoogleReviewsProps,
  ISchoolDetailsGoogleReviewsState
> {
  constructor(props: ISchoolDetailsGoogleReviewsProps) {
    super(props);
    this.state = {};
  }

  public render(): JSX.Element {
    const { googleReviews } = this.props;
    return (
      <div className={`SchoolDetailsGoogleReviews`}>
        {googleReviews?.map((review, i) => (
          <SchoolDetailsGoogleReview key={i} googleReview={review} />
        ))}
      </div>
    );
  }
}

export default SchoolDetailsGoogleReviews;
