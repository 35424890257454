import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";

export type GradeState = {
  apiGrades: Array<Api.Grade> | undefined;
};

const initialState: GradeState = {
  apiGrades: undefined,
};

const gradeSlice: Slice<GradeState> = createSlice({
  name: "grade",
  initialState,
  reducers: {
    setGrades(state, { payload }: PayloadAction<{ grades: Api.Grade[] }>) {
      state.apiGrades = payload.grades;
    },
    unsetGrades(state, { payload }: PayloadAction<{}>) {
      state.apiGrades = undefined;
    },
    addGrade(state, { payload }: PayloadAction<{ newGrade: Api.Grade }>) {
      if (!state.apiGrades) state.apiGrades = [];
      state.apiGrades.push(payload.newGrade);
    },
    updateGrade(
      state,
      { payload }: PayloadAction<{ gradeId: string; newGrade: Api.Grade }>
    ) {
      if (!state.apiGrades) return;
      const gradeIndex: number = state.apiGrades.findIndex(
        (apiGrade) => apiGrade._id === payload.gradeId
      );
      if (gradeIndex === -1) return;
      state.apiGrades[gradeIndex] = payload.newGrade;
    },
  },
});

export const {
  setGrades,
  unsetGrades,
  addGrade,
  updateGrade,
} = gradeSlice.actions;

export default gradeSlice.reducer;
