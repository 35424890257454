import * as React from "react";
import { IAppLoaderState } from "./IAppLoaderState";
import {
  IAppLoaderProps,
  IAppLoaderStateProps,
  IAppLoaderDispatchProps,
} from "./IAppLoaderProps";
import "./AppLoader.scss";
import Logo from "../Logo/Logo";
import { connect } from "react-redux";
import { AppDispatch, AppState } from "../../../store/store";
import { fetchCurrentUserOperation } from "../../../store/user/users.operations";
import { setIsShareWindowClosedAction } from "../../../store/@ui/ui.actions";
import {
  setCreateMySchoolBodyAction,
  setGetSchoolDetailsBodyAction,
  setGetSchoolPreviewsBodyAction,
} from "../../../store/school/schools.actions";
import {
  setCreateCurrentVoteBodyAction,
  setDeleteCurrentVoteBodyAction,
} from "../../../store/vote/vote.actions";
import {
  setCreateCurrentUserEmailBodyAction,
  setCreateCurrentUserFacebookAccountBodyAction,
  setGetCurrentUserBodyAction,
  setPatchCurrentUserBodyAction,
  setVerifyCodeBodyAction,
  setVerifyCurrentUserBodyAction,
} from "../../../store/user/users.actions";
import QueryAnalyzer from "../../QueryAnalyzer/QueryAnalyzer";
import { Route, Switch } from "react-router";

const mapStateToProps = (appState: AppState): IAppLoaderStateProps => ({});

const mapDispatchToProps = (
  dispatch: AppDispatch
): IAppLoaderDispatchProps => ({
  onFetchCurrentUser: () => {
    dispatch(fetchCurrentUserOperation({}));
  },
  onCloseShareWindow: () => {
    dispatch(setIsShareWindowClosedAction({ isClosed: true }));
  },
  onResetResponses: () => {
    const status = "empty";
    dispatch(setGetCurrentUserBodyAction({ status }));
    dispatch(setPatchCurrentUserBodyAction({ status }));
    dispatch(setCreateCurrentUserEmailBodyAction({ status }));
    dispatch(setCreateCurrentUserFacebookAccountBodyAction({ status }));
    dispatch(setVerifyCodeBodyAction({ status }));
    dispatch(setVerifyCurrentUserBodyAction({ status }));
    dispatch(setGetSchoolDetailsBodyAction({ status }));
    dispatch(setGetSchoolPreviewsBodyAction({ status }));
    dispatch(setCreateMySchoolBodyAction({ status }));
    dispatch(setCreateCurrentVoteBodyAction({ status }));
    dispatch(setDeleteCurrentVoteBodyAction({ status }));
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);

class AppLoader extends React.Component<IAppLoaderProps, IAppLoaderState> {
  timeout: NodeJS.Timeout | undefined;
  constructor(props: IAppLoaderProps) {
    super(props);

    this.state = {
      isLoaded: false,
    };
  }

  public init() {
    const {
      onResetResponses,
      onFetchCurrentUser,
      onCloseShareWindow,
    } = this.props;
    onResetResponses();
    onFetchCurrentUser();
    onCloseShareWindow();
  }

  componentDidMount() {
    this.init();
    this.timeout = setTimeout(() => {
      this.load();
    }, 3500);
  }

  componentWillUnmount() {
    this.timeout && clearTimeout(this.timeout);
  }

  load = () => {
    this.setState({
      isLoaded: true,
    });
  };

  public render(): JSX.Element {
    const { children } = this.props;
    const { isLoaded } = this.state;

    return (
      <>
        {!isLoaded ? (
          <div className="app-loader" style={{ height: window.innerHeight }}>
            <Switch>
              <Route path="/ranking/:schoolId">
                <QueryAnalyzer />
              </Route>
              <Route path="/">
                <QueryAnalyzer />
              </Route>
            </Switch>
            <div className="progress-wrapper">
              <Logo />
              <div className="bar">
                <div className="inside"></div>
              </div>
              <div className="bottom">
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((ball) => (
                  <div key={ball} className="ball-wrapper">
                    <div className={`ball _${ball}`}>
                      <div className="ball-border"></div>
                      <div className="ball-shell"></div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          children
        )}
      </>
    );
  }
}

export default connector(AppLoader);
