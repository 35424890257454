import * as React from "react";
import { IAddSchoolFormState } from "./IAddSchoolFormState";
import {
  IAddSchoolFormDispatchProps,
  IAddSchoolFormProps,
  IAddSchoolFormStateProps,
} from "./IAddSchoolFormProps";
import "./AddSchoolForm.scss";
import Bubble from "../../shared/Bubble/Bubble";
import { Button, TextField } from "@material-ui/core";
import provinces from "../../../assets/data/provinces.data.json";
import { getProvincesTypes } from "../../../helpers/helpers";
import { JoiSchoolSchema, JoiUserEmailSchema } from "../../../models";
import Joi from "joi";
import { AppDispatch, AppState } from "../../../store/store";

import { getCurrentUser } from "../../../store/user/users.selectors";
import {
  getCreateMySchoolBody,
  getSchoolPreviews,
} from "../../../store/school/school.selectors";
import schoolsOperations from "../../../store/school/schools.operations";
import { connect } from "react-redux";
import Countdown from "react-countdown";
import IconPending from "../../shared/IconPending/IconPending";
import {
  resetAddSchoolFormAction,
  setAddSchoolFormAction,
} from "../../../store/@ui/ui.actions";
import { getAddSchoolForm } from "../../../store/@ui/ui.selectors";
import Select from "../../shared/Select/Select";

const mapStateToProps = (appState: AppState): IAddSchoolFormStateProps => ({
  currentUser: getCurrentUser(appState),
  schoolPreviews: getSchoolPreviews(appState),
  createMySchoolBody: getCreateMySchoolBody(appState),
  addSchoolForm: getAddSchoolForm(appState),
});

const mapDispatchToProps = (
  dispatch: AppDispatch
): IAddSchoolFormDispatchProps => ({
  onCreateMySchool: (event) => {
    dispatch(schoolsOperations.createMySchoolOperation(event));
  },
  onUpdateAddSchoolForm: (event) => {
    dispatch(setAddSchoolFormAction(event));
  },
  onResetAddSchoolForm: () => {
    dispatch(resetAddSchoolFormAction({}));
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);

class AddSchoolForm extends React.Component<
  IAddSchoolFormProps,
  IAddSchoolFormState
> {
  constructor(props: IAddSchoolFormProps) {
    super(props);
    this.state = {};
  }

  componentWillUnmount() {
    const { onResetAddSchoolForm } = this.props;
    onResetAddSchoolForm();
  }

  public isPending = (): boolean => {
    const { createMySchoolBody } = this.props;
    return createMySchoolBody.status === "pending";
  };

  public isCurrentUserCanAdd = (remainingTimeToCanSend: number): boolean => {
    if (remainingTimeToCanSend > 0) return false;
    return true;
  };

  public getValue = (value: string | undefined): string | undefined =>
    value && value.length ? value : undefined;

  public handleSchoolNameInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { onUpdateAddSchoolForm, addSchoolForm } = this.props;
    const name: string = event.target.value;

    onUpdateAddSchoolForm({
      addSchoolForm: {
        ...addSchoolForm,
        name: this.getValue(name),
      },
    });
  };

  public handleSchoolCityInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { onUpdateAddSchoolForm, addSchoolForm } = this.props;
    const city: string = event.target.value;

    onUpdateAddSchoolForm({
      addSchoolForm: {
        ...addSchoolForm,
        city: this.getValue(city),
      },
    });
  };

  public handleSchoolProvinceTypeInputChange = (event: {
    value: string | undefined;
  }) => {
    const { onUpdateAddSchoolForm, addSchoolForm } = this.props;
    const provinceType: string | undefined = event.value;
    const validation: Joi.ValidationResult = this.validateSchoolProvinceType(
      provinceType
    );

    onUpdateAddSchoolForm({
      addSchoolForm: {
        ...addSchoolForm,
        provinceType: this.getValue(provinceType) as System.ProvinceType,
        provinceTypeError: validation.error?.details[0].message,
      },
    });
  };

  public handleSchoolEmailInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { onUpdateAddSchoolForm, addSchoolForm } = this.props;
    const schoolEmail: string = event.target.value;

    onUpdateAddSchoolForm({
      addSchoolForm: {
        ...addSchoolForm,
        schoolEmail: this.getValue(schoolEmail),
      },
    });
  };

  public handleSchoolPhoneInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { onUpdateAddSchoolForm, addSchoolForm } = this.props;
    const schoolPhone: string = event.target.value;

    onUpdateAddSchoolForm({
      addSchoolForm: {
        ...addSchoolForm,
        schoolPhone: this.getValue(schoolPhone),
      },
    });
  };

  public handleSchoolPageUrlInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { onUpdateAddSchoolForm, addSchoolForm } = this.props;
    const schoolPageUrl: string = event.target.value;

    onUpdateAddSchoolForm({
      addSchoolForm: {
        ...addSchoolForm,
        schoolPageUrl: this.getValue(schoolPageUrl),
      },
    });
  };

  public handleUserEmailAddressInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { onUpdateAddSchoolForm, addSchoolForm } = this.props;
    const userEmailAddress: string = event.target.value;

    onUpdateAddSchoolForm({
      addSchoolForm: {
        ...addSchoolForm,
        userEmailAddress: this.getValue(userEmailAddress),
      },
    });
  };

  public handleSchoolNameInputBlur = (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    const { onUpdateAddSchoolForm, addSchoolForm } = this.props;
    const { name } = addSchoolForm;
    const validation: Joi.ValidationResult = this.validateSchoolName(name);

    onUpdateAddSchoolForm({
      addSchoolForm: {
        ...addSchoolForm,
        nameError: validation.error?.details[0].message,
      },
    });
  };

  public handleSchoolCityInputBlur = (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    const { onUpdateAddSchoolForm, addSchoolForm } = this.props;
    const { city } = addSchoolForm;
    const validation: Joi.ValidationResult = this.validateSchoolCity(city);

    onUpdateAddSchoolForm({
      addSchoolForm: {
        ...addSchoolForm,
        cityError: validation.error?.details[0].message,
      },
    });
  };

  public handleSchoolProvinceTypeInputBlur = (
    event: React.FocusEvent<HTMLSelectElement>
  ) => {
    const { onUpdateAddSchoolForm, addSchoolForm } = this.props;
    const { provinceType } = addSchoolForm;
    const validation: Joi.ValidationResult = this.validateSchoolProvinceType(
      provinceType
    );

    onUpdateAddSchoolForm({
      addSchoolForm: {
        ...addSchoolForm,
        provinceTypeError: validation.error?.details[0].message,
      },
    });
  };

  public handleSchoolEmailInputBlur = (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    const { onUpdateAddSchoolForm, addSchoolForm } = this.props;
    const { schoolEmail } = addSchoolForm;
    const validation: Joi.ValidationResult = this.validateSchoolEmail(
      schoolEmail
    );
    onUpdateAddSchoolForm({
      addSchoolForm: {
        ...addSchoolForm,
        schoolEmailError: validation.error?.details[0].message,
      },
    });
  };

  public handleSchoolPhoneInputBlur = (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    const { onUpdateAddSchoolForm, addSchoolForm } = this.props;
    const { schoolPhone } = addSchoolForm;
    const validation: Joi.ValidationResult = this.validateSchoolPhone(
      schoolPhone
    );
    onUpdateAddSchoolForm({
      addSchoolForm: {
        ...addSchoolForm,
        schoolPhoneError: validation.error?.details[0].message,
      },
    });
  };

  public handleSchoolPageUrlInputBlur = (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    const { onUpdateAddSchoolForm, addSchoolForm } = this.props;
    const { schoolPageUrl } = addSchoolForm;
    const validation: Joi.ValidationResult = this.validateSchoolPageUrl(
      schoolPageUrl
    );
    onUpdateAddSchoolForm({
      addSchoolForm: {
        ...addSchoolForm,
        schoolPageUrlError: validation.error?.details[0].message,
      },
    });
  };

  public handleUserEmailAddressInputBlur = (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    const { onUpdateAddSchoolForm, addSchoolForm } = this.props;
    const { userEmailAddress } = addSchoolForm;
    const validation: Joi.ValidationResult = this.validateUserEmailAddress(
      userEmailAddress
    );

    onUpdateAddSchoolForm({
      addSchoolForm: {
        ...addSchoolForm,
        userEmailAddressError: validation.error?.details[0].message,
      },
    });
  };

  public validateSchoolName = (
    name: string | undefined
  ): Joi.ValidationResult => {
    return JoiSchoolSchema.name.validate(name);
  };

  public validateSchoolCity = (
    city: string | undefined
  ): Joi.ValidationResult => {
    return JoiSchoolSchema.city.validate(city);
  };

  public validateSchoolProvinceType = (
    provinceType: string | System.ProvinceType | undefined
  ): Joi.ValidationResult => {
    return JoiSchoolSchema.provinceType.validate(provinceType);
  };

  public validateSchoolEmail = (
    schoolEmail: string | undefined
  ): Joi.ValidationResult => {
    return JoiSchoolSchema.schoolEmail.validate(schoolEmail);
  };

  public validateSchoolPhone = (
    schoolPhone: string | undefined
  ): Joi.ValidationResult => {
    return JoiSchoolSchema.schoolPhone.validate(schoolPhone);
  };

  public validateSchoolPageUrl = (
    schoolPageUrl: string | undefined
  ): Joi.ValidationResult => {
    return JoiSchoolSchema.schoolPageUrl.validate(schoolPageUrl);
  };

  public validateUserEmailAddress = (
    userEmailAddress: string | undefined
  ): Joi.ValidationResult => {
    return JoiUserEmailSchema.address.validate(userEmailAddress);
  };

  public getLastAddedSchoolTimestamp = (): number | undefined => {
    const { currentUser } = this.props;
    if (!currentUser) return undefined;
    if (!currentUser.lastAddedSchool_at) return undefined;
    return new Date(currentUser.lastAddedSchool_at).getTime();
  };

  public isValidForm = (payload: {
    name: string | undefined;
    city: string | undefined;
    provinceType: string | undefined;
    schoolEmail: string | undefined;
    schoolPhone: string | undefined;
    schoolPageUrl: string | undefined;
    userEmailAddress: string | undefined;
  }): boolean => {
    const {
      validateSchoolName,
      validateSchoolCity,
      validateSchoolProvinceType,
      validateSchoolEmail,
      validateSchoolPhone,
      validateSchoolPageUrl,
      validateUserEmailAddress,
    } = this;
    const {
      name,
      city,
      provinceType,
      schoolEmail,
      schoolPhone,
      schoolPageUrl,
      userEmailAddress,
    } = payload;

    if (validateSchoolName(name).error) return false;
    if (validateSchoolCity(city).error) return false;
    if (validateSchoolProvinceType(provinceType).error) return false;
    if (validateSchoolEmail(schoolEmail).error) return false;
    if (validateSchoolPhone(schoolPhone).error) return false;
    if (validateSchoolPageUrl(schoolPageUrl).error) return false;
    if (validateUserEmailAddress(userEmailAddress).error) return false;

    return true;
  };

  public handleAddSchoolButtonClick = () => {
    const { onCreateMySchool } = this.props;
    const {
      name,
      city,
      provinceType,
      schoolEmail,
      schoolPhone,
      schoolPageUrl,
      userEmailAddress,
    } = this.props.addSchoolForm;
    if (!name) return;
    if (!city) return;
    if (!provinceType) return;
    if (!userEmailAddress) return;
    onCreateMySchool &&
      onCreateMySchool({
        name,
        city,
        provinceType,
        schoolEmail,
        schoolPhone,
        schoolPageUrl,
        userEmailAddress,
      });
  };

  public render(): JSX.Element {
    const { addSchoolForm } = this.props;
    const {
      name,
      city,
      provinceType,
      schoolEmail,
      schoolPhone,
      schoolPageUrl,
      userEmailAddress,
      nameError,
      cityError,
      provinceTypeError,
      schoolEmailError,
      schoolPageUrlError,
      schoolPhoneError,
      userEmailAddressError,
    } = addSchoolForm;
    const lastAddedSchoolTimestamp:
      | number
      | undefined = this.getLastAddedSchoolTimestamp();
    return (
      <div className={`addSchoolForm`}>
        <Countdown
          key={lastAddedSchoolTimestamp}
          date={
            lastAddedSchoolTimestamp ? lastAddedSchoolTimestamp + 3600000 : 1
          }
          autoStart={true}
          renderer={(props) => (
            <>
              <Bubble className={`required-form`} ready={true}>
                <div className="content">
                  <h3>
                    <i className="app-header-icon icon-attention-alt"></i>
                    Wymagane informacje o szkole
                  </h3>
                  <div className="input-wrapper">
                    <TextField
                      className="school-name-input"
                      type="value"
                      value={name}
                      disabled={this.isPending() || props.total > 0}
                      onChange={this.handleSchoolNameInputChange}
                      onBlur={this.handleSchoolNameInputBlur}
                      variant="outlined"
                      label="Nazwa szkoły"
                      required={true}
                      autoFocus={true}
                      error={Boolean(nameError)}
                      helperText={
                        nameError || `"Nazwa szkoły" jest wymaganym polem`
                      }
                    />
                  </div>
                  <div className="input-wrapper">
                    <TextField
                      className="school-city-input"
                      type="text"
                      value={city}
                      disabled={this.isPending() || props.total > 0}
                      onChange={this.handleSchoolCityInputChange}
                      onBlur={this.handleSchoolCityInputBlur}
                      variant="outlined"
                      label="Miasto"
                      required={true}
                      error={Boolean(cityError)}
                      helperText={cityError || `"Miasto" jest wymaganym polem`}
                    />
                  </div>
                  <div className="input-wrapper">
                    <Select
                      value={provinceType}
                      options={[
                        {
                          value: (undefined as unknown) as string,
                          text: "Wybierz województwo... *",
                        },
                      ].concat(
                        getProvincesTypes(provinces).map((provinceType) => ({
                          value: provinceType,
                          text: provinces[provinceType],
                        }))
                      )}
                      disabled={this.isPending() || props.total > 0}
                      onChange={this.handleSchoolProvinceTypeInputChange}
                      onBlur={this.handleSchoolProvinceTypeInputBlur}
                      helperText={
                        provinceTypeError ||
                        `"Województwo" jest wymaganym polem`
                      }
                      error={Boolean(provinceTypeError)}
                    ></Select>
                  </div>
                </div>
              </Bubble>
              <Bubble className={`additional-form`} ready={true}>
                <div className="content">
                  <h3>
                    <i className="app-header-icon icon-cube"></i>Dodatkowe
                    informacje o szkole
                  </h3>
                  <div className="input-wrapper">
                    <TextField
                      className="school-email-input"
                      value={schoolEmail}
                      disabled={this.isPending() || props.total > 0}
                      onChange={this.handleSchoolEmailInputChange}
                      onBlur={this.handleSchoolEmailInputBlur}
                      type="email"
                      variant="outlined"
                      label="E-mail szkoły"
                      error={Boolean(schoolEmailError)}
                      helperText={
                        schoolEmailError ||
                        `"E-mail szkoły" jest opcjonalnym polem`
                      }
                    />
                  </div>
                  <div className="input-wrapper">
                    <TextField
                      className="school-phone-input"
                      value={schoolPhone}
                      disabled={this.isPending() || props.total > 0}
                      onChange={this.handleSchoolPhoneInputChange}
                      onBlur={this.handleSchoolPhoneInputBlur}
                      type="phone"
                      variant="outlined"
                      label="Telefon szkoły"
                      error={Boolean(schoolPhoneError)}
                      helperText={
                        schoolPhoneError ||
                        `"Telefon szkoły" jest opcjonalnym polem`
                      }
                    />
                  </div>
                  <div className="input-wrapper">
                    <TextField
                      className="school-page-input"
                      value={schoolPageUrl}
                      disabled={this.isPending() || props.total > 0}
                      onChange={this.handleSchoolPageUrlInputChange}
                      onBlur={this.handleSchoolPageUrlInputBlur}
                      type="text"
                      variant="outlined"
                      label="Strona internetowa szkoły"
                      error={Boolean(schoolPageUrlError)}
                      helperText={
                        schoolPageUrlError ||
                        `"Strona internetowa szkoły" jest opcjonalnym polem`
                      }
                    />
                  </div>
                </div>
              </Bubble>
              <Bubble className={`submit-form`} ready={true}>
                <div className="content">
                  <h3>
                    <i className="app-header-icon icon-login"></i>Podsumowanie
                  </h3>
                  <div>
                    <TextField
                      className="user-email-input"
                      value={userEmailAddress}
                      disabled={this.isPending() || props.total > 0}
                      onChange={this.handleUserEmailAddressInputChange}
                      onBlur={this.handleUserEmailAddressInputBlur}
                      type="email"
                      variant="outlined"
                      label="Twój adres e-mail"
                      required={true}
                      error={Boolean(userEmailAddressError)}
                      helperText={
                        userEmailAddressError ||
                        `"Twoj adres e-mail" jest wymaganym polem`
                      }
                    />
                  </div>

                  {!props.total && (
                    <Button
                      className="app-button add-school-button"
                      disabled={
                        this.isPending() ||
                        !this.isValidForm({
                          name,
                          city,
                          provinceType,
                          schoolEmail,
                          schoolPhone,
                          schoolPageUrl,
                          userEmailAddress,
                        }) ||
                        !this.isCurrentUserCanAdd(props.total)
                      }
                      onClick={this.handleAddSchoolButtonClick}
                    >
                      <IconPending isPending={this.isPending()} />
                      Wyślij
                    </Button>
                  )}
                  {props.total > 0 && (
                    <div className="in-verification-messsage">
                      <i className="icon-search"></i>
                      <span>
                        Dziękujemy za zgłoszenie szkoły! Jesteśmy w trakcie
                        weryfikacji... Jeśli będziemy potrzebować więcej
                        szczegółów wyślemy wiadomość na
                        {!userEmailAddress
                          ? ` podany adres e-mail.`
                          : ` podany adres e-mail: ${userEmailAddress}`}
                      </span>
                    </div>
                  )}
                </div>
              </Bubble>
            </>
          )}
        />
      </div>
    );
  }
}

export default connector(AddSchoolForm);
