import { fetchFromApi } from "../../utils/utils";

export const fetchBasicVotes = async (
  requestBody: Vote.Req.GetBasicVotesBody
): Promise<Vote.Res.GetBasicVotesBody> => {
  return await fetchFromApi<
    Vote.Req.GetBasicVotesBody,
    Vote.Res.GetBasicVotesBody
  >(`/votes/basic`, "GET");
};

/** */

export const fetchCurrentUserVotes = async (
  requestBody: Vote.Req.GetCurrentUserVotesBody
): Promise<Vote.Res.GetCurrentUserVotesBody> => {
  return await fetchFromApi<
    Vote.Req.GetCurrentUserVotesBody,
    Vote.Res.GetCurrentUserVotesBody
  >(`/votes/current`, "GET");
};

/***/

export const createCurrentVote = async (
  requestBody: Vote.Req.CreateCurrentVoteBody
): Promise<Vote.Res.CreateCurrentVoteBody> => {
  return await fetchFromApi<
    Vote.Req.CreateCurrentVoteBody,
    Vote.Res.CreateCurrentVoteBody
  >(`/votes/current`, "POST", requestBody);
};

/***/

export const deleteCurrentVote = async (
  params: Vote.Req.DeleteCurrentVoteParams
): Promise<Vote.Res.DeleteCurrentVoteBody> => {
  return await fetchFromApi<
    Vote.Req.DeleteCurrentVoteBody,
    Vote.Res.DeleteCurrentVoteBody
  >(`/votes/current/${params.id}`, "DELETE");
};

export default {
  fetchBasicVotes,
  fetchCurrentUserVotes,
  createCurrentVote,
  deleteCurrentVote,
};
