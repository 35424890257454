import { stringify } from "querystring";
import { fetchFromApi } from "../../utils/utils";

const postVisit = async (
  requestParams: Visit.Req.PostVisitParams,
  requestBody: Visit.Req.PostVisitBody,
  requestQuery: Visit.Req.PostVisitQuery
): Promise<Visit.Res.PostVisitBody> => {
  return await fetchFromApi<Visit.Req.PostVisitBody, Visit.Res.PostVisitBody>(
    `/visits`,
    "POST",
    requestBody
  );
};

export default {
  postVisit,
};
