import { configureStore, ThunkAction } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import {
  Action,
  combineReducers,
  Middleware,
  MiddlewareAPI,
  Store,
} from "redux";
import logger from "redux-logger";
import thunk, { ThunkDispatch } from "redux-thunk";

import UI from "./@ui";
import user from "./user";
import school from "./school";
import vote from "./vote";
import expert from "./expert";
import grade from "./grade";

export const appVersion: string = `v6`;

const reducer = combineReducers({
  UI,
  user,
  school,
  vote,
  expert,
  grade,
});

export const persistStateMiddleware: Middleware = (
  api: MiddlewareAPI<AppDispatch, AppState>
) => (next: AppDispatch) => (action: AppThunkAction) => {
  if (action.type === "ui/setScrollTop") return next(action);
  next(action);
  const tree: AppState = store.getState();
  setLocalStorage(tree);
};

const setLocalStorage = (tree: AppState) => {
  const treeSchoolPreviews = tree.school.schoolPreviews;
  const appState: AppState = {
    ...tree,
    school: {
      ...tree.school,
      schoolPreviews: treeSchoolPreviews
        ? treeSchoolPreviews.slice(0, 80)
        : treeSchoolPreviews,
    },
  };
  localStorage[`${appVersion}-appState`] = JSON.stringify(appState);
};

const getPersistedState = (): AppState | undefined => {
  try {
    const persistedState = localStorage.getItem(`${appVersion}-appState`);
    if (persistedState === null) throw new Error();
    const state: AppState = JSON.parse(persistedState);
    return state;
  } catch (error) {
    return undefined;
  }
};

const store: Store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(/*logger, */ thunk, persistStateMiddleware),
  preloadedState: getPersistedState(),
  devTools: process.env.NODE_ENV !== "production",
});

export type AppState = ReturnType<typeof reducer>;
export type AppDispatch = ThunkDispatch<AppState, undefined, Action<string>>;
export type AppThunkAction = ThunkAction<
  void,
  AppState,
  unknown,
  Action<string>
> & { type?: string };

export default store;
