import * as React from "react";
import { IShareWindowState } from "./IShareWindowState";
import {
  IShareWindowDispatchProps,
  IShareWindowProps,
  IShareWindowStateProps,
} from "./IShareWindowProps";
import "./ShareWindow.scss";
import Window from "../../shared/Window/Window";
import { Button } from "@material-ui/core";
import options from "./share-options.json";
import { withRouter } from "react-router";
import { AppDispatch, AppState } from "../../../store/store";
import { connect } from "react-redux";
import env from "../../../assets/data/.env.json";
import {
  getGetSchoolDetailsByFullNameBody,
  getSelectedSchoolDetails,
} from "../../../store/school/school.selectors";
import schoolsHelpers from "../../../store/school/schools.helpers";
import { getIsShareWindowClosed } from "../../../store/@ui/ui.selectors";
import {
  setIsIframeWindowClosedAction,
  setIsShareWindowClosedAction,
} from "../../../store/@ui/ui.actions";
import { encodeSchoolPathUrl } from "../../../utils/utils";

const mapStateToProps = (appState: AppState): IShareWindowStateProps => ({
  selectedSchoolDetails: getSelectedSchoolDetails(appState),
  isShareWindowClosed: getIsShareWindowClosed(appState),
  getSchoolDetailsByFullNameBody: getGetSchoolDetailsByFullNameBody(appState),
});

const mapDispatchToProps = (
  dispatch: AppDispatch
): IShareWindowDispatchProps => ({
  onIframeWindowOpen: () => {
    dispatch(setIsIframeWindowClosedAction({ isClosed: false }));
  },
  onShareWindowClose: () => {
    dispatch(setIsShareWindowClosedAction({ isClosed: true }));
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);

class ShareWindow extends React.Component<
  IShareWindowProps,
  IShareWindowState
> {
  constructor(props: IShareWindowProps) {
    super(props);
    this.state = {};
  }

  public getSchoolUrl = (from?: string): string => {
    const { location, match, selectedSchoolDetails } = this.props;
    const pathname = location.pathname;
    const fullName = schoolsHelpers.getFullName(selectedSchoolDetails);
    const fromQuery = from ? `%26from=${from}` : "";
    const schoolUrl: string = match.params.schoolFullName
      ? `https://szkolytechnologiczne.pl${pathname}%3Fvote=up${fromQuery}`
      : `https://szkolytechnologiczne.pl/ranking/${fullName}%3Fvote=up${fromQuery}`;
    return encodeSchoolPathUrl(schoolUrl);
  };

  public getOptionHref = (
    href: string | undefined,
    from: string
  ): string | undefined => {
    if (!href) return undefined;
    const { selectedSchoolDetails } = this.props;
    const schoolUrl: string = this.getSchoolUrl(from);
    const schoolName: string = selectedSchoolDetails
      ? `${selectedSchoolDetails.name} w ${selectedSchoolDetails.city}`
      : "";
    return href
      .replace("#{app_id}", env.FB_APP_ID)
      .replace("#{url}", schoolUrl)
      .replace("#{text}", `Zagłosuj na ${schoolName}!`);
  };

  public handleCopyButtonClick = () => {
    const url: string = this.getSchoolUrl();
    navigator.clipboard.writeText(url);
  };

  public handleCopyUrlInputFocus = (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    event.target.select();
  };

  public handleWindowClose = () => {
    const { onShareWindowClose } = this.props;
    onShareWindowClose();
  };

  public handleEmbedCodeOptionClick = () => {
    const { onShareWindowClose, onIframeWindowOpen } = this.props;
    onShareWindowClose();
    onIframeWindowOpen();
  };

  public render(): JSX.Element {
    const { isShareWindowClosed } = this.props;
    const url: string = this.getSchoolUrl();
    return (
      <Window
        className={`ShareWindow`}
        isClosed={isShareWindowClosed}
        onClose={this.handleWindowClose}
      >
        <div className="share-window-inner">
          <h3>Udostępnij szkołę</h3>
          <div className="share-options">
            <a className="option" onClick={this.handleEmbedCodeOptionClick}>
              <div className="icon">
                <i className="icon-code"></i>
              </div>
              <div className="text">
                <span>Wstaw kod</span>
              </div>
            </a>
            {options.map((option, i) => (
              <a
                key={i}
                className="option"
                href={this.getOptionHref(option.href, option.from)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div
                  className="icon"
                  style={{ backgroundColor: option.backgroundColor }}
                >
                  <i className={option.iconName}></i>
                </div>
                <div className="text">
                  <span>{option.name}</span>
                </div>
              </a>
            ))}
          </div>
          <div className="copy-url">
            <input
              className="copy-url-input"
              value={url}
              onFocus={this.handleCopyUrlInputFocus}
            />
            <Button onClick={this.handleCopyButtonClick}>Kopiuj</Button>
          </div>
        </div>
      </Window>
    );
  }
}

export default withRouter(connector(ShareWindow));
