import * as React from "react";
import { ISchoolDetailsVoteCloudLimitReachedStageState } from "./ISchoolDetailsVoteCloudLimitReachedStageState";
import { ISchoolDetailsVoteCloudLimitReachedStageProps } from "./ISchoolDetailsVoteCloudLimitReachedStageProps";
import "./SchoolDetailsVoteCloudLimitReachedStage.scss";
import "moment/locale/pl";
import moment from "moment";

class SchoolDetailsVoteCloudLimitReachedStage extends React.Component<
  ISchoolDetailsVoteCloudLimitReachedStageProps,
  ISchoolDetailsVoteCloudLimitReachedStageState
> {
  constructor(props: ISchoolDetailsVoteCloudLimitReachedStageProps) {
    super(props);
    this.state = {};
  }

  getVotingDate = (
    votes: Vote.Vote[] | undefined,
    selectedVoteType: 1 | -1 | undefined
  ): string | undefined => {
    if (!votes) return undefined;
    if (!votes[0]) return undefined;
    if (!selectedVoteType) return undefined;
    const oldestVote: Vote.Vote = votes[0];
    const votingDate: string = moment(oldestVote.created_at)
      .add(selectedVoteType === 1 ? 1 : 3, "days")
      .calendar();
    return votingDate;
  };

  public render(): JSX.Element {
    const {
      selectedVoteType,
      negativeVotesInLastThreeDays,
      positiveVotesInLastOneDay,
    } = this.props;
    return (
      <div className={`SchoolDetailsVoteCloudLimitReachedStage`}>
        <div>
          <span>Niestety, ale wykorzystano limit głosowań.</span>
        </div>
        <div>
          <span>Jako autoryzowany użytkownik możesz oddać:</span>
        </div>
        <ul>
          <li>maksymalnie 3 głosy pozytywne na 24h</li>
          <li>maksymalnie 1 głos negatywny na 72h</li>
        </ul>

        <>
          <div>
            <span>
              {selectedVoteType === 1 ? "Pozytywny" : "Negatywny"} głos będziesz
              mógł oddać:
            </span>
          </div>
          <div>
            <b>
              {this.getVotingDate(positiveVotesInLastOneDay, selectedVoteType)}
            </b>
          </div>
        </>
      </div>
    );
  }
}

export default SchoolDetailsVoteCloudLimitReachedStage;
