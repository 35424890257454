import env from "../assets/data/.env.json";

export const rand = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export function shuffle<T>(array: T[]) {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    let temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }

  return array;
}

export const encodeSchoolUrl = (school?: {
  name: string;
  city: string;
  provinceType: string;
}): string => {
  return `${school?.name} ${school?.city} ${school?.provinceType}`
    .replaceAll("-", "--")
    .replaceAll(" ", "-");
};

export const encodeSchoolPathUrl = (schoolPath: string): string => {
  return schoolPath.replaceAll("-", "--").replaceAll(" ", "-");
};

export const decodeSchoolUrl = (schoolUrl: string) => {
  return schoolUrl
    .replaceAll("--", "%2D")
    .replaceAll("-", " ")
    .replaceAll("%2D", "-");
};

export function Operation(
  target: Object,
  propertyKey: string | symbol,
  descriptor: PropertyDescriptor
) {
  const originalMethod: any = descriptor.value;
  descriptor.value = async function (...args: any[]) {
    try {
      await originalMethod.apply(this, args);
    } catch (error) {
      const [, , next] = args;
      next(error);
    }
  };
}

export const scrollContainerToElement = <
  T extends HTMLElement = HTMLDivElement
>(
  container: React.RefObject<T>,
  element: React.RefObject<T>
) =>
  container.current &&
  element.current &&
  container.current.scrollTo(0, element.current.offsetTop);

export const getReCaptchaPublicKey = (): string =>
  "6Ld0SOAZAAAAACGmPrBR5GZL84FDGby_B0LIEz6C";

export const getReCaptchaPrivateKey = (): string =>
  "6Ld0SOAZAAAAAAhBcOMzqsnhSvP--yCBILDndRf3";

export const localStorageVersion: string = `v1`;
export const setLocalStorage = (key: string, value: any): void => {
  localStorage.setItem(`${localStorageVersion}-${key}`, JSON.stringify(value));
};

export const getLocalStorage = (key: string): any => {
  const value: string | null = localStorage.getItem(
    `${localStorageVersion}-${key}`
  );
  if (!value) return null;
  return JSON.parse(value);
};

export async function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function fetchFromApi<
  RequestBody extends {},
  ResponseBody extends System.ResponseBody
>(
  endPoint: string,
  method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE",
  requestBody?: RequestBody
): Promise<ResponseBody> {
  const apiUrl: string =
    process.env.NODE_ENV === "production"
      ? env.API_URL
      : `http://localhost:8080`;
  const response: Response = await fetch(`${apiUrl}${endPoint}`, {
    method,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(requestBody),
  });
  const responseBody: ResponseBody = await response.json();
  return responseBody;
}

// TODO
export async function fetchFromGoogleApi<
  RequestBody extends {},
  ResponseBody extends System.ResponseBody
>(
  endPoint: string,
  method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE",
  requestBody?: RequestBody
): Promise<ResponseBody> {
  const apiUrl: string =
    process.env.NODE_ENV === "production"
      ? env.API_URL
      : `http://localhost:8080`;
  const response: Response = await fetch(`${apiUrl}${endPoint}`, {
    method,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(requestBody),
  });
  const responseBody: ResponseBody = await response.json();
  return responseBody;
}

export function isErrorResponse(responseBody: System.ResponseBody): boolean {
  return responseBody.status === "error";
}

export const focusStandardTextField = (
  textFieldRef: React.RefObject<HTMLDivElement>
) => {
  const input = textFieldRef.current?.children[0].children[0] as
    | HTMLInputElement
    | undefined;

  if (!input) return null;
  return setTimeout(() => {
    input.focus();
  }, 200);
};
export function HSLToHex(hslFn: string | undefined) {
  if (!hslFn) return undefined;
  const hsl = hslFn
    .substring(4, hslFn.length - 1)
    .replace("deg", "")
    .replaceAll("%", "")
    .split(",");

  let h: number = Number(hsl[0]);
  let s: number = Number(hsl[1]);
  let l: number = Number(hsl[2]);
  s /= 100;
  l /= 100;

  let c = (1 - Math.abs(2 * l - 1)) * s,
    x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
    m = l - c / 2,
    r = 0,
    g = 0,
    b = 0;

  if (0 <= h && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (60 <= h && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (120 <= h && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (180 <= h && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (240 <= h && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (300 <= h && h < 360) {
    r = c;
    g = 0;
    b = x;
  }
  // Having obtained RGB, convert channels to hex
  let rr: string = Math.round((r + m) * 255).toString(16);
  let gg: string = Math.round((g + m) * 255).toString(16);
  let bb: string = Math.round((b + m) * 255).toString(16);

  // Prepend 0s, if necessary
  if (rr.length == 1) rr = "0" + r;
  if (gg.length == 1) gg = "0" + g;
  if (bb.length == 1) bb = "0" + b;

  return "#" + rr + gg + bb;
}

export const getSelectedSectionType = (searchObject: {
  [key: string]: any;
}): App.SchoolDetailsSectionType => {
  const { section } = searchObject;
  const defaultSectionType: App.SchoolDetailsSectionType = "basic-information";
  if (!section) {
    return defaultSectionType;
  }
  if (!(typeof section === "string")) {
    return defaultSectionType;
  }
  if (
    !["basic-informations", "experts-opinions", "users-comments"].includes(
      section
    )
  ) {
    return defaultSectionType;
  }

  return section as App.SchoolDetailsSectionType;
};

export const getSchoolFullName = (school: {
  name: string;
  city: string;
  provinceType: System.ProvinceType;
}): string => {
  return `${school.name} ${school.city} ${school.provinceType}`;
};

export const getUserVotesScore = (
  schoolDetails: School.SchoolDetails | undefined
): number => {
  if (!schoolDetails) return 0;
  const { voteBriefs } = schoolDetails;
  const voteUps: number = voteBriefs.filter((vote) => vote.type === 1).length;
  const voteDowns: number = voteBriefs.filter((vote) => vote.type === -1)
    .length;

  return voteUps - voteDowns;
};

export const getGoogleReviewsScore = (
  schoolDetails: School.SchoolDetails | undefined
): number => {
  if (!schoolDetails) return 0;
  const { googleReviews } = schoolDetails;
  const rateUps: number = googleReviews.filter((review) => review.rate >= 3)
    .length;
  const rateDowns: number = googleReviews.filter((review) => review.rate < 2)
    .length;

  return rateUps - rateDowns;
};
