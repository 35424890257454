import * as React from "react";
import { IAnimationTextState } from "./IAnimationTextState";
import { IAnimationTextProps } from "./IAnimationTextProps";
import "./AnimationText.scss";
import { AnimatePresence, HTMLMotionProps, motion } from "framer-motion";

export type SplitTextProps = {
  separator: string;
  text: string;
  onAnimationComplete?: () => void;
} & Omit<HTMLMotionProps<"div">, "onAnimationComplete">;

export class SplitText extends React.Component<SplitTextProps> {
  constructor(props: SplitTextProps) {
    super(props);
  }

  public handleAnimationComplete = (index: number, length: number) => {
    const { onAnimationComplete } = this.props;
    if (index === length - 1) onAnimationComplete && onAnimationComplete();
  };

  render() {
    const { text, separator, ...rest } = this.props;
    let words = text.split(separator);
    return words.map((word, i) => {
      return (
        <span
          key={text + i}
          style={{ display: "inline-block", overflow: "hidden" }}
        >
          <motion.span
            {...rest}
            style={{ display: "inline-block" }}
            custom={i}
            onAnimationComplete={() =>
              this.handleAnimationComplete(i, words.length)
            }
          >
            {word + (i !== words.length - 1 ? "\u00A0" : "")}
          </motion.span>
        </span>
      );
    });
  }
}

class AnimationText extends React.Component<
  IAnimationTextProps,
  IAnimationTextState
> {
  constructor(props: IAnimationTextProps) {
    super(props);
    this.state = {};
  }

  public render(): JSX.Element {
    const {
      children,
      visible,
      separator,
      delayBetweenWords,
      onAnimationComplete,
    } = this.props;
    return (
      <AnimatePresence>
        <span className="AnimationText">
          <motion.span
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <SplitText
              separator={separator || " "}
              text={String(children)}
              initial={{ y: "100%" }}
              animate={visible ? "visible" : "hidden"}
              onAnimationComplete={onAnimationComplete}
              variants={{
                visible: (i: number) => ({
                  y: 0,
                  transition: {
                    delay: i * (delayBetweenWords || 0.1),
                  },
                }),
              }}
            ></SplitText>
          </motion.span>
        </span>
      </AnimatePresence>
    );
  }
}

export default AnimationText;
