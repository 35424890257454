import { stringify } from "query-string";
import { fetchFromApi } from "../../utils/utils";

export const fetchBasicUsers = async (
  requestBody: User.Req.GetBasicUsersBody
): Promise<User.Res.GetBasicUsersBody> => {
  const responseBody = await fetchFromApi<
    User.Req.GetBasicUsersBody,
    User.Res.GetBasicUsersBody
  >(`/users`, "GET");
  return responseBody;
};

/** */

export const fetchCurrentUser = async (
  requestBody: User.Req.GetCurrentUserBody
): Promise<User.Res.GetCurrentUserBody> => {
  const responseBody = await fetchFromApi<
    User.Req.GetCurrentUserBody,
    User.Res.GetCurrentUserBody
  >(`/users/current`, "GET");
  return responseBody;
};

/** */

export const createCurrentUserEmail = async (
  requestBody: User.Req.CreateCurrentUserEmailBody
): Promise<User.Res.CreateCurrentUserEmailBody> => {
  const responseBody = await fetchFromApi<
    User.Req.CreateCurrentUserEmailBody,
    User.Res.CreateCurrentUserEmailBody
  >(`/users/current/emails`, "POST", requestBody);
  return responseBody;
};

/** */

export const createCurrentUserFacebookAccount = async (
  requestBody: User.Req.CreateCurrentUserFacebookAccountBody
): Promise<User.Res.CreateCurrentUserFacebookAccountBody> => {
  const responseBody = await fetchFromApi<
    User.Req.CreateCurrentUserFacebookAccountBody,
    User.Res.CreateCurrentUserFacebookAccountBody
  >(`/users/current/facebook_accounts`, "POST", requestBody);
  return responseBody;
};

/** */

export const putCurrentUserFacebookAccount = async (
  requestBody: User.Req.PutCurrentUserFacebookAccountBody
): Promise<User.Res.PutCurrentUserFacebookAccountBody> => {
  const responseBody = await fetchFromApi<
    User.Req.PutCurrentUserFacebookAccountBody,
    User.Res.PutCurrentUserFacebookAccountBody
  >(`/users/current/facebook_accounts`, "PUT", requestBody);
  return responseBody;
};

/** */

export const patchCurrentUser = async (
  requestBody: User.Req.PatchCurrentUserBody
): Promise<User.Res.PatchCurrentUserBody> => {
  const responseBody = await fetchFromApi<
    User.Req.PatchCurrentUser,
    User.Res.PatchCurrentUser
  >(`/users/current`, "PATCH", requestBody);
  return responseBody;
};

/** */

export const generateEmailVerificationCode = async (
  requestBody: Code.Req.GenerateCodeBody
): Promise<Code.Res.GenerateCodeBody> => {
  const responseBody = await fetchFromApi<
    Code.Req.GenerateCodeBody,
    Code.Res.GenerateCodeBody
  >(`/codes/generate`, "POST", requestBody);
  return responseBody;
};

/** */

export const verifyCurrentUser = async (
  requestBody: User.Req.VerifyCurrentUserBody
): Promise<User.Res.VerifyCurrentUserBody> => {
  const responseBody = await fetchFromApi<
    User.Req.VerifyCurrentUserBody,
    User.Res.VerifyCurrentUserBody
  >(`/users/current/verify`, "POST", requestBody);
  return responseBody;
};

/** */

export const verifyEmail = async (
  requestBody: Code.Req.VerifyCodeBody
): Promise<Code.Res.VerifyCodeBody> => {
  const responseBody = await fetchFromApi<
    Code.Req.VerifyCodeBody,
    Code.Res.VerifyCodeBody
  >(`/codes/verify`, "POST", requestBody);
  return responseBody;
};

export const getDeleteFacebookAccountStatus = async (
  requestQuery: User.Req.GetDeleteUserFacebookAccountQuery
): Promise<User.Res.GetDeleteUserFacebookAccountBody> => {
  const responseBody = await fetchFromApi<
    User.Req.GetDeleteUserFacebookAccount,
    User.Res.GetDeleteUserFacebookAccount
  >(`/users/delete_facebook_account/?${stringify(requestQuery)}`, "GET");
  return responseBody;
};

export default {
  fetchBasicUsers,
  fetchCurrentUser,
  createCurrentUserEmail,
  generateEmailVerificationCode,
  verifyCurrentUser,
  verifyEmail,
  getDeleteFacebookAccountStatus,
};
