import * as React from "react";
import { ISchoolDetailsBasicInformationsState } from "./ISchoolDetailsBasicInformationsState";
import { ISchoolDetailsBasicInformationsProps } from "./ISchoolDetailsBasicInformationsProps";
import "./SchoolDetailsBasicInformations.scss";
import Skeleton from "react-loading-skeleton";

import provinces from "../../../assets/data/provinces.data.json";
import ScoreBox from "../ScoreBox/ScoreBox";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

class SchoolDetailsBasicInformations extends React.Component<
  ISchoolDetailsBasicInformationsProps,
  ISchoolDetailsBasicInformationsState
> {
  constructor(props: ISchoolDetailsBasicInformationsProps) {
    super(props);
    this.state = {};
  }

  public renderValue = (
    value: string | JSX.Element,
    type: "website" | "phone" | "email" | undefined,
    colorTheme?: string
  ) => {
    if (!type) return value;
    if (type === "website")
      return (
        <Link
          to={`/redirect/?to=${value}`}
          className="basic-information-link app-link"
          style={{ color: colorTheme }}
          href={`${value}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {value}
        </Link>
      );
    if (type === "phone")
      return (
        <a
          className="basic-information-link app-link"
          style={{ color: colorTheme }}
          href={`tel:${value}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {value}
        </a>
      );
    if (type === "email")
      return (
        <a
          className="basic-information-link app-link"
          style={{ color: colorTheme }}
          href={`mailto:${value}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {value}
        </a>
      );
  };

  public renderRow = (payload: {
    getSchoolDetailsBody: School.Res.GetSchoolDetailsByFullNameBody;
    label: string;
    iconName: string;
    value: string | JSX.Element | undefined;
    type?: "website" | "phone" | "email";
    colorTheme?: string;
    className?: string;
  }) => {
    const {
      getSchoolDetailsBody,
      label,
      iconName,
      value,
      type,
      colorTheme,
      className,
    } = payload;
    return getSchoolDetailsBody.status === "pending" ? (
      <li className={`basic-information ${className}`}>
        <span className="icon">
          <Skeleton />
        </span>
        <span className="property-name">
          <Skeleton />
        </span>
        <span className={`value`}>
          <Skeleton />
        </span>
      </li>
    ) : (
      <li className={`basic-information ${className}`}>
        <i className={`icon ${iconName}`}></i>
        <span className="property-name">{label}</span>
        <div className={`value ${!value && "undefined"}`}>
          {value ? (
            this.renderValue(value, type, colorTheme)
          ) : (
            <span>Nie podano</span>
          )}
        </div>
      </li>
    );
  };

  public render(): JSX.Element {
    const { renderRow } = this;
    const {
      expertsScore,
      userVotesScore,
      googleReviewsScore,
      colorTheme,
      basicInformations,
      getSchoolDetailsBody,
      city,
      provinceType,
      schoolType,
      schoolId,
      distinguishingFeature,
      additionalLocations,
    } = this.props;
    return (
      <div className={`schoolDetailsBasicInformations`}>
        {distinguishingFeature && getSchoolDetailsBody.status !== "pending" && (
          <motion.div
            className="distinguishing-feature"
            style={{ background: colorTheme }}
            animate={{ maxHeight: [0, 80], opacity: [0, 0, 1] }}
            transition={{ delay: 0.6, duration: 0.6 }}
          >
            <div className="icon-box">
              <i className="icon-pin"></i>
            </div>
            <div className="value-box">
              <span>{distinguishingFeature}</span>
            </div>
          </motion.div>
        )}
        {renderRow({
          getSchoolDetailsBody,
          label: "Rodzaj szkoły",
          iconName: "icon-graduation-cap",
          value:
            schoolType && schoolType === "technical" ? "Technikum" : "Liceum",
        })}
        {renderRow({
          getSchoolDetailsBody,
          label: "Miasto",
          iconName: "icon-location",
          value: city,
        })}
        {renderRow({
          getSchoolDetailsBody,
          label: "Województwo",
          iconName: "icon-target",
          value: provinceType && provinces[provinceType],
        })}
        {additionalLocations &&
          additionalLocations.length > 0 &&
          renderRow({
            getSchoolDetailsBody,
            className: "additional-locations",
            label: "Inne oddziały",
            iconName: "icon-list-add",
            value: (
              <ul>
                {additionalLocations.map((location, i) => (
                  <li key={i}>
                    {location.city}, {provinces[location.provinceType]}
                  </li>
                ))}
              </ul>
            ),
          })}
        {renderRow({
          getSchoolDetailsBody,
          label: "Strona internetowa",
          iconName: "icon-globe",
          value: basicInformations?.schoolPageUrl,
          type: "website",
          colorTheme,
        })}
        {renderRow({
          getSchoolDetailsBody,
          label: "E-mail",
          iconName: "icon-at",
          value: basicInformations?.mail,
          type: "email",
          colorTheme,
        })}
        {renderRow({
          getSchoolDetailsBody,
          label: "Telefon",
          iconName: "icon-phone",
          value: basicInformations?.phone,
          type: "phone",
          colorTheme,
        })}

        <div className="margin"></div>
        <div>
          <ScoreBox
            isPending={getSchoolDetailsBody.status === "pending"}
            schoolId={schoolId}
            title={"Opinie ekspertów"}
            score={expertsScore}
            description={
              "Punkty przyznane od naszych ekspertów liczone na podstawie średniej arytmetycznej wszystkich ocen."
            }
            colorTheme={colorTheme}
          />
          <ScoreBox
            isPending={getSchoolDetailsBody.status === "pending"}
            schoolId={schoolId}
            title={"Głosy użytkowników"}
            score={userVotesScore}
            description={
              "Punkty przyznane na podstawie głosów od naszych użytkowników."
            }
            colorTheme={colorTheme}
          />
          <ScoreBox
            isPending={getSchoolDetailsBody.status === "pending"}
            schoolId={schoolId}
            title={"Recenzje google"}
            score={googleReviewsScore}
            description={
              "Punkty przyznane na podstawie recenzji pobranych z google."
            }
            colorTheme={colorTheme}
          />
        </div>
      </div>
    );
  }
}

export default SchoolDetailsBasicInformations;
