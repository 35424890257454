import * as React from "react";
import { IIconState } from "./IIconState";
import { IIconProps } from "./IIconProps";
import "./Icon.scss";

class Icon extends React.Component<IIconProps, IIconState> {
  constructor(props: IIconProps) {
    super(props);
    this.state = {};
  }

  public render(): JSX.Element {
    const { children, width, height } = this.props;
    return (
      <div
        className={`Icon`}
        style={{ width, height, backgroundImage: `url(/icon.png)` }}
      >
        {children}
      </div>
    );
  }
}

export default Icon;
