import React from "react";

class ScoringRules extends React.Component {
  render() {
    return (
      <p className="ScoringRules">
        Każda szkoła uzyskuje liczbę punktów, które są tworzone na podstawie
        pięciu źródeł:
        <ul>
          <li>
            <b>Punkty podstawowe</b> - przyznawane na podstawie wyników z matur,
            olimpiad i innych konkursów.
          </li>
          <li>
            <b>Punkty ekspertów</b> - liczone na podstawie średniej
            arytmetycznej wszystkich opinii przyznanych przez członków zespołu
            ekspertów, którego zadaniem jest analiza jakości nauczania w danej
            szkole. Każda taka opinia posiada określoną przez eksperta ilość
            gwiazdek (od 1 do 5). Ostateczna średnia arytmetyczna gwiazdek jest
            mnożona przez 10 i wynik doliczany jest do sumy punktów.
          </li>
          <li>
            <b>Punkty od użytkowników</b> - przyznawane przez zweryfikowanych
            użytkowników naszej aplikacji. Każdy użytkownik może oddać jeden
            głos pozytywny lub negatywny na daną szkołę za pomocą metody
            głosowania przez Facebook'a lub przez potwierdzonego e-maila.
          </li>
          <li>
            <b>Punkty od użytkowników google</b> - przyznane na podstawie
            recenzji dostepnych w Google, gdzie każda recenzja z 3+ gwiazdkami
            jest zamieniana na jeden głos pozytywny, a recenzja mająca mniej niż
            3 gwiazdki zamieniana jest na jeden głos negatywny.
          </li>
        </ul>
      </p>
    );
  }
}

export default ScoringRules;
