import * as React from "react";
import { ISchoolDetailsUserCommentsState } from "./ISchoolDetailsUserCommentsState";
import { ISchoolDetailsUserCommentsProps } from "./ISchoolDetailsUserCommentsProps";
import "./SchoolDetailsUserComments.scss";
import SchoolDetailsUserComment from "../SchoolDetailsUserComment/SchoolDetailsUserComment";

class SchoolDetailsUserComments extends React.Component<
  ISchoolDetailsUserCommentsProps,
  ISchoolDetailsUserCommentsState
> {
  constructor(props: ISchoolDetailsUserCommentsProps) {
    super(props);
    this.state = {};
  }

  public getIsCachedCurrentUserVote = (
    voteBriefs: Vote.VoteBrief[] | undefined,
    currentUserVoteInSelectedSchool: Vote.Vote | undefined
  ): boolean => {
    if (!voteBriefs) return false;
    if (!currentUserVoteInSelectedSchool) return false;

    const cachedCurrentUserVote: Vote.VoteBrief | undefined = voteBriefs.find(
      (voteBrief) => voteBrief._id === currentUserVoteInSelectedSchool._id
    );
    return Boolean(cachedCurrentUserVote);
  };

  public render(): JSX.Element {
    const { voteBriefs, currentUserVoteInSelectedSchool } = this.props;
    const isCachedCurrentUserVote: boolean = this.getIsCachedCurrentUserVote(
      voteBriefs,
      currentUserVoteInSelectedSchool
    );
    return (
      <div className="SchoolDetailsUserComments">
        {currentUserVoteInSelectedSchool && !isCachedCurrentUserVote && (
          <SchoolDetailsUserComment
            voteBrief={currentUserVoteInSelectedSchool}
          />
        )}
        {voteBriefs &&
          voteBriefs
            .filter((voteBrief) => voteBrief.comment)
            .map((voteBrief, i: number) => (
              <SchoolDetailsUserComment key={i} voteBrief={voteBrief} />
            ))}
      </div>
    );
  }
}

export default SchoolDetailsUserComments;
