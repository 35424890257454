import * as React from "react";
import { IRulesState, RuleSection } from "./IRulesState";
import { IRulesProps } from "./IRulesProps";
import "./Rules.scss";
import Bubble from "../../shared/Bubble/Bubble";
import { motion } from "framer-motion";
import qs from "query-string";
import tabs from "./tabs.json";
import { Link, withRouter } from "react-router-dom";
import { Button } from "@material-ui/core";
import contents from "../../../assets/data/contents.data.json";
import PageRegulation from "./PageRegulation";
import PrivacyPolicy from "./PrivacyPolicy";
import ScoringRules from "./ScoringRules";

class Rules extends React.Component<IRulesProps, IRulesState> {
  constructor(props: IRulesProps) {
    super(props);
    this.state = {
      animateTabs: false,
      selectedSection: undefined,
      previousSection: undefined,
    };
  }

  componentDidMount() {
    this.onChangeQuery();
  }

  componentDidUpdate(oldProps: IRulesProps) {
    const { props } = this;
    if (props.location.search !== oldProps.location.search) {
      this.onChangeQuery();
    }
  }

  public onChangeQuery() {
    const { location } = this.props;
    const query = qs.parse(location.search);
    if (query.section) {
      this.setState({
        animateTabs: true,
        selectedSection: query.section as RuleSection,
        previousSection: this.state.selectedSection,
      });
    } else {
      this.setState({
        selectedSection: undefined,
        previousSection: this.state.selectedSection,
      });
    }
  }

  public render(): JSX.Element {
    const {} = this.props;
    const { selectedSection, previousSection, animateTabs } = this.state;
    return (
      <div className={`Rules`}>
        <div className="main">
          <div>
            {tabs.map((tab, i) => (
              <Bubble key={i} className="info" ready={true}>
                <div className="content">
                  <div className="top">
                    <div className="title-box">
                      <i className={tab.iconName}></i>
                      <span>{tab.title}</span>
                    </div>
                    <Link
                      to={selectedSection === tab.section ? "/rules" : tab.path}
                    >
                      <Button className="app-button">
                        {selectedSection === tab.section ? "Zamknij" : "Rozwiń"}
                      </Button>
                    </Link>
                  </div>

                  <motion.div
                    className="bottom"
                    transition={{ duration: 0.7 }}
                    animate={
                      animateTabs &&
                      (selectedSection === tab.section ||
                        previousSection === tab.section) && {
                        opacity:
                          selectedSection === tab.section ? [0, 1] : [1, 0],
                        maxHeight:
                          selectedSection === tab.section ? [0, 350] : [350, 0],
                      }
                    }
                  >
                    <div>
                      <div>
                        <span className={`details ${tab.section}`}>
                          {tab.section === "scoring-rules" && <ScoringRules />}
                          {tab.section === "page-regulation" && (
                            <PageRegulation />
                          )}
                          {tab.section === "privacy-policy" && (
                            <PrivacyPolicy />
                          )}
                        </span>
                      </div>
                    </div>
                  </motion.div>
                </div>
              </Bubble>
            ))}
          </div>
          <div>
            <Bubble className="welcome WelcomeRules" ready={true}>
              <div className="content">
                <div
                  className="icon"
                  style={{
                    backgroundImage: `url(${contents["rules"].image_url})`,
                  }}
                ></div>
                <h1>{contents["rules"].header}</h1>
                <p>{contents["rules"].description}</p>
              </div>
            </Bubble>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Rules);
