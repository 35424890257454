import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";

export type VoteState = {
  getCurrentUserVotesBody: Vote.Res.GetCurrentUserVotesBody;
  createCurrentVoteBody: Vote.Res.CreateCurrentVoteBody;
  deleteCurrentVoteBody: Vote.Res.DeleteCurrentVote;
};

const initialState: VoteState = {
  getCurrentUserVotesBody: { status: "empty" },
  createCurrentVoteBody: { status: "empty" },
  deleteCurrentVoteBody: { status: "empty" },
};

export const voteSlice: Slice<VoteState> = createSlice({
  name: "vote",
  initialState,
  reducers: {
    setGetCurrentUserVotesBody(
      state,
      { payload }: PayloadAction<{ body: Vote.Res.GetCurrentUserVotes }>
    ) {
      state.getCurrentUserVotesBody = payload.body;
    },

    setCreateCurrentVoteBody(
      state,
      { payload }: PayloadAction<{ body: Vote.Res.CreateCurrentVote }>
    ) {
      state.createCurrentVoteBody = payload.body;
    },

    setDeleteCurrentVoteBody(
      state,
      { payload }: PayloadAction<{ body: Vote.Res.DeleteCurrentVote }>
    ) {
      state.deleteCurrentVoteBody = payload.body;
    },
  },
});

export const {
  setVotes,
  unsetVotes,
  updateVote,
  setCurrentUserVotes,
} = voteSlice.actions;

export default voteSlice.reducer;
