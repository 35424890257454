import * as React from "react";
import { IWindowState } from "./IWindowState";
import { IWindowProps } from "./IWindowProps";
import "./Window.scss";
import Bubble from "../Bubble/Bubble";

class Window extends React.Component<IWindowProps, IWindowState> {
  constructor(props: IWindowProps) {
    super(props);
    this.state = {};
  }

  public handleCloseClick = () => {
    const { onClose } = this.props;
    onClose();
  };

  public render(): JSX.Element {
    const { className, children, isClosed } = this.props;
    return (
      <>
        {!isClosed && (
          <div className={`Window ${className}`}>
            <div className="window-inner">
              <Bubble>
                <div className="close" onClick={this.handleCloseClick}>
                  <i className="icon-cancel"></i>
                </div>
                <>{children}</>
              </Bubble>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default Window;
