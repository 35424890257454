import * as React from "react";
import { ISchoolDetailsCommentsState } from "./ISchoolDetailsCommentsState";
import { ISchoolDetailsCommentsProps } from "./ISchoolDetailsCommentsProps";
import "./SchoolDetailsComments.scss";
import SchoolDetailsUserComments from "../SchoolDetailsUserComments/SchoolDetailsUserComments";
import SchoolDetailsGoogleReviews from "../SchoolDetailsGoogleReviews/SchoolDetailsGoogleReviews";
import "./SchoolDetailsComments.scss";
import Oops from "../../shared/Oops/Oops";
import SchoolDetailsUserComment from "../SchoolDetailsUserComment/SchoolDetailsUserComment";

class SchoolDetailsComments extends React.Component<
  ISchoolDetailsCommentsProps,
  ISchoolDetailsCommentsState
> {
  constructor(props: ISchoolDetailsCommentsProps) {
    super(props);
    this.state = {};
  }

  public render(): JSX.Element {
    const {
      voteBriefs,
      googleReviews,
      currentUserVoteInSelectedSchool,
      getSchoolDetailsByFullNameBody,
    } = this.props;
    const isLoaded: boolean =
      getSchoolDetailsByFullNameBody.status !== "pending" &&
      googleReviews !== undefined &&
      voteBriefs !== undefined;
    const isEmpty: boolean =
      isLoaded &&
      !googleReviews?.length &&
      !voteBriefs?.length &&
      !currentUserVoteInSelectedSchool;

    return (
      <div className={`SchoolDetailsComments`}>
        {isLoaded ? (
          !isEmpty ? (
            <>
              <SchoolDetailsUserComments
                voteBriefs={voteBriefs ? [...voteBriefs].reverse() : undefined}
                currentUserVoteInSelectedSchool={
                  currentUserVoteInSelectedSchool
                }
              />
              <SchoolDetailsGoogleReviews googleReviews={googleReviews} />
            </>
          ) : (
            <Oops>
              <h3 className="oops-header">Przykro nam!</h3>
              <span>Nie ma tu jeszcze żadnego komentarza...</span>
            </Oops>
          )
        ) : (
          [1, 2, 3, 4, 5, 6, 7, 8].map((comment, i: number) => (
            <SchoolDetailsUserComment key={i} voteBrief={undefined} />
          ))
        )}
      </div>
    );
  }
}

export default SchoolDetailsComments;
