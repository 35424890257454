import * as React from "react";
import { IPodiumState } from "./IPodiumState";
import {
  IPodiumDispatchProps,
  IPodiumProps,
  IPodiumStateProps,
} from "./IPodiumProps";
import "./Podium.scss";
import Bubble from "../../shared/Bubble/Bubble";
import SchoolAvatar from "../../shared/SchoolAvatar/SchoolAvatar";
import { connect } from "react-redux";
import { AppDispatch, AppState } from "../../../store/store";
import {
  getGetSchoolPreviewsBody,
  getSchoolPodium,
} from "../../../store/school/school.selectors";
import { Link } from "react-router-dom";

const mapStateToProps = (appState: AppState): IPodiumStateProps => ({
  schoolPodium: getSchoolPodium(appState),
  getSchoolPreviewsBody: getGetSchoolPreviewsBody(appState),
});

const mapDispatchToProps = (
  dispatch: AppDispatch
): IPodiumDispatchProps => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);

class Podium extends React.Component<IPodiumProps, IPodiumState> {
  constructor(props: IPodiumProps) {
    super(props);
    this.state = {};
  }

  public render(): JSX.Element {
    const { schoolPodium, getSchoolPreviewsBody } = this.props;
    return (
      <Bubble
        className={`Podium`}
        ready={getSchoolPreviewsBody.status === "success"}
      >
        <div className="podium">
          <h3>
            <i className="app-header-icon icon-chart-bar"></i>Podium
          </h3>
          <div className="places">
            <Link
              to={
                schoolPodium.second
                  ? `/ranking/${schoolPodium.second.name} ${schoolPodium.second.city} ${schoolPodium.second.provinceType}`
                  : "/ranking/"
              }
              className="place"
            >
              <>
                <div className="avatar-box">
                  <SchoolAvatar
                    className="avatar"
                    initials={schoolPodium.second?.initials}
                    backgroundColorTheme={
                      schoolPodium.second?.backgroundColorTheme
                    }
                  />
                </div>
                <div className="position-box">
                  <span>2</span>
                </div>
              </>
            </Link>

            <Link
              to={
                schoolPodium.first
                  ? `/ranking/${schoolPodium.first.name} ${schoolPodium.first.city} ${schoolPodium.first.provinceType}`
                  : "/ranking/"
              }
              className="place"
            >
              <>
                <div className="avatar-box">
                  <SchoolAvatar
                    className="avatar"
                    initials={schoolPodium.first?.initials}
                    backgroundColorTheme={
                      schoolPodium.first?.backgroundColorTheme
                    }
                  />
                </div>
                <div className="position-box">
                  <span>1</span>
                </div>
              </>
            </Link>
            <Link
              to={
                schoolPodium.third
                  ? `/ranking/${schoolPodium.third.name} ${schoolPodium.third.city} ${schoolPodium.third.provinceType}`
                  : "/ranking/"
              }
              className="place"
            >
              <>
                <div className="avatar-box">
                  <SchoolAvatar
                    className="avatar"
                    initials={schoolPodium.third?.initials}
                    backgroundColorTheme={
                      schoolPodium.third?.backgroundColorTheme
                    }
                  />
                </div>

                <div className="position-box">
                  <span>3</span>
                </div>
              </>
            </Link>
          </div>
        </div>
      </Bubble>
    );
  }
}

export default connector(Podium);
