import {
  setAddVoteAuthAction,
  setEmailVerificationFormCodeErrorAction,
  setLastEmailResendTimestampAction,
  setSelectedVoteMethodTypeAction,
  setSelectedVoteMethodValueAction,
} from "./../@ui/ui.actions";
import {
  addCurrentUserEmailAction,
  addCurrentUserFacebookAccountAction,
  setCreateCurrentUserEmailBodyAction,
  setCreateCurrentUserFacebookAccountBodyAction,
  setCurrentUserAction,
  setGenerateCodeBodyAction,
  setGetCurrentUserBodyAction,
  setPatchCurrentUserBodyAction,
  setPutCurrentUserFacebookAccountBodyAction,
  setVerifyCodeBodyAction,
  updateCurrentUserFirstNameAction,
  updateCurrentUserFacebookAccountAction,
  verifyCurrentUserEmailAction,
  setVerifyCurrentUserBodyAction,
  setCurrentUserLastCaptchaVerificationAction,
  confirmCurrentUserVoteAction,
} from "./users.actions";
import {} from "./index";
import { AppDispatch, AppThunkAction } from "../store";

import {
  createCurrentUserEmail,
  createCurrentUserFacebookAccount,
  fetchCurrentUser,
  generateEmailVerificationCode,
  patchCurrentUser,
  putCurrentUserFacebookAccount,
  verifyEmail,
  verifyCurrentUser,
} from "./users.service";
import {
  addSelectedSchoolDetailsVoteBriefAction,
  incrementThumbsDownAction,
  incrementThumbsUpAction,
} from "../school/schools.actions";

export type FetchCurrentUserOperationPayload = {};

export const fetchCurrentUserOperation = (
  payload: FetchCurrentUserOperationPayload
): AppThunkAction => async (dispatch: AppDispatch) => {
  dispatch(setGetCurrentUserBodyAction({ status: "pending" }));
  const fetchCurrentUserRes = await fetchCurrentUser({});
  dispatch(setGetCurrentUserBodyAction(fetchCurrentUserRes));

  if (!fetchCurrentUserRes.payload) return;
  // prettier-ignore
  dispatch(setCurrentUserAction({ currentUser: {...fetchCurrentUserRes.payload.currentUser, } })
  );
};

/** */

export type PatchCurrentUserOperationPayload = {
  firstName?: string;
};

export const patchCurrentUserOperation = (
  payload: PatchCurrentUserOperationPayload
): AppThunkAction => async (dispatch: AppDispatch) => {
  dispatch(setPatchCurrentUserBodyAction({ status: "pending" }));
  const patchCurrentUserRes = await patchCurrentUser(payload);
  dispatch(setPatchCurrentUserBodyAction(patchCurrentUserRes));
  if (!patchCurrentUserRes.payload) return;

  if (patchCurrentUserRes.payload.firstName) {
    // prettier-ignore
    dispatch(updateCurrentUserFirstNameAction({firstName: patchCurrentUserRes.payload.firstName}));
  }
};

/** */

export type CreateCurrentUserEmailOperationPayload = {
  address: string;
};

export const createCurrentUserEmailOperation = (
  payload: CreateCurrentUserEmailOperationPayload
): AppThunkAction => async (dispatch: AppDispatch) => {
  // prettier-ignore
  dispatch(setCreateCurrentUserEmailBodyAction({ status: "pending" }));
  const createCurrentUserEmailRes = await createCurrentUserEmail({
    address: payload.address,
  });
  // prettier-ignore
  dispatch(setCreateCurrentUserEmailBodyAction(createCurrentUserEmailRes));

  if (!createCurrentUserEmailRes.payload) return;
  // prettier-ignore
  dispatch(addCurrentUserEmailAction({ email: createCurrentUserEmailRes.payload.createdCurrentUserEmail }));
};

/** */

export type CreateCurrentUserFacebookAccountOperationPayload = {
  userID: string;
  accessToken: string;
};

export const createCurrentUserFacebookAccountOperation = (
  payload: CreateCurrentUserFacebookAccountOperationPayload
): AppThunkAction => async (dispatch: AppDispatch) => {
  // prettier-ignore
  dispatch(setCreateCurrentUserFacebookAccountBodyAction({ status: "pending" }));
  const createCurrentUserFacebookAccountRes = await createCurrentUserFacebookAccount(
    {
      userID: payload.userID,
      accessToken: payload.accessToken,
    }
  );
  // prettier-ignore
  dispatch(setCreateCurrentUserFacebookAccountBodyAction(createCurrentUserFacebookAccountRes));
  if (
    createCurrentUserFacebookAccountRes.error?.type ===
    "account_is_confirmed_by_other_user"
  ) {
    dispatch(
      setAddVoteAuthAction({
        otherError: "To konto zostało już użyte do głosowania",
      })
    );
  }
  if (!createCurrentUserFacebookAccountRes.payload) return;
  // prettier-ignore
  dispatch(addCurrentUserFacebookAccountAction({ facebookAccount: createCurrentUserFacebookAccountRes.payload.createdCurrentUserFacebookAccount }));
  // prettier-ignore
  dispatch(setSelectedVoteMethodTypeAction({ selectedVoteMethodType: "facebook" }));
  // prettier-ignore
  dispatch(setSelectedVoteMethodValueAction({selectedVoteMethodValue: payload.userID,}));
};

/** */

export type PutCurrentUserFacebookAccountOperationPayload = {
  userID: string;
  accessToken: string;
};

export const putCurrentUserFacebookAccountOperation = (
  payload: PutCurrentUserFacebookAccountOperationPayload
): AppThunkAction => async (dispatch: AppDispatch) => {
  // prettier-ignore
  dispatch(setPutCurrentUserFacebookAccountBodyAction({ status: "pending" }));
  const putCurrentUserFacebookAccountRes = await putCurrentUserFacebookAccount({
    userID: payload.userID,
    accessToken: payload.accessToken,
  });
  // prettier-ignore
  dispatch(setPutCurrentUserFacebookAccountBodyAction(putCurrentUserFacebookAccountRes));

  if (!putCurrentUserFacebookAccountRes.payload) return;
  const {
    updatedCurrentUserFacebookAccount,
  } = putCurrentUserFacebookAccountRes.payload;
  // prettier-ignore
  dispatch(updateCurrentUserFacebookAccountAction({userID: payload.userID, newAccount: updatedCurrentUserFacebookAccount}));

  // prettier-ignore
  dispatch(setSelectedVoteMethodTypeAction({ selectedVoteMethodType: "facebook" }));
  // prettier-ignore
  dispatch(setSelectedVoteMethodValueAction({selectedVoteMethodValue: payload.userID}));
};

/** */

export type GenerateEmailVerificationCodeOperationPayload = {
  emailAddress: string;
};

export const generateEmailVerificationCodeOperation = (
  payload: GenerateEmailVerificationCodeOperationPayload
): AppThunkAction => async (dispatch: AppDispatch) => {
  // prettier-ignore
  dispatch(setGenerateCodeBodyAction({ status: "pending" }));
  // prettier-ignore
  const generateCodeBody = await generateEmailVerificationCode({ emailAddress: payload.emailAddress });
  // prettier-ignore
  const lastEmailResendTimestamp: number = Date.now();
  dispatch(setLastEmailResendTimestampAction({ lastEmailResendTimestamp }));
  dispatch(setGenerateCodeBodyAction(generateCodeBody));

  if (!generateCodeBody.payload) return;
};

/** */

export type VerifyEmailOperationPayload = {
  currentUserVotesFromEmail: Vote.Vote[];
  emailAddress: string;
  verificationCode: string;
};

export const verifyEmailOperation = (
  payload: VerifyEmailOperationPayload
): AppThunkAction => async (dispatch: AppDispatch) => {
  // prettier-ignore
  dispatch(setVerifyCodeBodyAction({ status: "pending" }));

  const verifyEmailRes = await verifyEmail({
    emailAddress: payload.emailAddress,
    verificationCode: payload.verificationCode,
  });

  dispatch(setVerifyCodeBodyAction(verifyEmailRes));

  if (verifyEmailRes.error) {
    switch (verifyEmailRes.error.type) {
      case "code_is_expired":
        dispatch(
          setEmailVerificationFormCodeErrorAction({
            codeError: `"Kod" wygasł - musisz wygenerować nowy`,
          })
        );
        break;
      case "code_is_invalid":
        dispatch(
          setEmailVerificationFormCodeErrorAction({
            codeError: `"Kod" jest niepoprawny`,
          })
        );
        break;
    }
    return;
  }
  if (!verifyEmailRes.payload) return;

  // prettier-ignore
  dispatch(verifyCurrentUserEmailAction({ emailAddress: payload.emailAddress }));

  payload.currentUserVotesFromEmail.forEach((vote) => {
    dispatch(confirmCurrentUserVoteAction({ voteId: vote._id }));
    if (vote.type === 1)
      dispatch(
        incrementThumbsUpAction({ schoolPreviewId: vote.addedToSchoolId })
      );
    // prettier-ignore
    if(vote.type === -1) dispatch(incrementThumbsDownAction({schoolPreviewId: vote.addedToSchoolId}))
    // prettier-ignore
    dispatch(addSelectedSchoolDetailsVoteBriefAction({
      addedToSchoolId: vote.addedToSchoolId,
      voteBrief: {
        _id: vote._id, 
        type: vote.type,
        created_at: vote.created_at,
        firstName: vote.firstName,
        comment: vote.comment
      }
    }))
  });
};

/** */

export type VerifyCurrentUserOperationPayload = { token: string };

export const verifyCurrentUserOperation = (
  payload: VerifyCurrentUserOperationPayload
): AppThunkAction => async (dispatch: AppDispatch) => {
  dispatch(setVerifyCurrentUserBodyAction({ status: "pending" }));
  const verifyCurrentUserRes = await verifyCurrentUser({
    token: payload.token,
  });

  dispatch(setVerifyCurrentUserBodyAction(verifyCurrentUserRes));

  if (!verifyCurrentUserRes.payload) return;
  dispatch(
    setCurrentUserLastCaptchaVerificationAction({
      lastCaptchaVerification:
        verifyCurrentUserRes.payload.lastCaptchaVerification,
    })
  );
};

/** */

export default {
  fetchCurrentUserOperation,
  generateEmailVerificationCodeOperation,
  verifyEmailOperation,
  verifyCurrentUserOperation,
};
