import * as React from "react";
import { IEmbedState } from "./IEmbedState";
import { EmbedPageSearch, IEmbedProps, IEmbedStateProps } from "./IEmbedProps";
import "./Embed.scss";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { AppDispatch, AppState } from "../../../store/store";
import queryString from "query-string";
import H from "history";

import Logo from "../../entities/Logo/Logo";
import usersService from "../../../store/user/users.service";
import schoolsService from "../../../store/school/schools.service";
import Spinner from "../../shared/Spinner/Spinner";
import siteService from "../../../store/site/site.service";
import EmbedSchool from "../../entities/EmbedSchool/EmbedSchool";
import { encodeSchoolUrl } from "../../../utils/utils";

export const sizes = {
  small: { width: 320, height: 150 },
  medium: { width: 320, height: 220 },
  large: { width: 320, height: 400 },
};
export type EmbedSizeType = "small" | "medium" | "large";
export type EmbedThemeType = "light" | "gray" | "dark";

const mapStateToProps = (state: AppState): IEmbedStateProps => ({});

const mapDispatchToProps = (dispatch: AppDispatch) => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);

class Embed extends React.Component<IEmbedProps, IEmbedState> {
  constructor(props: IEmbedProps) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      currentUser: undefined,
      isCurrentUserPending: true,
      schoolDetails: undefined,
      isSchoolDetailsPending: true,
      currentSiteBrief: undefined,
      isSiteBriefPending: true,
    };
  }

  private getParentOrigin() {
    const locationAreDisctint = window.location !== window.parent.location;
    const parentOrigin = (
      (locationAreDisctint ? document.referrer : document.location) || ""
    ).toString();

    if (parentOrigin) {
      return new URL(parentOrigin).origin;
    }

    const currentLocation = document.location;

    if (
      currentLocation.ancestorOrigins &&
      currentLocation.ancestorOrigins.length
    ) {
      return currentLocation.ancestorOrigins[0];
    }

    return "";
  }

  componentDidMount() {
    const { match } = this.props;
    const siteName = this.getParentOrigin();
    this.fetchCurrentSiteBrief(siteName);
    this.fetchCurrentUser();
    this.fetchSchoolDetails(match.params.schoolId);
    this.setSize();
    window.addEventListener("resize", this.setSize);
  }

  componentDidUpdate() {}

  componentWillUnmount() {
    window.removeEventListener("resize", this.setSize);
  }

  public setSize = () => {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  public getSizeType = (width: number, height: number): EmbedSizeType => {
    if (height <= 150) return "small";
    if (height <= 220) return "medium";
    return "large";
  };

  public fetchCurrentUser = async () => {
    const currentUserBody = await usersService.fetchCurrentUser({});
    this.setState({
      currentUser: currentUserBody.payload?.currentUser,
      isCurrentUserPending: false,
    });
  };

  public fetchSchoolDetails = async (schoolId: string) => {
    const schoolDetailsBody = await schoolsService.fetchSchoolDetailsById(
      { schoolId },
      {},
      {}
    );
    this.setState({
      schoolDetails: schoolDetailsBody.payload?.schoolDetails,
      isSchoolDetailsPending: false,
    });
  };

  public fetchCurrentSiteBrief = async (name: string) => {
    const { match } = this.props;
    const { schoolId } = match.params;
    const currentSiteBriefBody = await siteService.getSiteBriefByName(
      {},
      { name, schoolId },
      {}
    );
    this.setState({
      isSiteBriefPending: false,
      currentSiteBrief: currentSiteBriefBody.payload?.siteBrief,
    });
  };

  public getTheme = (location: H.Location): "light" | "gray" | "dark" => {
    const { search } = location;
    const searchObj = queryString.parse(search);
    const { theme } = searchObj;
    if (theme && typeof theme === "string") {
      return theme === "light" || theme === "gray" || theme === "dark"
        ? theme
        : "light";
    }
    return "light";
  };

  public handleVote = () => {
    const { schoolDetails, currentSiteBrief } = this.state;
    if (!schoolDetails) return;
    const from_details = currentSiteBrief
      ? `&from_details=${currentSiteBrief._id}`
      : "";
    window.open(
      `https://www.szkolytechnologiczne.pl/ranking/${encodeSchoolUrl(
        schoolDetails
      )}?vote=up&from=iframe${from_details}`,
      "_blank"
    );
  };

  public render(): JSX.Element {
    const { location } = this.props;
    const {
      schoolDetails,
      currentSiteBrief,
      isCurrentUserPending,
      isSchoolDetailsPending,
      isSiteBriefPending,
      width,
      height,
    } = this.state;
    const currentSiteName: string = this.getParentOrigin();
    const isPending: boolean =
      isCurrentUserPending || isSchoolDetailsPending || isSiteBriefPending;
    const theme = this.getTheme(location);
    document.documentElement.setAttribute("data-theme", theme);
    const from_details = currentSiteBrief
      ? `&from_details=${currentSiteBrief._id}`
      : "";

    const sizeType = this.getSizeType(width, height);

    return (
      <div className={`Embed`}>
        <a
          href={`https://szkolytechnologiczne.pl/?from=iframe${from_details}`}
          target="_blank"
          rel="noopener noreferrer"
          className="app-label"
        >
          <Logo />
          {isPending && <Spinner background={"#1f1f22"} />}
        </a>

        {currentSiteBrief && currentSiteBrief.isIncluded && (
          <EmbedSchool
            schoolDetails={schoolDetails}
            sizeType={sizeType}
            onVote={this.handleVote}
          />
        )}
        {currentSiteBrief && !currentSiteBrief.isIncluded && (
          <div className="error">
            <div>
              <i className="icon-attention-circled"></i>
              <span>Wystąpił bład</span>
            </div>
            <p>
              Strona <i>{currentSiteName}</i> ma zablokowany dostęp do aplikacji
              Szkoły Technologiczne. Jeśli jesteś administatorem strony
              skontaktuj się z nami.
            </p>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(connector(Embed));
