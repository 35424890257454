import * as React from "react";
import { IMenuState } from "./IMenuState";
import { IMenuProps } from "./IMenuProps";
import "./Menu.scss";

import MenuOptionComponent from "../MenuOption/MenuOption";
import menuOptionsData from "./../../../assets/data/menu-options.data.json";
import Footer from "../Footer/Footer";
import Logo from "../Logo/Logo";

class Menu extends React.Component<IMenuProps, IMenuState> {
  constructor(props: IMenuProps) {
    super(props);
    this.state = {};
  }

  public render(): JSX.Element {
    return (
      <div className={`menu`}>
        <div className="menu-fixed">
          <div className="menu-middle">
            <div className="logo">
              <Logo />
            </div>
            <div className="options">
              {menuOptionsData.map((option: App.MenuOptionData, i: number) => (
                <MenuOptionComponent key={i} option={option} />
              ))}
            </div>
            <div className="mini-footer">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Menu;
