import * as React from "react";
import { ISchoolDetailsVoteCloudState } from "./ISchoolDetailsVoteCloudState";
import { ISchoolDetailsVoteCloudProps } from "./ISchoolDetailsVoteCloudProps";
import "./SchoolDetailsVoteCloud.scss";
import Cloud from "../../shared/Cloud/Cloud";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

import voteStages from "./vote-stages.data.json";
import SchoolDetailsVoteCloudFormStage from "../SchoolDetailsVoteCloudFormStage/SchoolDetailsVoteCloudFormStage";
import SchoolDetailsVoteCloudMailVerificationStage from "../SchoolDetailsVoteCloudMailVerificationStage/SchoolDetailsVoteCloudMailVerificationStage";
import SchoolDetailsVoteCloudErrorStage from "../SchoolDetailsVoteCloudErrorStage/SchoolDetailsVoteCloudErrorStage";
import SchoolDetailsVoteCloudSuccessStage from "../SchoolDetailsVoteCloudSuccessStage/SchoolDetailsVoteCloudSuccessStage";
import SchoolDetailsVoteCloudAuthStage from "../SchoolDetailsVoteCloudAuthStage/SchoolDetailsVoteCloudAuthStage";
import {
  SetAddVoteFormActionPayload,
  SetEmailVerificationFormActionPayload,
} from "../../../store/@ui/ui.actions";
import SchoolDetailsVoteCloudRecaptchaStage from "../SchoolDetailsVoteCloudRecaptchaStage/SchooldDetailsVoteCloudRecaptchaStage";
import SchoolDetailsVoteCloudLimitReachedStage from "../SchoolDetailsVoteCloudLimitReachedStage/SchoolDetailsVoteCloudLimitReachedStage";

export enum VoteStageType {
  METHOD_SELECTION = "method_selection",
  FORM = "form",
  CAPTCHA_VERIFICATION = "captcha_verification",
  MAIL_VERIFICATION = "mail_verification",
  SUCCESS = "success",
  LIMIT_REACHED = "limit_reached",
  ERROR = "error",
}

class SchoolDetailsVoteCloud extends React.Component<
  ISchoolDetailsVoteCloudProps,
  ISchoolDetailsVoteCloudState
> {
  constructor(props: ISchoolDetailsVoteCloudProps) {
    super(props);
    this.state = {};
  }

  private getVoteStageType = (payload: {
    userVoteStatus: App.VoteStatus;
    selectedVoteMethodType: System.VoteMethodType | undefined;
    currentUser: User.User | undefined;
    isCurrentUserVerified: boolean;
    isCurrentUserLimitReached: boolean;
  }): VoteStageType => {
    const {
      selectedVoteMethodType,
      userVoteStatus,
      currentUser,
      isCurrentUserVerified,
      isCurrentUserLimitReached,
    } = payload;
    if (userVoteStatus === "voted") return VoteStageType.SUCCESS;
    if (!isCurrentUserVerified) return VoteStageType.CAPTCHA_VERIFICATION;
    if (!currentUser || !currentUser.isIncluded) return VoteStageType.ERROR;
    if (isCurrentUserLimitReached) return VoteStageType.LIMIT_REACHED;
    if (userVoteStatus === "not_voted" && !selectedVoteMethodType)
      return VoteStageType.METHOD_SELECTION;
    if (userVoteStatus === "not_voted") return VoteStageType.FORM;
    if (userVoteStatus === "in_verification")
      return VoteStageType.MAIL_VERIFICATION;

    return VoteStageType.ERROR;
  };

  public handleGoogleReCaptchaVerify = (e: any): void => {};

  public handleFormStageSubmit = (event: {
    method: Vote.VoteMethod;
    firstName: string | undefined;
    comment: string | undefined;
  }): void => {
    const { onVoteCreate } = this.props;
    onVoteCreate && onVoteCreate(event);
  };

  public handleEmailVerification = (event: {
    verificationCode: string;
  }): void => {
    const { onEmailVerify } = this.props;
    onEmailVerify && onEmailVerify(event);
  };

  public handleEmailResend = (event: { emailAddress: string }) => {
    const { onEmailResend } = this.props;
    onEmailResend && onEmailResend(event);
  };

  public handleCloseCloud = () => {
    const { onClose } = this.props;
    onClose && onClose();
  };

  public getLastEmailAddress = (currentUser: User.User | undefined) => {
    if (!currentUser) return undefined;
    if (!currentUser.emails.length) return undefined;
    const firstConfirmedEmail = currentUser.emails.find(
      (email) => email.isConfirmed
    );
    if (firstConfirmedEmail) return firstConfirmedEmail.address;
    const n: number = currentUser.emails.length - 1;
    return currentUser.emails[n].address;
  };

  public handleFormCancel = () => {
    const { onClose } = this.props;
    onClose && onClose();
  };

  public handleFormUndo = () => {
    const { onUnselectVoteMethod } = this.props;
    onUnselectVoteMethod && onUnselectVoteMethod();
  };

  public handleVoteUndo = () => {
    const { onVoteDelete } = this.props;
    onVoteDelete && onVoteDelete();
  };

  public handleVoteViaEmailMethod = () => {
    const { onSelectEmailVoteMethod } = this.props;
    onSelectEmailVoteMethod && onSelectEmailVoteMethod();
  };

  public handleVoteViaFacebookMethod = (event: {
    accessToken: string;
    userID: string;
  }) => {
    const { onSelectFacebookVoteMethod } = this.props;
    onSelectFacebookVoteMethod && onSelectFacebookVoteMethod(event);
  };

  public handleUpdateAddVoteForm = (event: SetAddVoteFormActionPayload) => {
    const { onUpdateVoteForm } = this.props;
    onUpdateVoteForm && onUpdateVoteForm(event);
  };

  public handleUpdateEmailVerificationForm = (
    event: SetEmailVerificationFormActionPayload
  ) => {
    const { onUpdateEmailVerificationForm } = this.props;
    onUpdateEmailVerificationForm && onUpdateEmailVerificationForm(event);
  };

  public handleSuccessStageClose = () => {
    const { onClose } = this.props;
    onClose && onClose();
  };

  public handleVoteFormReset = () => {
    const { onResetVoteForm } = this.props;
    onResetVoteForm && onResetVoteForm();
  };

  public handleShare = () => {
    const { onShare } = this.props;
    onShare && onShare();
  };

  public render(): JSX.Element {
    const {
      sentToEmailAddress,
      userVoteType,
      addVoteForm,
      emailVerificationForm,
      selectedVoteMethodType,
      selectedVoteMethodValue,
      selectedCurrentUserFacebookAccount,
      currentUser,
      isCurrentUserVerified,
      isCurrentUserLimitReached,
      userVoteStatus,
      selectedVoteType,
      patchCurrentUserBody,
      verifyCodeBody,
      generateCodeBody,
      createCurrentVoteBody,
      createCurrentUserEmailBody,
      createCurrentUserFacebookAccountBody,
      putCurrentUserFacebookAccountBody,
      deleteCurrentVoteBody,
      lastEmailResendTimestamp,
      negativeVotesInLastThreeDays,
      positiveVotesInLastOneDay,
      addVoteAuth,
      onShare,
    } = this.props;

    const voteStageType: VoteStageType = this.getVoteStageType({
      currentUser,
      userVoteStatus,
      selectedVoteMethodType,
      isCurrentUserVerified,
      isCurrentUserLimitReached,
    });
    const voteStage: {
      header: string;
      iconName: string;
      iconBackground: string;
    } = voteStages[voteStageType];

    return (
      <Cloud
        className="SchoolDetailsVoteCloud"
        isOpened={Boolean(selectedVoteType)}
        onClose={this.handleCloseCloud}
      >
        <div className="header">
          <div
            className="status"
            style={{ backgroundColor: voteStage.iconBackground }}
          >
            <i className={`status-icon ${voteStage.iconName}`}></i>
          </div>
          <h3>{voteStage.header}</h3>
        </div>
        <div className="content">
          {voteStageType === VoteStageType.CAPTCHA_VERIFICATION && (
            <SchoolDetailsVoteCloudRecaptchaStage />
          )}
          {voteStageType === VoteStageType.METHOD_SELECTION && (
            <SchoolDetailsVoteCloudAuthStage
              addVoteAuth={addVoteAuth}
              selectedVoteType={selectedVoteType}
              createCurrentUserFacebookAccountBody={
                createCurrentUserFacebookAccountBody
              }
              putCurrentUserFacebookAccountBody={
                putCurrentUserFacebookAccountBody
              }
              onVoteViaEmailMethod={this.handleVoteViaEmailMethod}
              onVoteViaFacebookMethod={this.handleVoteViaFacebookMethod}
              onCancel={this.handleCloseCloud}
            />
          )}
          {voteStageType === VoteStageType.FORM && (
            <SchoolDetailsVoteCloudFormStage
              addVoteForm={addVoteForm}
              defaultEmailAddress={this.getLastEmailAddress(currentUser)}
              defaultFirstName={currentUser?.firstName}
              selectedVoteType={selectedVoteType}
              selectedVoteMethodType={selectedVoteMethodType}
              selectedVoteMethodValue={selectedVoteMethodValue}
              selectedCurrentUserFacebookAccount={
                selectedCurrentUserFacebookAccount
              }
              patchCurrentUserBody={patchCurrentUserBody}
              createCurrentUserEmailBody={createCurrentUserEmailBody}
              createCurrentVoteBody={createCurrentVoteBody}
              onVote={this.handleFormStageSubmit}
              onFormUndo={this.handleFormUndo}
              onFormReset={this.handleVoteFormReset}
              onUpdateVoteForm={this.handleUpdateAddVoteForm}
            />
          )}
          {voteStageType === "mail_verification" && sentToEmailAddress && (
            <SchoolDetailsVoteCloudMailVerificationStage
              emailVerificationForm={emailVerificationForm}
              selectedVoteType={selectedVoteType}
              sentToEmailAddress={sentToEmailAddress}
              verifyCodeBody={verifyCodeBody}
              deleteCurrentVoteBody={deleteCurrentVoteBody}
              generateCodeBody={generateCodeBody}
              lastEmailResendTimestamp={lastEmailResendTimestamp}
              onEmailVerify={this.handleEmailVerification}
              onEmailResend={this.handleEmailResend}
              onUpdateEmailVerificationForm={
                this.handleUpdateEmailVerificationForm
              }
              onVoteUndo={this.handleVoteUndo}
            />
          )}
          {voteStageType === "success" && (
            <SchoolDetailsVoteCloudSuccessStage
              selectedVoteType={selectedVoteType}
              onClose={this.handleSuccessStageClose}
              onShare={this.handleShare}
            />
          )}
          {voteStageType === "limit_reached" && (
            <SchoolDetailsVoteCloudLimitReachedStage
              selectedVoteType={selectedVoteType}
              negativeVotesInLastThreeDays={negativeVotesInLastThreeDays}
              positiveVotesInLastOneDay={positiveVotesInLastOneDay}
            />
          )}
          {voteStageType === "error" && (
            <SchoolDetailsVoteCloudErrorStage
              selectedVoteType={selectedVoteType}
            />
          )}
          <GoogleReCaptcha onVerify={this.handleGoogleReCaptchaVerify} />
        </div>
      </Cloud>
    );
  }
}

export default SchoolDetailsVoteCloud;
