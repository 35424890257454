import * as React from "react";
import { IAddSchoolState } from "./IAddSchoolState";
import { IAddSchoolProps } from "./IAddSchoolProps";
import "./AddSchool.scss";
import WelcomeAddSchool from "../../entities/WelcomeAddSchool/WelcomeAddSchool";
import AddSchoolForm from "../../entities/AddSchoolForm/AddSchoolForm";

class AddSchool extends React.Component<IAddSchoolProps, IAddSchoolState> {
  constructor(props: IAddSchoolProps) {
    super(props);
    this.state = {};
  }

  public render(): JSX.Element {
    return (
      <div className={`addSchool`}>
        <div className="top">
          <div>
            <WelcomeAddSchool />
          </div>
          <div>
            <AddSchoolForm />
          </div>
        </div>
        <div className="bottom"></div>
      </div>
    );
  }
}

export default AddSchool;
