import * as React from "react";
import { ISizeProviderState } from "./ISizeProviderState";
import {
  ISizeProviderDispatchProps,
  ISizeProviderProps,
  ISizeProviderStateProps,
} from "./ISizeProviderProps";
import "./SizeProvider.scss";
import { AppDispatch, AppState } from "../../../store/store";
import { connect } from "react-redux";
import { setWindowSizeAction } from "../../../store/@ui/ui.actions";

const mapStateToProps = (state: AppState): ISizeProviderStateProps => ({});

const mapDispatchToProps = (
  dispatch: AppDispatch
): ISizeProviderDispatchProps => ({
  onWindowSizeChange: (windowWidth: number, windowHeight: number) => {
    dispatch(setWindowSizeAction({ windowWidth, windowHeight }));
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);

class SizeProvider extends React.Component<
  ISizeProviderProps,
  ISizeProviderState
> {
  resizeTimer: NodeJS.Timeout | undefined;
  constructor(props: ISizeProviderProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.handleConfirmedResizePage();
    window.addEventListener("resize", this.handleResizePage);
  }

  public handleResizePage = () => {
    this.resizeTimer && clearTimeout(this.resizeTimer);
    this.resizeTimer = setTimeout(() => {
      this.handleConfirmedResizePage();
    }, 250);
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  public handleConfirmedResizePage = () => {
    const { innerWidth: width, innerHeight: height } = window;
    const { onWindowSizeChange } = this.props;
    onWindowSizeChange && onWindowSizeChange(width, height);
  };

  public render(): JSX.Element {
    const { children } = this.props;
    return <>{children}</>;
  }
}

export default connector(SizeProvider);
