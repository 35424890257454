import * as React from "react";
import { ISchoolDetailsBottomState } from "./ISchoolDetailsBottomState";
import { ISchoolDetailsBottomProps } from "./ISchoolDetailsBottomProps";
import "./SchoolDetailsBottom.scss";
import SchoolDetailsVoteCloud from "../SchoolDetailsVoteCloud/SchoolDetailsVoteCloud";
import VoteButton from "../VoteButton/VoteButton";
import {
  SetAddVoteFormActionPayload,
  SetEmailVerificationFormActionPayload,
} from "../../../store/@ui/ui.actions";

class SchoolDetailsBottom extends React.Component<
  ISchoolDetailsBottomProps,
  ISchoolDetailsBottomState
> {
  constructor(props: ISchoolDetailsBottomProps) {
    super(props);
    this.state = {};
  }

  private handleVoteCloudClose = () => {
    const {
      onChangeSelectedVoteMethod,
      onChangeSelectedVoteType,
      onResetVoteForm,
      onResetVoteAuth,
    } = this.props;
    onResetVoteAuth && onResetVoteAuth();
    onResetVoteForm && onResetVoteForm();
    onChangeSelectedVoteMethod && onChangeSelectedVoteMethod(undefined);
    onChangeSelectedVoteType && onChangeSelectedVoteType(undefined);
  };

  private handleVoteCreate = (event: {
    method: Vote.VoteMethod;
    firstName: string | undefined;
    comment: string | undefined;
  }) => {
    const { onVoteCreate } = this.props;
    onVoteCreate && onVoteCreate(event);
  };

  private handleEmailVerify = (event: { verificationCode: string }) => {
    const { onEmailVerify } = this.props;
    onEmailVerify && onEmailVerify(event);
  };

  private handleEmailResend = (event: { emailAddress: string }) => {
    const { onEmailResend } = this.props;
    onEmailResend && onEmailResend(event);
  };

  private handleVoteDelete = () => {
    const { onVoteDelete, currentUserVoteInSelectedSchool } = this.props;
    if (!currentUserVoteInSelectedSchool) return;
    const id: string = currentUserVoteInSelectedSchool._id;
    onVoteDelete && onVoteDelete({ id });
  };

  private handleVoteButtonSelected = (voteType: System.VoteType) => {
    const { onChangeSelectedVoteType } = this.props;
    onChangeSelectedVoteType && onChangeSelectedVoteType(voteType);
  };

  private handleVoteButtonUnselected = (voteType: System.VoteType) => {
    const { onChangeSelectedVoteType } = this.props;
    onChangeSelectedVoteType && onChangeSelectedVoteType(undefined);
  };

  public handleSelectEmailVoteMethod = () => {
    const { onSelectEmailVoteMethod } = this.props;
    onSelectEmailVoteMethod && onSelectEmailVoteMethod();
  };

  public handleSelectFacebookVoteMethod = (event: {
    accessToken: string;
    userID: string;
  }) => {
    const { onSelectFacebookVoteMethod } = this.props;
    onSelectFacebookVoteMethod && onSelectFacebookVoteMethod(event);
  };

  public handleUnselectVoteMethod = () => {
    const { onUnselectVoteMethod, onResetVoteForm } = this.props;
    onResetVoteForm && onResetVoteForm();
    onUnselectVoteMethod && onUnselectVoteMethod();
  };

  public handleUpdateAddVoteForm = (event: SetAddVoteFormActionPayload) => {
    const { onUpdateVoteForm } = this.props;
    onUpdateVoteForm && onUpdateVoteForm(event);
  };

  public handleUpdateEmailVerificationForm = (
    event: SetEmailVerificationFormActionPayload
  ) => {
    const { onUpdateEmailVerificationForm } = this.props;
    onUpdateEmailVerificationForm && onUpdateEmailVerificationForm(event);
  };

  public handleResetVoteForm = () => {};

  public handleShare = () => {
    const { onShare } = this.props;
    onShare && onShare();
  };

  public render(): JSX.Element {
    const {
      currentUserVoteInSelectedSchool,
      currentUserVoteStatusInSelectedSchool,
      negativeVotesInLastThreeDays,
      positiveVotesInLastOneDay,
      isCurrentUserLimitReached,
      currentUser,
      isCurrentUserVerified,
      addVoteForm,
      emailVerificationForm,
      getSchoolDetailsBody,
      selectedVoteType,
      patchCurrentUserBody,
      verifyCodeBody,
      generateCodeBody,
      createCurrentVoteBody,
      createCurrentUserFacebookAccountBody,
      putCurrentUserFacebookAccountBody,
      deleteCurrentVoteBody,
      createCurrentUserEmailBody,
      lastEmailResendTimestamp,
      selectedVoteMethodType,
      selectedVoteMethodValue,
      selectedCurrentUserFacebookAccount,
      addVoteAuth,
    } = this.props;

    return (
      <div className={`schoolDetailsBottom`}>
        {getSchoolDetailsBody.status !== "pending" &&
          getSchoolDetailsBody.status !== "error" && (
            <>
              <SchoolDetailsVoteCloud
                sentToEmailAddress={
                  currentUserVoteInSelectedSchool &&
                  currentUserVoteInSelectedSchool.method.type === "email"
                    ? currentUserVoteInSelectedSchool.method.value
                    : undefined
                }
                userVoteStatus={currentUserVoteStatusInSelectedSchool}
                userVoteType={currentUserVoteInSelectedSchool?.type}
                addVoteForm={addVoteForm}
                emailVerificationForm={emailVerificationForm}
                currentUser={currentUser}
                isCurrentUserVerified={isCurrentUserVerified}
                isCurrentUserLimitReached={isCurrentUserLimitReached}
                negativeVotesInLastThreeDays={negativeVotesInLastThreeDays}
                positiveVotesInLastOneDay={positiveVotesInLastOneDay}
                selectedVoteMethodType={selectedVoteMethodType}
                selectedCurrentUserFacebookAccount={
                  selectedCurrentUserFacebookAccount
                }
                selectedVoteMethodValue={selectedVoteMethodValue}
                patchCurrentUserBody={patchCurrentUserBody}
                deleteCurrentVoteBody={deleteCurrentVoteBody}
                createCurrentUserEmailBody={createCurrentUserEmailBody}
                createCurrentUserFacebookAccountBody={
                  createCurrentUserFacebookAccountBody
                }
                addVoteAuth={addVoteAuth}
                putCurrentUserFacebookAccountBody={
                  putCurrentUserFacebookAccountBody
                }
                createCurrentVoteBody={createCurrentVoteBody}
                verifyCodeBody={verifyCodeBody}
                generateCodeBody={generateCodeBody}
                selectedVoteType={selectedVoteType}
                lastEmailResendTimestamp={lastEmailResendTimestamp}
                onClose={this.handleVoteCloudClose}
                onUnselectVoteMethod={this.handleUnselectVoteMethod}
                onSelectEmailVoteMethod={this.handleSelectEmailVoteMethod}
                onSelectFacebookVoteMethod={this.handleSelectFacebookVoteMethod}
                onVoteCreate={this.handleVoteCreate}
                onVoteDelete={this.handleVoteDelete}
                onEmailVerify={this.handleEmailVerify}
                onEmailResend={this.handleEmailResend}
                onUpdateVoteForm={this.handleUpdateAddVoteForm}
                onUpdateEmailVerificationForm={
                  this.handleUpdateEmailVerificationForm
                }
                onResetVoteForm={this.handleResetVoteForm}
                onShare={this.handleShare}
              />
              <VoteButton
                className="vote-button-up"
                voteType={1}
                userVoteType={currentUserVoteInSelectedSchool?.type}
                userVoteStatus={currentUserVoteStatusInSelectedSchool}
                selectedVoteType={selectedVoteType}
                onVoteButtonSelected={this.handleVoteButtonSelected}
                onVoteButtonUnselected={this.handleVoteButtonUnselected}
              ></VoteButton>
              <VoteButton
                className="vote-button-up"
                voteType={-1}
                userVoteType={currentUserVoteInSelectedSchool?.type}
                userVoteStatus={currentUserVoteStatusInSelectedSchool}
                selectedVoteType={selectedVoteType}
                onVoteButtonSelected={this.handleVoteButtonSelected}
                onVoteButtonUnselected={this.handleVoteButtonUnselected}
              ></VoteButton>
            </>
          )}
      </div>
    );
  }
}

export default SchoolDetailsBottom;
