const getInitials = (payload: {
  schoolName: string;
  schoolCity: string;
}): string => {
  const initials: string = payload.schoolName
    .split(" ")
    .map((word) => word[0])
    .concat(payload.schoolCity && payload.schoolCity[0])
    .join("")
    .substring(0, 4)
    .toUpperCase();
  return initials;
};

const getFullName = (
  school:
    | { name: string; city: string; provinceType: School.ProvinceType }
    | undefined
): string => `${school?.name} ${school?.city} ${school?.provinceType}`;

const filterGradesBySchoolId = (payload: {
  schoolId: string;
  apiGrades: Api.Grade[];
}): Api.Grade[] => {
  if (payload.apiGrades.length === 0) return [];
  const filteredApiGrades: Api.Grade[] = payload.apiGrades.filter(
    (apiGrade) =>
      apiGrade.addedToSchoolId === payload.schoolId && apiGrade.isIncluded
  );

  return filteredApiGrades;
};

const getGradesAvg = (payload: {
  schoolId: string;
  apiGrades: Api.Grade[];
}): number => {
  if (payload.apiGrades.length === 0) return 0;
  let sum: number = 0;
  const filteredApiGrades: Api.Grade[] = payload.apiGrades.filter(
    (apiGrade) =>
      apiGrade.addedToSchoolId === payload.schoolId && apiGrade.isIncluded
  );

  filteredApiGrades.forEach((apiGrade) => {
    sum += apiGrade.type;
  });
  if (filteredApiGrades.length === 0) return 0;
  const avg: number = sum / filteredApiGrades.length;
  return avg;
};

const filterVotesBySchoolId = (payload: {
  schoolId: string;
  apiVotes: Vote.Vote[];
}): Vote.Vote[] => {
  const votes: Vote.Vote[] = payload.apiVotes.filter(
    (apiVote) =>
      apiVote.addedToSchoolId === payload.schoolId && apiVote.isIncluded
  );
  return votes;
};

const filterVotesByType = (payload: {
  voteType: System.VoteType;
  apiVotes: Vote.Vote[];
}): Vote.Vote[] => {
  const votes: Vote.Vote[] = payload.apiVotes.filter(
    (apiVote) => apiVote.type === payload.voteType && apiVote.isIncluded
  );
  return votes;
};

export type min = number;
export type max = number;

const filterGoogleReviewsByRate = (payload: {
  rateRange: [min, max];
  googleReviews: Array<School.GoogleReview>;
}): School.GoogleReview[] => {
  const googleReviews: School.GoogleReview[] = payload.googleReviews.filter(
    (review) =>
      review.rate >= payload.rateRange[0] && review.rate <= payload.rateRange[1]
  );

  return googleReviews;
};

const countScore = (payload: {
  statScore: number | undefined;
  votesUpCount: number;
  votesDownCount: number;
  gradesAvg: number;
  googleRatesUpCount: number;
  googleRatesDownCount: number;
}): number => {
  const statScore: number = payload.statScore || 0;
  const voteScore: number = payload.votesUpCount - payload.votesDownCount;
  const gradeScore: number = Math.ceil(payload.gradesAvg * 10);
  const googleScore: number =
    payload.googleRatesUpCount - payload.googleRatesDownCount;
  const score: number = statScore + voteScore + gradeScore + googleScore;
  return score;
};

const mapGradesToExpertsOpnions = (payload: {
  apiSchoolGrades: Api.Grade[];
  appExperts: App.Expert[];
}): App.SchoolDetailsExpertOpinion[] => {
  const expertsOpinions: App.SchoolDetailsExpertOpinion[] = payload.apiSchoolGrades
    .filter((schoolGrade) => schoolGrade.opinion)
    .map((schoolGrade) => ({
      expert: payload.appExperts.find(
        (expert) => expert._id === schoolGrade.addedByExpertId
      ) as App.Expert, // * filter nizej gwarantuje ze jest to Expert
      gradeType: schoolGrade.type,
      opinion: schoolGrade.opinion as string, // * filter wyzej gwarantuje ze jest to string
    }))
    .filter((expertOpinion) => expertOpinion.expert as App.Expert);

  return expertsOpinions;
};

const mapVotesToUserComments = (payload: {
  apiSchoolVotes: Vote.Vote[];
  appUsers: App.User[];
}): App.SchoolDetailsUsersComment[] => {
  const userComments: App.SchoolDetailsUsersComment[] = payload.apiSchoolVotes
    .filter((schoolVote) => schoolVote.comment)
    .map((schoolVote) => ({
      user: payload.appUsers.find(
        (user) => user._id === schoolVote.addedByUserId
      ) as App.User, // * filter nizej gwarantuje ze jest to User
      voteType: schoolVote.type,
      firstName: schoolVote.firstName,
      comment: schoolVote.comment as string, // * filter wyzej gwarantuje ze jest to string
      created_at: schoolVote.created_at,
    }))
    .filter((userComment) => userComment.user);
  return userComments;
};

export default {
  getInitials,
  getFullName,
  filterGradesBySchoolId,
  getGradesAvg,
  filterVotesBySchoolId,
  filterVotesByType,
  filterGoogleReviewsByRate,
  countScore,
  mapGradesToExpertsOpnions,
  mapVotesToUserComments,
};
