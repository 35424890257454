import * as React from "react";
import { ISelectState } from "./ISelectState";
import { ISelectProps } from "./ISelectProps";
import "./Select.scss";

class Select extends React.Component<ISelectProps, ISelectState> {
  constructor(props: ISelectProps) {
    super(props);
    this.state = {};
  }

  public handleChangeSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { target } = event;
    const { onChange } = this.props;
    const value: string | undefined =
      target.value === "_undefined" ? undefined : target.value;
    onChange && onChange({ value });
  };

  public render(): JSX.Element {
    const { options, value, helperText, error, ...rest } = this.props;
    const _value: string = value ? value : "_undefined";
    const _options: Array<{
      value: string;
      text: string;
    }> = options.map((option) =>
      option.value ? option : { ...option, value: "_undefined" }
    ) as Array<{
      value: string;
      text: string;
    }>;
    return (
      <div className={`Select ${error && "select-error"}`}>
        <select {...rest} value={_value} onChange={this.handleChangeSelect}>
          {_options.map((option, i) => (
            <option key={i} value={option.value}>
              {option.text}
            </option>
          ))}
        </select>
        <p className="helperText">{helperText}</p>
      </div>
    );
  }
}

export default Select;
