import * as React from "react";
import { IRankSearchState } from "./IRankSearchState";
import {
  IRankSearchDispatchProps,
  IRankSearchProps,
  IRankSearchStateProps,
} from "./IRankSearchProps";
import "./RankSearch.scss";
import Bubble from "../../shared/Bubble/Bubble";
import { AppDispatch, AppState } from "../../../store/store";
import {
  getIsFilteredSchools,
  getProvinceTypeFilter,
  getSchoolNameFilter,
} from "../../../store/@ui/ui.selectors";
import {
  setProvinceTypeFilterAction,
  setSchoolNameFilterAction,
} from "../../../store/@ui/ui.actions";
import { connect } from "react-redux";

import provinces from "../../../assets/data/provinces.data.json";
import { getProvincesTypes } from "../../../helpers/helpers";
import { getGetSchoolPreviewsBody } from "../../../store/school/school.selectors";
import IconPending from "../../shared/IconPending/IconPending";
import schoolsOperations from "../../../store/school/schools.operations";

const mapStateToProps = (appState: AppState): IRankSearchStateProps => ({
  getSchoolPreviewsBody: getGetSchoolPreviewsBody(appState),
  provinceTypeFilter: getProvinceTypeFilter(appState),
  schoolNameFilter: getSchoolNameFilter(appState),
  isFilteredSchools: getIsFilteredSchools(appState),
});

const mapDispatchToProps = (
  dispatch: AppDispatch
): IRankSearchDispatchProps => ({
  onSchoolNameFilterChange: (schoolName) => {
    return dispatch(setSchoolNameFilterAction({ schoolName }));
  },
  onProvinceTypeFilterChange: (provinceType) => {
    return dispatch(setProvinceTypeFilterAction({ provinceType }));
  },

  onFetchSchools: (schoolNameFilter, provinceTypeFilter) => {
    dispatch(
      schoolsOperations.fetchSchoolPreviewsOperation({
        start: undefined,
        end: undefined,
        mode: "set",
        schoolNameFilter,
        provinceTypeFilter,
      })
    );
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);

class RankSearch extends React.Component<IRankSearchProps, IRankSearchState> {
  schoolNameFilterTimer: NodeJS.Timeout | undefined;
  constructor(props: IRankSearchProps) {
    super(props);
    this.state = {
      schoolNameFilter: undefined,
      oldSchoolNameFilter: undefined,
    };
  }

  componentDidUpdate(oldProps: IRankSearchProps, oldState: IRankSearchState) {
    const { props } = this;
    const { state } = this;
    if (props.schoolNameFilter !== oldProps.schoolNameFilter) {
      this.handleFilterChange(oldProps);
    }
    if (props.provinceTypeFilter !== oldProps.provinceTypeFilter) {
      this.handleFilterChange(oldProps);
    }
    if (state.schoolNameFilter !== oldState.schoolNameFilter) {
      this.handleSchoolNameFilterStateChange();
    }
  }

  public handleSchoolNameFilterStateChange = () => {
    const { props, state } = this;
    const { onSchoolNameFilterChange } = this.props;
    const { schoolNameFilter } = this.state;
    if (!schoolNameFilter) return onSchoolNameFilterChange(schoolNameFilter);
    if (!schoolNameFilter.length)
      return onSchoolNameFilterChange(schoolNameFilter);
  };

  public handleFilterChange = (oldProps: IRankSearchProps) => {
    const {
      onFetchSchools,
      getSchoolPreviewsBody,
      schoolNameFilter,
      provinceTypeFilter,
      isFilteredSchools,
    } = this.props;
    const isPending: boolean = getSchoolPreviewsBody.status === "pending";
    !isPending &&
      isFilteredSchools &&
      onFetchSchools(schoolNameFilter, provinceTypeFilter);
    this.setState({ schoolNameFilter });
  };

  public handleRankSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { props } = this;
    const { getSchoolPreviewsBody } = props;
    const { value } = event.target;
    const schoolNameFilter = value.length ? value : undefined;
    if (getSchoolPreviewsBody.status === "pending") return;
    this.setState({ schoolNameFilter });
  };

  private handleProvinceTypeSelectChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { props, state } = this;
    const { onProvinceTypeFilterChange, onSchoolNameFilterChange } = props;
    const { schoolNameFilter } = state;
    const { value } = event.target;
    const provinceTypeFilter = (value !== "all" ? value : undefined) as
      | School.ProvinceType
      | undefined;
    onProvinceTypeFilterChange(provinceTypeFilter);
    onSchoolNameFilterChange(schoolNameFilter);
  };

  private handleRankSearchInputKeyUp = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    const { onSchoolNameFilterChange } = this.props;
    const { schoolNameFilter } = this.state;
    if (event.key !== "Enter") return;
    onSchoolNameFilterChange(schoolNameFilter);
  };

  public render(): JSX.Element {
    const { props, state } = this;
    const {
      provinceTypeFilter,
      getSchoolPreviewsBody,
      isFilteredSchools,
    } = props;
    const isPending: boolean =
      getSchoolPreviewsBody.status === "pending" && isFilteredSchools;
    return (
      <Bubble className="RankSearch" ready={true}>
        <div className="rank-search-inner">
          <div className={`rankSearch`}>
            <div className="input-wrapper">
              <div className="input-wrapper-top">
                {isPending ? (
                  <IconPending isPending={isPending} />
                ) : (
                  <i className="icon-search"></i>
                )}
                <input
                  minLength={4}
                  maxLength={114}
                  className={`rank-search-input ${isPending && "disabled"}`}
                  type="text"
                  placeholder="Szukaj szkoły..."
                  value={state.schoolNameFilter || ""}
                  onChange={this.handleRankSearchInputChange}
                  onKeyUp={this.handleRankSearchInputKeyUp}
                />
              </div>
              <div className="input-wrapper-bottom">
                <span className="info">
                  Wpisz frazę i naciśnij enter, aby wyszukać...
                </span>
              </div>
            </div>

            <select
              className="rank-search-filter"
              value={provinceTypeFilter ? provinceTypeFilter : "all"}
              onChange={this.handleProvinceTypeSelectChange}
              disabled={isPending}
            >
              <option value={"all"}>Wybierz województwo...</option>
              {getProvincesTypes(provinces).map(
                (provinceType: System.ProvinceType) => (
                  <option key={provinceType} value={provinceType}>
                    {provinces[provinceType]}
                  </option>
                )
              )}
            </select>
          </div>
        </div>
      </Bubble>
    );
  }
}

export default connector(RankSearch);
