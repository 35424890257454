import * as React from "react";
import { IBubbleState } from "./IBubbleState";
import { IBubbleProps } from "./IBubbleProps";
import "./Bubble.scss";
import { motion } from "framer-motion";
import { rand } from "../../../utils/utils";

class Bubble extends React.Component<IBubbleProps, IBubbleState> {
  constructor(props: IBubbleProps) {
    super(props);
    this.state = {
      mainAnimationComplete: false,
    };
  }

  private handleAnimationComplete = () => {
    const { onBubbleAnimationComplete } = this.props;
    onBubbleAnimationComplete && onBubbleAnimationComplete();
    this.setState({
      mainAnimationComplete: true,
    });
  };

  private getAnimate = (ready: boolean | undefined) => {
    return ready
      ? {
          scale: [0.95, 1.005, 0.98, 1.005, 0.99, 1],
        }
      : undefined;
  };

  public render(): JSX.Element {
    const { children, className, style, ready, bubbleRef } = this.props;
    const { mainAnimationComplete } = this.state;
    const duration: number = rand(70, 100) / 100;
    const delay: number = rand(0, 40) / 100;
    return (
      <div className={`bubble ${className}`} style={style} ref={bubbleRef}>
        <motion.div
          className="motion-bubble"
          transition={{ duration, delay }}
          animate={this.getAnimate(ready)}
          onAnimationComplete={this.handleAnimationComplete}
        >
          <motion.div className="motion-bubble-inner">{children}</motion.div>
        </motion.div>
      </div>
    );
  }
}

export default Bubble;
