import * as React from "react";
import { ISchoolBackgroundState } from "./ISchoolBackgroundState";
import { ISchoolBackgroundProps } from "./ISchoolBackgroundProps";
import "./SchoolBackground.scss";
import { motion } from "framer-motion";

class SchoolBackground extends React.Component<
  ISchoolBackgroundProps,
  ISchoolBackgroundState
> {
  constructor(props: ISchoolBackgroundProps) {
    super(props);
    this.state = {};
  }

  public handleShareClick = () => {
    const { onShare } = this.props;
    onShare && onShare();
  };

  public render(): JSX.Element {
    const {
      children,
      backgroundPhotoUrl,
      className,
      minimalize,
      isPending,
    } = this.props;

    return (
      <motion.div
        className={`schoolBackground ${className}`}
        animate={minimalize ? "minimalized" : "maximalized"}
        initial={false}
        variants={{
          minimalized: { height: `80px` },
          maximalized: { height: `150px` },
        }}
        transition={{ duration: 0.8 }}
      >
        {!isPending && (
          <div className="controlls">
            <i className="icon-share" onClick={this.handleShareClick}></i>
          </div>
        )}
        <motion.div
          key={String(minimalize)}
          className="background"
          style={{
            backgroundImage: isPending
              ? undefined
              : `url(${backgroundPhotoUrl})`,
          }}
          animate={{
            opacity: minimalize ? [1, 0] : [0, 1],
          }}
          transition={{ duration: 0.8 }}
        ></motion.div>
        <div className="foreground">{children}</div>
      </motion.div>
    );
  }
}

export default SchoolBackground;
