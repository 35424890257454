import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";
import schoolReducers from "./school.reducers";

export type SchoolState = {
  schoolPreviews: School.SchoolPreview[] | undefined;
  filteredSchoolPreviews: School.SchoolPreview[] | undefined;
  selectedSchoolDetails: School.SchoolDetails | undefined;
  getSchoolDetailsBody: School.Res.GetSchoolDetailsByFullNameBody;
  getSchoolPreviewsBody: School.Res.GetSchoolPreviewsBody;
  createMySchoolBody: School.Res.CreateMySchoolBody;
};

const initialState: SchoolState = {
  schoolPreviews: undefined,
  filteredSchoolPreviews: undefined,
  selectedSchoolDetails: undefined,
  getSchoolDetailsBody: { status: "empty" },
  getSchoolPreviewsBody: { status: "empty" },
  createMySchoolBody: { status: "empty" },
};

export const schoolSlice: Slice<SchoolState> = createSlice({
  name: "school",
  initialState,
  reducers: {
    ...schoolReducers,
  },
});

/** @deprecated */
export const {
  setSchools,
  addSchool,
  updateSchool,
  setSelectedSchoolId,
  unsetSelectedSchoolId,
} = schoolSlice.actions;

export default schoolSlice.reducer;
