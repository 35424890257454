import * as React from "react";
import { ILogoState } from "./ILogoState";
import { ILogoProps } from "./ILogoProps";
import "./Logo.scss";
import LogoIcon from "../LogoIcon/LogoIcon";

class Logo extends React.Component<ILogoProps, ILogoState> {
  constructor(props: ILogoProps) {
    super(props);
    this.state = {};
  }

  public render(): JSX.Element {
    return (
      <div className={`Logo`}>
        <LogoIcon />
        <h1>
          <span className="first">szkoly</span>
          <span className="second animating">
            technologiczne<span className="second-end">.pl</span>
          </span>
        </h1>
      </div>
    );
  }
}

export default Logo;
