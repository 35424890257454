import React from "react";

class PageRegulation extends React.Component {
  render() {
    return (
      <p className="PageRegulation">
        <ol>
          <li>
            I. Postanowienia ogólne
            <ol>
              <li>
                Szkolytechnologiczne.pl (dalej “Aplikacja”) prowadzi ranking
                szkół technologicznych (dalej “Ranking”) na podstawie
                niniejszego regulaminu (dalej ”Regulamin”).
              </li>
              <li>
                Administratorem Aplikacji jest Techni Schools sp. z o.o. z
                siedzibą w Lublinie (adres siedziby i adres do korespondencji:
                ul. Związkowa 26, 20-148 Lublin), zarejestrowana w Rejestrze
                Przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez
                Sąd Rejonowy Lublin-Wschód w Lublinie z siedzibą w Świdniku, VI
                Wydział Gospodarczy KRS pod numerem 0000803622, wysokość
                kapitału zakładowego 100 000 PLN, NIP: 7123392662, REGON:
                384348866 (dalej: Administrator).
              </li>
              <li>
                Osoba, która może brać udział w tworzeniu Rankingu to osoba
                fizyczna, która ukończyła 13 lat (dalej “Użytkownik”).
              </li>
              <li>
                W Rankingu uczestniczą szkoły średnie o profilu technologicznym
                (dalej “Szkoły”)
              </li>
              <li>
                Ranking prowadzony jest na terenie Rzeczpospolitej Polskiej.
              </li>
              <li>
                Celem Rankingu jest utworzenie zestawienia najlepszych szkół
                technologicznych w Polsce. II. Ranking
              </li>
            </ol>
          </li>

          <li>
            II. Ranking
            <ol>
              <li>
                Szkoły mogą być zgłaszane przez każdego Użytkownika w formularzu
                zgłoszeniowym (dalej: “Formularz”) dostępnym w Aplikacji. Do
                zaakceptowania zgłoszenia niezbędne jest potwierdzenie swojego
                adresu e-mail. Potwierdzenie następuje po kliknięciu linku
                aktywacyjnego, dostępnego w mailu po wypełnieniu Formularza.
              </li>
              <li>
                Niezwłocznie po dodaniu zgłoszenia Administrator poinformuje
                Szkołę o Rankingu i jego zasadach oraz zaprosi Szkołę do udziału
                w Rankingu. Szkoła pojawi się w Rankingu dopiero po wyrażeniu
                zgody na uczestnictwo.
              </li>
              <li>
                Użytkownik może skomentować swoją ocenę w Aplikacji. Organizator
                zastrzega sobie usuwanie komentarzy, które są wulgarne,
                obraźliwe, lub naruszają Regulamin lub prawa osób trzecich.
              </li>
              <li>Na główną ocenę składają się trzy składowe:</li>
              <ol>
                <li>
                  punkty podstawowe, czyli punkty przyznane na postawie wyników
                  z matury, egzaminów zawodowych oraz olimpiad;
                </li>
                <li>
                  opinie ekspertów, czyli punkty przyznane od ekspertów
                  wymienionych w Aplikacji w zakładce “Eksperci”, liczone na
                  podstawie średniej arytmetycznej wszystkich ocen;
                </li>
                <li>
                  głosy, czyli punkty przyznane na postawie głosów od
                  Użytkowników oraz opinii pobranych z Google.
                </li>
              </ol>
            </ol>
          </li>
          <li>
            III. Postanowienia końcowe
            <ol>
              <li>
                Każdy Użytkownik i Szkoła przystępujący do Rankingu akceptują
                niniejszy Regulamin i zobowiązują się do jego przestrzegania.
              </li>
              <li>
                Administrator zastrzega sobie prawo do zmiany Regulaminu. Zmiany
                zostaną opublikowane w postaci tekstu jednolitego wraz z
                informacją o ich dokonaniu na www.szkolytechnologiczne.pl.
              </li>
              <li>
                Nowe brzmienie Regulaminu obowiązuje od dnia opublikowania
                tekstu jednolitego Regulaminu.
              </li>
              <li>
                Załącznik nr 1 - Polityka prywatności, stanowi nieodłączną część
                niniejszego Regulaminu.
              </li>
              <li>
                W sprawach nieuregulowanych niniejszym regulaminem, zastosowanie
                znajdują powszechnie obowiązujące przepisy prawa.
              </li>
            </ol>
          </li>
        </ol>
      </p>
    );
  }
}

export default PageRegulation;
