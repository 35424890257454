import * as React from "react";
import { IFacebookDeletionStatusState } from "./IFacebookDeletionStatusState";
import {
  IFacebookDeletionStatusDispatchProps,
  IFacebookDeletionStatusProps,
  IFacebookDeletionStatusStateProps,
} from "./IFacebookDeletionStatusProps";
import "./FacebookDeletionStatus.scss";
import Bubble from "../../shared/Bubble/Bubble";
import Icon from "../../shared/Icon/Icon";
import { Button, Input } from "@material-ui/core";

import userService from "../../../store/user/users.service";
import { AppDispatch, AppState } from "../../../store/store";
import { connect } from "react-redux";
import {
  updateCurrentUserFacebookAccountAction,
  updateCurrentUserFirstNameAction,
} from "../../../store/user/users.actions";
import IconPending from "../../shared/IconPending/IconPending";

const { getDeleteFacebookAccountStatus } = userService;

const mapStateToProps = (
  appState: AppState
): IFacebookDeletionStatusStateProps => ({});

const mapDispatchToProps = (
  dispatch: AppDispatch
): IFacebookDeletionStatusDispatchProps => ({
  onUnsetFirstName: () => {
    dispatch(updateCurrentUserFirstNameAction({ firstName: undefined }));
  },
  onClearFacebookAccount: (userID: string) => {
    dispatch(
      updateCurrentUserFacebookAccountAction({
        userID,
        newAccount: {
          first_name: undefined,
          last_name: undefined,
          picture_url: undefined,
          email: undefined,
        },
      })
    );
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);

class FacebookDeletionStatus extends React.Component<
  IFacebookDeletionStatusProps,
  IFacebookDeletionStatusState
> {
  constructor(props: IFacebookDeletionStatusProps) {
    super(props);
    this.state = {
      confirmationCode: "",
      isPending: false,
      deletionStatus: undefined,
    };
  }

  public handleVerificationCodeInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    this.setState({ confirmationCode: value });
  };

  public handleCheckButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    const { confirmationCode } = this.state;
    this.onFetchFacebookDeletionStatus(confirmationCode);
  };

  public onFetchFacebookDeletionStatus = async (confirmationCode: string) => {
    const { onUnsetFirstName, onClearFacebookAccount } = this.props;
    this.setState({ isPending: true });
    const resBody: User.Res.GetDeleteUserFacebookAccountBody = await getDeleteFacebookAccountStatus(
      { confirmationCode }
    );
    this.setState({ isPending: false });
    if (!resBody.status) return this.setState({ deletionStatus: false });
    this.setState({ deletionStatus: resBody.payload?.deletionStatus });
    onUnsetFirstName();
    onClearFacebookAccount(confirmationCode);
  };

  public render(): JSX.Element {
    const { confirmationCode, isPending, deletionStatus } = this.state;
    return (
      <div className={`FacebookDeletionStatus`}>
        <div>
          <Bubble ready={true}>
            <div>
              <div className="icon-wrapper">
                <Icon></Icon>
              </div>
              <div className="info">
                <p>
                  Jeśli tutaj trafiłeś to prawdopodobnie dokonałeś usunięcia
                  swoich danych przekazanych nam przez aplikację Facebook.
                </p>
                <p>
                  W tym miejscu możesz sprawdzić status procesu usuwania tych
                  danych z bazy.
                </p>
              </div>
            </div>
          </Bubble>
          {deletionStatus === undefined && (
            <Bubble ready={true}>
              <div>
                <p>
                  <b>Wprowadź kod weryfikacyjny otrzymany przez Facebook:</b>
                </p>
                <Input
                  autoFocus={true}
                  placeholder={"Wpisz kod..."}
                  disabled={isPending}
                  onChange={this.handleVerificationCodeInputChange}
                />
                <Button
                  className="app-button check-fb-status"
                  disabled={
                    confirmationCode.length < 3 ||
                    confirmationCode.length > 255 ||
                    isPending
                  }
                  onClick={this.handleCheckButtonClick}
                >
                  <IconPending isPending={isPending} />
                  Sprawdź
                </Button>
              </div>
            </Bubble>
          )}
          {deletionStatus === true && (
            <Bubble>
              <div className="success-bar">
                <div>
                  <i className="icon-ok"></i>
                </div>
                <div>
                  <div>
                    <b>Nasza baza nie zwraca żadnych wyników.</b>
                  </div>
                  <div>
                    Oznacza to, że jeśli istniały dane przypisane pod
                    wprowadzony przez Ciebie kod weryfikacyjny to zostały już
                    usunięte.
                  </div>
                </div>
              </div>
            </Bubble>
          )}
          {deletionStatus === false && (
            <Bubble>
              <div className="error-bar">
                <div>
                  <i className="icon-cancel"></i>
                </div>
                <div>
                  <div>
                    <b>Dane nie zostały usunięte.</b>
                  </div>
                  <div>
                    Jeśli usunąłeś swoje dane i nadal widzisz ten komunikat
                    skontaktuj się z nami:{" "}
                    <b>kontakt@szkolytechnologiczne.pl</b>
                  </div>
                </div>
              </div>
            </Bubble>
          )}
        </div>
      </div>
    );
  }
}

export default connector(FacebookDeletionStatus);
