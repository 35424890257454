import * as React from "react";
import { ContactSection, IContactState } from "./IContactState";
import {
  IContactDispatchProps,
  IContactProps,
  IContactStateProps,
} from "./IContactProps";
import "./Contact.scss";
import Bubble from "../../shared/Bubble/Bubble";
import { Button } from "@material-ui/core";

import tabs from "./tabs.json";
import { withRouter } from "react-router";
import qs from "query-string";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { AppDispatch, AppState } from "../../../store/store";
import { connect } from "react-redux";
import { getCurrentUser } from "../../../store/user/users.selectors";
import { getCurrentUserVotes } from "../../../store/vote/votes.selectors";
import {
  isAnyEmailIsConfirmedForUser,
  isAnyFacebookAccountExistForUser,
} from "../../../store/user/users.helpers";
import Background from "../../shared/Background/Background";
import contents from "../../../assets/data/contents.data.json";

const mapStateToProps = (appState: AppState): IContactStateProps => ({
  currentUser: getCurrentUser(appState),
  currentVotes: getCurrentUserVotes(appState),
});

const mapDispatchToProps = (
  dispatch: AppDispatch
): IContactDispatchProps => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);

class Contact extends React.Component<IContactProps, IContactState> {
  constructor(props: IContactProps) {
    super(props);
    this.state = {
      animateTabs: false,
      selectedSection: undefined,
      previousSection: undefined,
    };
  }

  componentDidMount() {
    this.onChangeQuery();
  }

  componentDidUpdate(oldProps: IContactProps) {
    const { props } = this;
    if (props.location.search !== oldProps.location.search) {
      this.onChangeQuery();
    }
  }

  public onChangeQuery() {
    const { location } = this.props;
    const query = qs.parse(location.search);
    if (query.section) {
      this.setState({
        animateTabs: true,
        selectedSection: query.section as ContactSection,
        previousSection: this.state.selectedSection,
      });
    } else {
      this.setState({
        selectedSection: undefined,
        previousSection: this.state.selectedSection,
      });
    }
  }

  public render(): JSX.Element {
    const { currentUser, currentVotes } = this.props;
    const { selectedSection, animateTabs, previousSection } = this.state;
    const now: Date = new Date();
    const userCreatedAt: Date | undefined =
      currentUser && new Date(currentUser?.created_at);
    return (
      <div className={`Contact`}>
        <Background />
        <div className="main">
          <div>
            <Bubble className="welcome" ready={true}>
              <div className="content">
                <div
                  className="icon"
                  style={{
                    backgroundImage: `url(${contents["contact"].image_url})`,
                  }}
                ></div>
                <h1>{contents["contact"].header}</h1>
                <p>{contents["contact"].description}</p>
              </div>
            </Bubble>
          </div>
          <div>
            {tabs.map((tab, i) => (
              <Bubble key={i} className="info" ready={true}>
                <div className="content">
                  <div className="top">
                    <div className="title-box">
                      <i className={tab.iconName}></i>
                      <span>{tab.title}</span>
                    </div>
                    <Link
                      to={
                        selectedSection === tab.section ? "/contact" : tab.path
                      }
                    >
                      <Button className="app-button">
                        {selectedSection === tab.section ? "Ok" : "Tak!"}
                      </Button>
                    </Link>
                  </div>

                  <motion.div
                    className="bottom"
                    transition={{ duration: 0.7 }}
                    animate={
                      animateTabs &&
                      (selectedSection === tab.section ||
                        previousSection === tab.section) && {
                        opacity:
                          selectedSection === tab.section ? [0, 1] : [1, 0],
                        maxHeight:
                          selectedSection === tab.section ? [0, 350] : [350, 0],
                      }
                    }
                  >
                    <div>
                      <div>
                        <span className={`details ${tab.section}`}>
                          {tab.details}
                        </span>
                        {tab.section === "be-expert" && (
                          <div className="be-expert-extra">
                            <section>
                              <div>
                                <b>
                                  <span>Wymagania:</span>
                                </b>
                              </div>
                              <div className="requirements">
                                <li>
                                  <i
                                    className={
                                      currentUser && currentUser.isIncluded
                                        ? `icon-ok`
                                        : `icon-cancel`
                                    }
                                  ></i>
                                  <span>
                                    Musisz być autoryzowanym użytkownikiem
                                    aplikacji
                                  </span>
                                </li>
                                <li>
                                  <i
                                    className={
                                      isAnyEmailIsConfirmedForUser(
                                        currentUser
                                      ) ||
                                      isAnyFacebookAccountExistForUser(
                                        currentUser
                                      )
                                        ? `icon-ok`
                                        : `icon-cancel`
                                    }
                                  ></i>
                                  <span>
                                    Potwierdzony adres e-mail lub konto Facebook
                                  </span>
                                </li>
                                <li>
                                  <i
                                    className={
                                      currentUser?.firstName
                                        ? `icon-ok`
                                        : `icon-cancel`
                                    }
                                  ></i>
                                  <span>Podane imię</span>
                                </li>
                                <li>
                                  <i
                                    className={
                                      currentVotes &&
                                      currentVotes.filter(
                                        (vote) => vote.comment
                                      ).length >= 2
                                        ? `icon-ok`
                                        : `icon-cancel`
                                    }
                                  ></i>
                                  <span>
                                    Oddane przynajmniej dwa głosy z komentarzami
                                  </span>
                                </li>
                                <li>
                                  <i
                                    className={
                                      userCreatedAt &&
                                      (now.getTime() -
                                        userCreatedAt.getTime()) /
                                        1000 /
                                        60 /
                                        60 /
                                        24 >=
                                        7
                                        ? `icon-ok`
                                        : `icon-cancel`
                                    }
                                  ></i>
                                  <span>
                                    Twoja pierwsza wizyta u nas przynajmniej 7
                                    dni temu
                                  </span>
                                </li>
                              </div>
                            </section>
                            <section className="benefits">
                              <div>
                                <b>
                                  <span>Korzyści:</span>
                                </b>
                              </div>
                              <div>
                                <li>
                                  Twoje opinie trafią do oddzielnej zakładki
                                </li>
                                <li>
                                  Będziesz mógł przyznawać dodatkowe punkty dla
                                  szkół
                                </li>
                                <li>Przyczynisz się do czegoś tam</li>
                                <li>
                                  Twoja pierwsza wizyta u nas przynajmniej 7 dni
                                  temu
                                </li>
                              </div>
                            </section>
                            <section>
                              <span>
                                Uważasz, że powinieneś zostać ekspertem -
                                skontaktuj się z nami:{" "}
                                <b>kontakt@szkolytechnologiczne.pl</b>
                              </span>
                            </section>
                          </div>
                        )}
                      </div>
                    </div>
                  </motion.div>
                </div>
              </Bubble>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default connector(withRouter(Contact));
