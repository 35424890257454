import React from "react";

class PrivacyPolicy extends React.Component {
  render() {
    return (
      <p className="PrivacyPolicy">
        Dziękujemy za odwiedzenie naszej aplikacji udostępnianej pod adresem{" "}
        <a className="app-link" href="szkolytechnologiczne.pl">
          www.szkolytechnologiczne.pl
        </a>{" "}
        (dalej jako: SzkolyTechnologiczne.pl, Aplikacja).
        SzkolyTechnologiczne.pl to aplikacja zawierająca ranking szkół
        technologicznych w Polsce. Dążąc do uzyskania pełnej transparentności
        działań naszego Rankingu na stronie www przygotowaliśmy dla Państwa
        dokument, w którym znajdą Państwo wszystkie informacje na temat zasad i
        sposobów przetwarzania danych osobowych oraz wykorzystywania plików
        cookies.
        <ol>
          <li>
            Postanowienia ogólne
            <ol>
              <li>
                Niniejsza polityka prywatności ma charakter informacyjny, co
                oznacza, że nie jest ona źródłem obowiązków dla Użytkownika.
                Polityka prywatności zawiera przede wszystkim zasady dotyczące
                przetwarzania danych przez Administratora na Aplikacji, w tym
                podstawy, cele i zakres przetwarzania danych osobowych oraz
                prawa, których dane dotyczą, a także informacje w zakresie
                stosowania na Aplikacji plików cookies oraz narzędzi
                analitycznych.
              </li>
              <li>
                Administratorem danych jest Techni Schools sp. z o.o. z siedzibą
                w Lublinie (adres siedziby i adres do korespondencji: ul.
                Związkowa 26, 20-148 Lublin), zarejestrowana w Rejestrze
                Przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez
                Sąd Rejonowy Lublin-Wschód w Lublinie z siedzibą w Świdniku, VI
                Wydział Gospodarczy KRS pod numerem 0000803622, wysokość
                kapitału zakładowego 100 000 PLN, NIP: 7123392662, REGON:
                384348866 (dalej Spółka lub Administrator).
              </li>
              <li>
                Dane osobowe przetwarzane są przez Administratora zgodnie z
                obowiązującymi przepisami prawa, w szczególności zgodnie z
                rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z
                dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w
                związku z przetwarzaniem danych osobowych i w sprawie swobodnego
                przepływu takich danych oraz uchylenia dyrektywy 95/46/WE
                (ogólne rozporządzenie o ochronie danych) -zwane dalej „RODO”
                lub „Rozporządzenie RODO”.
              </li>
            </ol>
          </li>
          <li>
            Przetwarzanie danych osobowych
            <ol>
              <li>
                Administrator uprawniony jest do przetwarzania danych w celu:
                (1) weryfikacji, czy Użytkownik ingeruje w Ranking zgodnie z
                Regulaminem; (2) poinformowania Użytkownika o przyjęciu
                zgłoszenia.
              </li>
              <li>
                Przetwarzane dane nie będą przedmiotem sprzedaży i udostępniania
                podmiotom zewnętrznym, za wyjątkiem podmiotów uprawnionych na
                mocy przepisów prawa
              </li>
              <li>
                Administrator nie ponosi odpowiedzialności za wprowadzenie
                błędnych danych osobowych.
              </li>
              <li>
                Użytkownik ma prawo uzyskania potwierdzenia faktu przetwarzania
                danych osobowych, a także uzyskania kopii danych osobowych
                podlegających przetwarzaniu. W przypadku gdy dane są
                nieprawidłowe lub niepełne Użytkownik ma prawo żądania
                sprostowania danych osobowych.
              </li>
              <li>
                Użytkownik ma prawo żądać usunięcia danych dotyczących
                Użytkownika, tj. przysługuje Użytkownikowi tzw. prawo do bycia
                zapomnianym, chyba że przetwarzanie danych osobowych jest
                niezbędne do ustalenia, realizacji lub obrony roszczeń prawnych.
              </li>
              <li>
                Użytkownik ma prawo wniesienia skargi do organu nadzorującego
                przestrzeganie przepisów ochrony danych osobowych gdy uzna
                Pani/Pan, iż przetwarzanie przez Techni Schools danych osobowych
                narusza RODO.
              </li>
              <li>
                Użytkownik ma prawo do cofnięcia w dowolnym momencie udzielonej
                zgody na przetwarzanie danych osobowych bez wpływu na zgodność z
                prawem przetwarzania, którego dokonano na podstawie zgody przed
                jej cofnięciem.
              </li>
            </ol>
          </li>
          <li>
            Pliki cookies
            <ol>
              <li>
                Aplikacja używa plików cookies. Są to niewielkie informacje
                tekstowe w postaci plików tekstowych, wysyłane przez serwer i
                zapisywane po stronie osoby odwiedzającej
                SzkolyTechnologiczne.pl.
              </li>
              <li>
                Podczas pierwszej wizyty na stronie wyświetlana jest informacja
                na temat stosowania plików cookies. Użytkownik może zmienić
                ustawienia cookies z poziomu swojej przeglądarki albo w ogóle
                usunąć pliki cookies.
              </li>
              <li>
                Instalacja plików cookies jest konieczna do prawidłowego
                świadczenia usług w Aplikacji. W plikach cookies znajdują się
                informacje niezbędne do prawidłowego funkcjonowania Aplikacji, w
                szczególności tych wymagających autoryzacji.
              </li>
              <li>
                Standardowo większość przeglądarek internetowych dostępnych na
                rynku domyślnie akceptuje zapisywanie plików cookies. Każdy ma
                możliwość określenia warunków korzystania z plików cookies za
                pomocą ustawień własnej przeglądarki internetowej. Oznacza to,
                że można np. częściowo ograniczyć (np. czasowo) lub całkowicie
                wyłączyć możliwość zapisywania plików cookies – w tym ostatnim
                wypadku jednak może to mieć wpływ na niektóre funkcjonalności
                Aplikacji.
              </li>
              <li>
                Aplikacja korzysta z cookies własnych w celu zapewnienia
                prawidłowego działania strony. Podobnie jak większość
                współczesnych stron internetowych, korzysta również z plików
                cookies podmiotów trzecich, czyli wykorzystuje funkcje
                zapewniane przez podmioty trzecie, co wiąże się z
                wykorzystywaniem plików cookies pochodzących od podmiotów
                trzecich. Wykorzystanie tego rodzaju plików cookies zostało
                opisane poniżej.
              </li>
            </ol>
          </li>
          <li>
            Google Analitycs
            <ol>
              <li>
                Strona www wykorzystuje internetowe narzędzie do analizy
                statystyk serwisów WWW Google (Universal) Analytics firmy
                zapewnianego przez Google LLC, 1600 Amphitheatre Parkway,
                Mountain View, CA 94043, Stany Zjednoczone
              </li>
              <li>
                Google (Universal) Analytics stosuje metody umożliwiające
                analizę korzystania ze strony internetowej przez Użytkownika, na
                przykład pliki cookie, czyli pliki tekstowe zapisywane na
                komputerze Użytkownika. Wygenerowane w ten sposób informacje
                dotyczące odwiedzin na stronie internetowej www są z reguły
                przekazywane do serwera firmy Google w Stanach Zjednoczonych i
                tam zapisywane.
              </li>
              <li>
                W razie aktywacji funkcji anonimizacji adresów IP na tej stronie
                adres IP Użytkownika jest skracany przez firmę Google przed jego
                przekazaniem na terenie państw członkowskich Unii Europejskiej
                lub innych państw sygnatariuszy porozumienia o Europejskim
                Obszarze Gospodarczym. Tylko w wyjątkowych sytuacjach do serwera
                firmy Google w Stanach Zjednoczonych przekazywany jest pełny
                adres IP i tam skracany.
              </li>
              <li>
                Anonimizowany adres IP przesłany przez przeglądarkę Użytkownika
                w ramach usługi Google Analytics nie jest łączony z innymi
                danymi Google. Usługobiorca może uniknąć przesyłania danych
                wygenerowanych przez plik cookie odnoszących się do korzystania
                przez niego ze strony internetowej (wraz z adresem IP) do Google
                oraz przetwarzania tych danych przez Google, pobierając i
                instalując wtyczkę do przeglądarki dostępną pod następującym
                adresem:{" "}
                <a
                  className="app-link"
                  href="https://tools.google.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://tools.google.com/
                </a>
                .
              </li>
            </ol>
          </li>
          <li>
            Facebook Piksels
            <ol>
              <li>
                Aplikacja korzysta z narzędzi marketingowych dostępnych w ramach
                serwisu Facebook i zapewnianych przez Facebook Inc., 1601 S.
                California Ave. Palo Alto, CA 94304, Stany Zjednoczone. W ramach
                tych narzędzi, do Użytkownika kierowane są reklamy w serwisie
                Facebook. Działania w tym zakresie realizujemy, opierając się na
                naszym prawnie uzasadnionym interesie w postaci marketingu
                własnych produktów lub usług
              </li>
              <li>
                W celu kierowania reklam spersonalizowanych pod kątem zachowań
                Użytkownika w naszej witrynie, strona www zaimplementowała Pixel
                Facebooka, który w sposób automatyczny gromadzi informacje
                korzystaniu witryny w zakresie przeglądanych stron. Zgromadzone
                w ten sposób informacje są najczęściej przekazywane do serwera
                Facebooka w Stanach Zjednoczonych i tam przechowywane.
              </li>
              <li>
                Informacje zbierane w ramach Pixela Facebooka są anonimowe.
                Aplikacja wie jedynie jakie działania podjął Użytkownik w ramach
                Aplikacji. Facebook może łączyć te informacje z innymi
                informacjami zebranymi w ramach korzystania przez Użytkownika z
                serwisu Facebook i wykorzystywać dla swoich własnych celów, w
                tym marketingowych. Takie działania Facebooka nie są już zależne
                od nas, a informacji o nich możesz szukać bezpośrednio w
                polityce prywatności Facebooka:{" "}
                <a
                  className="app-link"
                  href="https://www.facebook.com/privacy/explanation"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.facebook.com/privacy/explanation
                </a>
                .
              </li>
              <li>
                Z uwagi na to, że Facebook Inc. posiada siedzibą w USA i
                wykorzystuje infrastrukturę techniczną znajdującą się w USA,
                przystąpił do programu EU-US-Privacy Shield w celu zapewnienia
                odpowiedniego poziomu ochrony danych osobowych wymaganego przez
                przepisu europejskie. W ramach umowy pomiędzy USA a Komisją
                Europejską ta ostatnia stwierdziła odpowiedni poziom ochrony
                danych w przypadku przedsiębiorstw posiadających certyfikat
                Privacy Shield.
              </li>
            </ol>
          </li>
          <li>
            YANDEX Metrica
            <ol>
              <li>
                Aplikacja korzysta z narzędzia analitycznego Yandex Metrica
                zapewnianego przez Yandex LLC, 16, Leo Tolstoy St., Moskwa,
                Rosja. Działania w tym zakresie realizowane są, opierając się na
                prawnie uzasadnionym interesie, polegającym na tworzeniu
                statystyk i ich analizie w celu optymalizacji stron
                internetowych.
              </li>
              <li>
                Yandex zbiera informacje związane z aktywnością Użytkownika na
                stronie. Ponadto, Yandex rejestruje każdego odwiedzającego
                stronę i umożliwia odtworzenie nagrania wideo z jego ruchu na
                stronie. Yandex pozwala również generować mapy cieplne.
              </li>
              <li>
                W ramach narzędzia Yandex Aplikacja nie ma dostępu do
                informacji, które pozwalają nam na identyfikację użytkownika,
                ponieważ Yandex nie rejestruje procesu wypełnienia formularzy.
              </li>
              <li>
                W celu korzystania z Yandex Metrica, zaimplementowano w kodzie
                strony specjalny kod śledzący Yandex. Kod śledzący wykorzystuje
                pliki cookies firmy Yandex LCL. Z poziomu naszej Aplikacji, z
                wykorzystaniem mechanizmu służącego do zarządzania plikami
                cookies, możesz wyłączyć kod śledzący Hotjar. W każdej chwili
                możesz również zablokować kod śledzący Hotjar, wykonując kroki
                opisanej tutaj:{" "}
                <a
                  className="app-link"
                  href="https://yandex.com/support/metrica/general/opt-out.htm"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://yandex.com/support/metrica/general/opt-out.htm
                </a>
              </li>
            </ol>
          </li>
        </ol>
      </p>
    );
  }
}

export default PrivacyPolicy;
