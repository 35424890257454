import * as React from "react";
import { ISchoolDetailsVoteCloudMailVerificationStageState } from "./ISchoolDetailsVoteCloudMailVerificationStageState";
import { ISchoolDetailsVoteCloudMailVerificationStageProps } from "./ISchoolDetailsVoteCloudMailVerificationStageProps";
import "./SchoolDetailsVoteCloudMailVerificationStage.scss";
import { Button, TextField } from "@material-ui/core";
import Countdown from "react-countdown";

import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import SchoolDetailsVoteIcon from "../SchoolDetailsVoteIcon/SchoolDetailsVoteIcon";
import IconPending from "../../shared/IconPending/IconPending";
import Joi from "joi";
import { JoiCodeSchema } from "../../../models";
import { focusStandardTextField } from "../../../utils/utils";

class SchoolDetailsVoteCloudMailVerificationStage extends React.Component<
  ISchoolDetailsVoteCloudMailVerificationStageProps,
  ISchoolDetailsVoteCloudMailVerificationStageState
> {
  timer: NodeJS.Timeout | null = null;
  verificationCodeTextFieldRef: React.RefObject<HTMLDivElement> = React.createRef();
  constructor(props: ISchoolDetailsVoteCloudMailVerificationStageProps) {
    super(props);
    this.state = {
      verificationCode: "",
      verificationCodeError: undefined,
    };
  }

  public componentDidMount() {
    this.timer && clearTimeout(this.timer);
    this.timer = focusStandardTextField(this.verificationCodeTextFieldRef);
  }

  public componentWillUnmount() {}

  private handleVericationCodeInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { emailVerificationForm, onUpdateEmailVerificationForm } = this.props;
    const code: string = event.target.value;
    onUpdateEmailVerificationForm({
      emailVerificationForm: {
        ...emailVerificationForm,
        code: code.length ? code.slice(0, 6) : undefined,
      },
    });
  };

  private handleVerificationCodeInputBlur = (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    const { emailVerificationForm, onUpdateEmailVerificationForm } = this.props;
    const { code } = emailVerificationForm;
    const validation: Joi.ValidationResult = this.validateVerificationCode(
      code
    );

    onUpdateEmailVerificationForm({
      emailVerificationForm: {
        ...emailVerificationForm,
        codeError: validation.error?.details[0].message,
      },
    });
  };

  public validateVerificationCode = (
    verificationCode: string | undefined
  ): Joi.ValidationResult => {
    return JoiCodeSchema.verificationCode.validate(verificationCode);
  };

  public isValidForm = (verificationCode: string | undefined): boolean => {
    const { validateVerificationCode } = this;

    if (validateVerificationCode(verificationCode).error) return false;
    return true;
  };

  public isVerifyCodePending = (
    verifyCodeBody: Code.Res.VerifyCodeBody
  ): boolean => {
    return verifyCodeBody.status === "pending";
  };

  public isGenerateCodePending = (
    generateCodeBody: Code.Res.GenerateCodeBody
  ): boolean => {
    return generateCodeBody.status === "pending";
  };

  public isDeleteCurrentVotePending = (
    deleteCurrentVoteBody: Vote.Res.DeleteCurrentVote
  ): boolean => {
    return deleteCurrentVoteBody.status === "pending";
  };

  public isPending = (payload: {
    verifyCodeBody: Code.Res.VerifyCodeBody;
    generateCodeBody: Code.Res.GenerateCodeBody;
    deleteCurrentVoteBody: Vote.Res.DeleteCurrentVote;
  }): boolean => {
    const {
      isVerifyCodePending,
      isGenerateCodePending,
      isDeleteCurrentVotePending,
    } = this;
    const { verifyCodeBody, generateCodeBody, deleteCurrentVoteBody } = payload;

    if (isVerifyCodePending(verifyCodeBody)) return true;
    if (isGenerateCodePending(generateCodeBody)) return true;
    if (isDeleteCurrentVotePending(deleteCurrentVoteBody)) return true;
    return false;
  };

  public isResendButtonDisabled = (payload: {
    verifyCodeBody: Code.Res.VerifyCodeBody;
    generateCodeBody: Code.Res.GenerateCodeBody;
    deleteCurrentVoteBody: Vote.Res.DeleteCurrentVote;
    remainingTimeToCanResend: number;
  }): boolean => {
    const {
      verifyCodeBody,
      generateCodeBody,
      deleteCurrentVoteBody,
      remainingTimeToCanResend,
    } = payload;
    if (
      this.isPending({
        verifyCodeBody,
        generateCodeBody,
        deleteCurrentVoteBody,
      })
    )
      return true;

    if (remainingTimeToCanResend > 0) return true;

    return false;
  };

  public isVerifyButtonDisabled = (payload: {
    verifyCodeBody: Code.Res.VerifyCodeBody;
    generateCodeBody: Code.Res.GenerateCodeBody;
    deleteCurrentVoteBody: any;
    code: string | undefined;
  }): boolean => {
    const {
      code,
      verifyCodeBody,
      generateCodeBody,
      deleteCurrentVoteBody,
    } = payload;

    if (
      this.isPending({
        verifyCodeBody,
        generateCodeBody,
        deleteCurrentVoteBody,
      })
    )
      return true;
    if (!this.isValidForm(code)) return true;
    return false;
  };

  public isUndoButtonDisabled = (payload: {
    verifyCodeBody: Code.Res.VerifyCodeBody;
    generateCodeBody: Code.Res.GenerateCodeBody;
    deleteCurrentVoteBody: any;
  }): boolean => {
    const { verifyCodeBody, generateCodeBody, deleteCurrentVoteBody } = payload;

    if (
      this.isPending({
        verifyCodeBody,
        generateCodeBody,
        deleteCurrentVoteBody,
      })
    )
      return true;
    return false;
  };

  private handleVerifyButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    const { onEmailVerify, emailVerificationForm } = this.props;
    const { code } = emailVerificationForm;
    if (!code) return;
    if (code.length !== 6) return;
    onEmailVerify && onEmailVerify({ verificationCode: code });
  };

  private handleResendCodeButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    const { onEmailResend, sentToEmailAddress } = this.props;
    onEmailResend && onEmailResend({ emailAddress: sentToEmailAddress });
  };

  private handleUndoButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    const { onVoteUndo } = this.props;
    onVoteUndo && onVoteUndo();
  };

  public render(): JSX.Element {
    const {
      verifyCodeBody,
      generateCodeBody,
      deleteCurrentVoteBody,
      sentToEmailAddress,
      selectedVoteType,
      lastEmailResendTimestamp,
      emailVerificationForm,
    } = this.props;

    const { code, codeError, otherError } = emailVerificationForm;

    const isPending: boolean = this.isPending({
      verifyCodeBody,
      generateCodeBody,
      deleteCurrentVoteBody,
    });
    return (
      <div className={`schoolDetailsVoteCloudMailVerificationStage`}>
        <div className="body">
          <div className="vote-icon">
            <SchoolDetailsVoteIcon selectedVoteType={selectedVoteType} />
          </div>
          <div className="form">
            <div>
              <p className="info">
                Musimy się upewnić, że Twój adres e-mail jest prawdziwy. Wpisz
                6-cyfrowy kod, który otrzymałeś na swoją skrzynkę mailową:{" "}
                <b>{sentToEmailAddress}</b>
              </p>
            </div>
            <div className="input-area">
              <TextField
                ref={this.verificationCodeTextFieldRef}
                value={code}
                className="app-input verification-code"
                type="number"
                variant="standard"
                disabled={verifyCodeBody.status === "pending"}
                onChange={this.handleVericationCodeInputChange}
                onBlur={this.handleVerificationCodeInputBlur}
                autoFocus={true}
                inputProps={{ max: 999999 }}
                error={Boolean(codeError)}
                helperText={codeError}
              />

              <Countdown
                key={lastEmailResendTimestamp}
                date={
                  lastEmailResendTimestamp
                    ? lastEmailResendTimestamp + 30000
                    : 1
                }
                autoStart={true}
                renderer={(props) => (
                  <Button
                    className="resend-button"
                    onClick={this.handleResendCodeButtonClick}
                    disabled={this.isResendButtonDisabled({
                      verifyCodeBody,
                      generateCodeBody,
                      deleteCurrentVoteBody,
                      remainingTimeToCanResend: props.total,
                    })}
                  >
                    <IconPending
                      isPending={this.isGenerateCodePending(generateCodeBody)}
                    />
                    {Boolean(props.total) && (
                      <div className="waiting-bar">
                        <CircularProgressbar
                          value={props.total / 300}
                          text={`${props.total / 1000}`}
                          styles={buildStyles({
                            textColor: "#c7c7c7",
                            trailColor: "rgb(84, 84, 84)",
                            pathColor: "#729bff",
                            textSize: "53px",
                          })}
                        />
                      </div>
                    )}
                    Wyślij ponownie kod
                  </Button>
                )}
              />
            </div>
          </div>
        </div>
        <div className="footer">
          <Button
            className="undo"
            disabled={this.isUndoButtonDisabled({
              verifyCodeBody,
              generateCodeBody,
              deleteCurrentVoteBody,
            })}
            onClick={this.handleUndoButtonClick}
          >
            <IconPending
              isPending={this.isDeleteCurrentVotePending(deleteCurrentVoteBody)}
            />
            Cofnij
          </Button>
          <Button
            className="app-button verify"
            disabled={this.isVerifyButtonDisabled({
              code,
              verifyCodeBody,
              generateCodeBody,
              deleteCurrentVoteBody,
            })}
            onClick={this.handleVerifyButtonClick}
          >
            <IconPending isPending={this.isVerifyCodePending(verifyCodeBody)} />
            Weryfikuj
          </Button>
        </div>
      </div>
    );
  }
}

export default SchoolDetailsVoteCloudMailVerificationStage;
